@import 'src/styles/base/_variables.less';

.color-Education {
  &:before {
    background-color: #5a7ef2;
  }
}

.color-Legal {
  &:before {
    background-color: #fec021;
  }
}

.color-ProfessionalServices {
  &:before {
    background-color: #f5df4d;
  }
}

.color-GovernmentAndPublicSector {
  &:before {
    background-color: #aa0047;
  }
}

.color-Healthcare {
  &:before {
    background-color: #8e90b4;
  }
}

.color-FinanceAndBanking {
  &:before {
    background-color: #009caa;
  }
}

.color-RealEstate {
  &:before {
    background-color: #00aa63;
  }
}

.color-ECommerce {
  &:before {
    background-color: #b91600;
  }
}

.color-Manufacturing {
  &:before {
    background-color: #9caa00;
  }
}

.color-HumanResources {
  &:before {
    background-color: #6300aa;
  }
}

.color-TransportationAndLogistics {
  &:before {
    background-color: #00aa0e;
  }
}

.color-Insurance {
  &:before {
    background-color: #00eaff;
  }
}

.color-InformationTechnology {
  &:before {
    background-color: #ff00eb;
  }
}

.color-Aviation {
  &:before {
    background-color: #2d6a00;
  }
}

.color-Staffing {
  &:before {
    background-color: #002d6a;
  }
}

.color-Accounting {
  &:before {
    background-color: #9113f6;
  }
}

.color-Retail {
  &:before {
    background-color: #4760cc;
  }
}

.color-HospitalityAndTourism {
  &:before {
    background-color: #9dd559;
  }
}

.color-MediaAndEntertainment {
  &:before {
    background-color: #863d39;
  }
}

.color-NonProfitAndCharity {
  &:before {
    background-color: #c98828;
  }
}

.color-EnergyAndUtilities {
  &:before {
    background-color: #221736;
  }
}

.color-Consulting {
  &:before {
    background-color: #207040;
  }
}

.color-Sales {
  &:before {
    background-color: #4ee16c;
  }
}

.color-MarketingAndAdvertising {
  &:before {
    background-color: #e14e98;
  }
}

.color-FoodAndBeverage {
  &:before {
    background-color: #3f0822;
  }
}

.color-Agriculture {
  &:before {
    background-color: #092426;
  }
}

.color-Religion {
  &:before {
    background-color: #e85a4b;
  }
}

.color-Construction {
  &:before {
    background-color: #6f8a35;
  }
}
