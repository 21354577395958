@import 'src/styles/base/_variables.less';

body {
  .Toastify__toast-container {
    width: 100%;
    max-width: 526px;
    color: @text-color;
    padding: 10px;
  }

  .Toastify__toast-container--top-center {
    top: 100px;

    @media (max-width: @breakpoint-sm - 1) {
      top: 50px;
    }
  }

  .Toastify__toast {
    border-radius: 20px;
    background-color: @white;
    padding: 10px 20px;
    cursor: default;
    margin-bottom: 10px;
    font-family: inherit;
    border: 1px solid;

    &-body {
      width: 100%;
      padding: 0;
    }

    &.Toastify__toast--error {
      color: @red;
      border-color: rgba(@red, 0.4);

      .Toastify__toast-icon {
        background-color: @red;
      }
    }

    &.Toastify__toast--success {
      color: @green;
      border-color: rgba(@green, 0.4);

      .Toastify__toast-icon {
        background-color: @green;
      }
    }

    &.Toastify__toast--warning,
    &.Toastify__toast--info {
      color: @yellow;
      border-color: rgba(@yellow, 0.4);

      .Toastify__toast-icon {
        background-color: @yellow;
      }
    }
  }
}
