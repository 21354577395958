@import 'src/styles/base/_variables.less';

.overflow {
  overflow-y: auto;
}

.detailsHead {
  display: flex;
  color: @text-color-darken;
  font-size: 24px;
  font-weight: 700;
}

.detailsHeadName {
  width: calc(100% - 50px);
  padding-right: 15px;
  align-self: center;
  word-break: break-word;
}

.tabs {

  :global {
    .ant-tabs {
      .ant-tabs-tab {
        color: inherit;
        text-transform: uppercase;
        font-weight: 400;

        &.ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            color: inherit;
            text-transform: uppercase;
            font-weight: 700;
          }
        }
      }

      .ant-tabs-ink-bar {
        background: @green;
        height: 3px;
        border-radius: 2px;
      }
    }

    .ant-tabs-top {
      > .ant-tabs-nav {
        margin: 0;

        &::before {
          border-bottom: 0;
        }
      }
    }

    .ant-tabs-content-holder {
      margin-top: 14px;
    }
  }
}

@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;