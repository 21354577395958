@import '../../../../styles/base/_variables.less';

.title {
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 20px;
}

.notice {
  text-align: left;
  font-size: 12px;
  color: @black-2;
  margin-bottom: 20px;
}

.password {
  @media (min-width: @breakpoint-md) {
    max-width: 510px;
  }

  &Input {
    @media (min-width: @breakpoint-md) {
      max-width: 420px;
    }
  }

  &Btn {
    margin-bottom: 20px;
  }
}

.input {
  margin-bottom: 20px;

  :global {
    .ant-input,
    .ant-input-affix-wrapper {
      height: 40px;
      overflow: hidden;
    }

    .ant-input-affix-wrapper {
      > input.ant-input {
        padding: 6px 12px;
        max-width: 380px;
      }

      .ant-input-suffix {
        color: @gray-4;
      }
    }
  }
}

.modalTitle {
  :global {
    .ant-modal-header {
      .ant-modal-title {
        padding-right: 16px;
        text-transform: initial;
      }
    }
  }
}

.timerBox {
  margin-bottom: 20px;
}

.transfer {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &Note {
    display: flex;
    align-items: baseline;
    gap: 10px;
  }
}

.auth {
  max-width: 850px;

  &Text {
    color: @gray-4;
    line-height: 20px;
    margin-bottom: 20px;

    h4 {
      font-size: 24px;
      color: @gray-5;
      margin-bottom: 10px;
    }
  }

  &Box {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;

    .item {
      border: 1px solid @gray-4;
      position: relative;
      min-height: 204px;

      @media (min-width: @breakpoint-md) {
        max-width: 410px;
        flex-shrink: 0;
      }

      :global {
        .ant-form-item {
          height: 100%;
          display: flex;
          margin-bottom: 0;
        }

        .ant-form-item-control-input {
          flex-grow: 1;
        }

        .ant-form-item-control-input-content {
          height: 100%;
          display: flex;
        }

        .ant-checkbox-wrapper {
          flex-direction: column;
          gap: 10px;
          text-align: center;
          padding: 20px 14px;
        }
      }

      .title {
        margin-bottom: 10px;
      }

      &.selected {
        border-color: @green;
      }

      .label {
        position: absolute;
        top: 14px;
        right: 16px;
        color: @green;
      }
    }
  }
}

.instruction {
  line-height: 20px;
  color: @black-2;

  div {
    margin-bottom: 2px;
  }

  p {
    color: @gray-4;
  }

  .code {
    width: 128px;
    height: 128px;
    border: 1px solid @gray-4;
    border-radius: 6px;
    margin-bottom: 20px;

    img {
      display: block;
      max-width: 100%;
      padding: 4px;
    }
  }
}

@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;