@import 'src/styles/base/_variables.less';

.wrap {
  display: grid;
  grid-gap: 22px;
  grid-template-columns: none;
  grid-auto-flow: column;
  grid-auto-columns: calc(50% - 15px);
  overflow-x: auto;
  overflow-y: hidden;

  @media (min-width: @breakpoint-md) {
    grid-auto-columns: calc(33.33% - 15px);
  }
}

.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  :global {
    .breadcrumb {
      margin-bottom: 0;
    }
  }

  &Btn {
    :global {
      .ant-btn {
        &:not(.ant-btn-link) {
          &.ant-btn-lg {
            &:not(.ant-btn-circle) {
              height: auto;
              padding: 0;
              font-size: 12px;

              @media (min-width: @breakpoint-md) {
                font-size: 14px;
              }
            }
          }
        }
      }

    }
  }

}

.tab {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid rgba(@gray-4, 0.3);

  @media (min-width: @breakpoint-md) {
    gap: 20px;
    padding: 20px;
  }

  h1 {
    color: @gray-4;
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;

    @media (min-width: @breakpoint-md) {
      font-size: 24px;
      line-height: 28px;
    }
  }

  .box {
    display: flex;
    align-items: center;
    gap: 12px;
    line-height: 20px;
    margin-top: auto;

    @media (min-width: @breakpoint-md) {
      justify-content: space-between;
    }

    @media (min-width: @breakpoint-lg) {
      justify-content: flex-start;
      margin-top: 0;
    }
  }

  h2 {
    font-size: 38px;
    line-height: 40px;
  }

  .img {
    width: 44px;
    height: 55px;

    img {
      display: block;
      max-width: 100%;
    }
  }

  .project {
    width: 50%;

    >div {
      color: @gray-4;
    }
  }
}

.wrapper {
  padding-top: 30px;
}
@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;