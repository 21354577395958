@import 'src/styles/base/_variables.less';

.cards-wrapper {
  position: relative;
  display: flex;
  gap: 10px;

  &.cards-wrapper--center {
    align-items: center;
  }
}

.cards-list {
  position: relative;

  >div {
    &:last-child {
      margin-bottom: 24px;

      div[class*='filled'] {
        &:before {
          background: linear-gradient(180deg,
              #00aa63 0%,
              rgba(0, 170, 99, 0) 100%);
        }
      }
    }

    &:nth-child(20) {
      div[class*='numeration'] {
        &::before {
          display: none;
        }
      }
    }
  }

  >div[class*='cards-wrapper'] {
    &:first-child {
      >div[class*='cards'] {
        border: 1px solid @gray-3;
        border-radius: 8px 8px 0 0;
      }
    }

    &:last-child {
      >div[class*='cards'] {
        border: 1px solid @gray-3;
        border-radius: 0 0 8px 8px;
      }
    }

    &:only-child {
      >div[class*='cards'] {
        border: 1px solid @gray-3;
        border-radius: 8px;
      }
    }

    >div[class*='cards'] {
      border: 1px solid @gray-3;
      border-top: 0;
    }

    >div[class*='cards-ended'] {
      border: 1px solid #FF6868;
    }
  }

  >div[class*='cards-wrapper--center'] {
    &:last-child {
      div[class*='numeration-wrapper--center'] {
        div[class*='numeration--center'] {
          &::before {
            display: none;
          }
        }
      }
    }
  }
}

.ended-label {
  border-radius: 0px 16px 16px 0px;
  background-color: #FF6868;
  padding: 0 10px;
  width: 60px;
  height: 20px;
  color: @white;
  font-size: 12px;
  font-weight: 500;
  font-family: @font-main;
  position: absolute;
  top: 0;
  left: 0;

}

.cards {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  position: relative;
  cursor: pointer;


  &:hover {
    &:before {
      content: '';
      position: absolute;
      top: 40px;
      left: 0%;
      transform: translate(-50%, -50%);
      border-radius: 13px;
      border: 3px solid @green-1;
      height: 40px;
    }
  }

  &__label {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;
    padding: 1px 7px;
    text-transform: uppercase;
    background-color: @yellow-2;
    color: @white;
    border-radius: 8px 0px;
    font-weight: 600;
    font-size: 10px;
  }

  &__name {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
  }

  &__item {
    font-size: 14px;
    line-height: 14px;

    >span {
      color: @gray-4;
      padding-left: 4px;
    }
  }

  &__description {
    word-break: break-word;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: @gray-4;
  }

  &__box {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 14px 0;

    @media (min-width: @breakpoint-xs) {
      flex-direction: row;
      gap: 0;
      align-items: center;
      justify-content: space-between;
      padding: 20px 0 0 0;
    }

    @media (min-width: @breakpoint-md) {
      padding: 0;
      justify-content: flex-end;
      margin: 0 -20px 10px;

      >div {
        padding: 0 20px;
      }
    }

    .ant-btn {
      width: 100%;

      @media (min-width: @breakpoint-xs) {
        width: auto;
      }
    }
  }

  &__btn {
    display: flex;
    align-self: start;
    justify-content: flex-end;
    margin: 0 -5px;
    margin-bottom: 20px;

    @media (min-width: @breakpoint-md) {
      margin-bottom: 0;
    }

    >div {
      padding: 0 5px;
    }
  }

  &__file {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: @blue-2;
    display: flex;
    align-items: center;

    >span {
      padding-left: 5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 180px;

      @media (min-width: @breakpoint-xxl) {
        max-width: 300px;
      }
    }

    &:hover {
      color: @blue;
    }
  }

  &.cards--center {
    &:hover {
      &:before {
        top: 50%;
      }
    }
  }

  &.cards-ended {

    &:hover {
      &:before {
        border-color: #FF6868;
      }
    }
  }
}

.numeration {
  cursor: default;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid transparent;
  flex-shrink: 0;
  font-size: 12px;
  line-height: 12px;
  font-weight: 700;
  color: @white;

  &:before {
    content: '';
    display: block;
    width: 4px;
    height: 100%;
    position: absolute;
    top: 55px;
    z-index: 0;
  }

  &.numeration--center {
    &:before {
      top: calc(50% + 15px);
      height: calc(100% - 15px);
    }
  }

  &.empty {
    background-color: @gray-4;
    box-shadow: 0px 0px 0px 3px @white inset;

    &:before {
      background: linear-gradient(180deg,
          #f0f0f0 0%,
          rgba(240, 240, 240, 0) 100%);
    }
  }

  &.stage {
    background-color: @gray-4;
    box-shadow: 0px 0px 0px 3px @white inset;

    &:before {
      background-color: @gray-4;
    }
  }

  &.filled {
    background-color: @green;
    box-shadow: 0px 0px 0px 3px @white inset;

    &:before {
      background-color: @green;
    }
  }

  &.ended {
    background-color: #FEC021;
    box-shadow: 0px 0px 0px 3px @white inset;

    &:before {
      background-color: #FEC021;
    }
  }

  &.dashed-first {
    &:before {
      height: 50%;
      border-right: 4px dashed @gray-4;
      background: transparent;
    }
  }

  &.dashed-last {
    &::after {
      content: '';
      display: block;
      position: absolute;
      height: 50%;
      top: -15px;
      border-right: 4px dashed @gray-4;
      background: transparent;
    }
  }

  &.short-first {
    &:before {
      height: 50%;
    }
  }

  &.short-last {
    &::after {
      content: '';
      display: block;
      position: absolute;
      height: 50%;
      top: -15px;
      width: 4px;
      background-color: @gray-4;
    }
  }

  &.dashed {

    &:after,
    &:before {
      border-right-color: @green;
    }
  }

  &.inverse {
    &.filled {
      box-shadow: 0px 0px 0px 3px @black-2 inset;
    }

    &.empty {
      box-shadow: 0px 0px 0px 3px @black-2 inset;
    }

    &.stage {
      box-shadow: 0px 0px 0px 3px @black-2 inset;
    }

    &.ended {
      box-shadow: 0px 0px 0px 3px @black-2 inset;
    }
  }
}

.numeration-wrapper {
  margin: 25px 0 0 0;

  @media (min-width: @breakpoint-xl) {
    margin: 25px 0 0 -40px;
  }

  &.numeration-wrapper--center {
    margin: 0;

    @media (min-width: @breakpoint-xl) {
      margin: 0 0 0 -40px;
    }
  }
}