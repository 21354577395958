@import 'src/styles/base/_variables.less';

.card-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
}

.card-box {
  padding: 5px;
  flex-shrink: 0;
  display: flex;
  width: 100%;

  @media (min-width: @breakpoint-md) {
    width: 50%;
  }

  @media (min-width: @breakpoint-lg) {
    width: 33.33%;
  }
}

.card {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid @gray-3;
  border-radius: 8px;
  padding: 20px;
  cursor: pointer;
  min-height: 200px;

  &:hover {
    border-color: @green;
  }

  &__head {
    margin-bottom: 24px;
  }

  &__img {
    width: 60px;

    img {
      display: block;
    }
  }

  &__number {
    font-weight: 600;
  }

  &__default {
    color: @white;
    background-color: @gray-2;
    width: min-content;
    margin-top: 6px;
    padding: 0 8px 1px;
    border-radius: 14px;
  }

  &__name {
    font-size: 14px;
    font-weight: 700;
    color: @text-color-darken;
    line-height: 20px;
    flex-grow: 1;
    padding-right: 15px;
    width: calc(100% - 42px);
    word-break: break-all;

    &.card__name--light {
      color: @text-color;
      font-weight: 400;
      width: calc(100% - 24px);
    }
  }

  &__foot {
    margin-top: auto;
    text-align: right;
  }

  &.card--btn {
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: rgba(147, 149, 151, 0.04);
    border: 1px solid rgba(147, 149, 151, 0.05);
  }
}

.card-title {
  text-transform: uppercase;
  color: @text-color-darken;

  @media (min-width: @breakpoint-sm) {
    margin-bottom: 20px;
  }
}

.ticket-list {
  padding-top: 20px;

  @media (min-width: @breakpoint-sm) {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;
  }

  &.mt-0 {
    margin-top: 0;
  }
}

.ticket-box {
  margin-bottom: 5px;
  padding: 5px;
  flex-shrink: 0;
  display: flex;
  width: 100%;
}

.ticket {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border: 1px solid rgba(147, 149, 151, 0.3);
  border-radius: 8px;
  padding: 10px;
  min-height: 100px;
  cursor: pointer;
  text-decoration: none;

  @media (min-width: @breakpoint-lg) {
    padding: 20px;
  }

  &:hover {
    border-color: @green-1;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0%;
      transform: translate(-50%, -50%);
      border-radius: 13px;
      border: 3px solid @green-1;
      height: 40px;
    }
  }

  &__left {
    display: flex;
    align-items: center;
  }

  &__progress {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    box-shadow: 0px 0px 0px 2px @gray-7;
    margin-right: 20px;

    .ant-progress-circle-trail {
      stroke: @gray-3;
    }

    .ant-progress-circle {
      .ant-progress-text {
        font-size: 12px;
        font-weight: 500;
        color: @gray-5;
      }
    }
  }

  &__name {
    font-size: 14px;
    line-height: 20px;
    word-break: break-all;
  }

  &__item {
    font-size: 14px;
    line-height: 20px;

    > span {
      color: @gray-4;
    }
  }

  &__shared {
    font-size: 14px;
    color: @black-2;
  }

  &__btn {
    display: flex;
    align-items: center;
    margin: 0 -5px;

    > div {
      padding: 0 5px;
    }
  }

  &__circle {
    display: inline-block;

    .ticket-circle {
      position: relative;
      padding-left: 16px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        left: 2px;
        top: 50%;
        transform: translate(0%, -50%);;
      }
    }
  }

  &.ticket--shadow {
    border-color: transparent;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
    flex-wrap: wrap;

    &:hover {
      border-color: @green-1;
    }

    .ticket__left,
    .ticket__right {
      width: 100%;

      @media (min-width: @breakpoint-md) {
        width: 50%;
      }

      @media (min-width: @breakpoint-lg) {
        width: 100%;
      }

      @media (min-width: @breakpoint-xl) {
        width: 50%;
      }
    }

    .ticket__right {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .ticket__inner {
      @media (min-width: @breakpoint-md) {
        display: flex;
        align-items: center;
        margin: 0 -20px;

        > div {
          padding: 0 20px;
        }
      }
    }


  }

  &.ticket--full {
    display: block;
    border-color: transparent;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
    flex-wrap: wrap;
    min-height: 80px;
    color: @gray-5;

    &:hover {
      border-color: @green-1;
    }

    @media (min-width: @breakpoint-md) {
      display: flex;
    }

    .ticket__name {
      @media (min-width: @breakpoint-md) {
        width: 260px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    .ticket__main {
      @media (min-width: @breakpoint-sm) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }

      @media (min-width: @breakpoint-lg) {
        width: calc(100% - 310px);
      }
    }

    .ticket__inner {
      @media (min-width: @breakpoint-md) {
        display: flex;
        align-items: center;
        width: calc(100% - 200px);

        > div {
          width: 50%;
        }
      }
    }
  }

  &.inverse {
    background-color: #1e2126;
    color: @white;

    .ticket__progress {
      box-shadow: 0px 0px 0px 2px rgba(230, 230, 230, 0.3);

      .ant-progress-circle-trail {
        stroke: rgba(240, 240, 240, 0.1);
      }

      .ant-progress-circle {
        .ant-progress-text {
          color: @white;
        }
      }
    }

    &:hover {
      border-color: @yellow-3;

      &:before {
        border-color: @yellow-3;
      }
    }
  }
}

