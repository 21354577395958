@import 'src/styles/base/_variables.less';

.page {
  background-color: @white-2;
  overflow-y: auto;
  overflow-x: hidden;
  flex: auto;
  font-size: 14px;
  line-height: 15px;
  scroll-behavior: smooth;

  @media (min-width: @breakpoint-md) {
    font-size: 24px;
    line-height: 30px;
  }
}

.container {
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 15px;
}

.title {
  h2 {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 24px;
    line-height: 30px;
    color: @black-2;
  
    @media (min-width: @breakpoint-lg) {
      font-size: 36px;
      line-height: 40px;
    }
  }

  span {
    color: @yellow-3;
  }

  &.titleDark {
    color: @text-color-darken;
  }
}

.iconCard {
  position: absolute;
  background: @white;
  filter: drop-shadow(0px 3px 8px rgba(0, 0, 0, 0.1));
  box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
  backdrop-filter: blur(22px);
  color: @yellow-3;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.sliderButton {
  background-color: transparent;
  border: 0;
  display: inline-block;
  width: 24px;
  height: 24px;
  color: @white;
  font-size: 24px;
  padding: 0;
  margin-right: 20px;

  &:last-child {
    margin-right: 0;
  }
}

.progress {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  box-shadow: 0px 0px 0px 2px @gray-7;

  .ant-progress-circle-trail {
    stroke: @gray-3;
  }

  .ant-progress-circle {
    &.ant-progress-status-success {
      .ant-progress-text {
        color: @gray-5;
      }
    }

    .ant-progress-text {
      font-size: 12px;
      font-weight: 500;
      color: @gray-5;
    }
  }
}

@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;