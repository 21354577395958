@import '../../../../../styles/base/_variables.less';

.title {
  display: block;
  margin-bottom: 20px;
  font-weight: 700;
  line-height: 40px;
}
.box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.activityWrap {
  overflow: auto;
}

.activityTable {
  width: 100%;

  table {
    width: '100%';
    position: 'relative';
  }

  td, th {
    padding: 0;
    border-spacing: 0;
  }

  th {
    width: 20%;
    text-align: left;
    padding: 12px 20px;
    line-height: 16px;
    font-weight: 400;
    background-color: rgba(147, 149, 151, 0.04);
  }

  
  td {
    white-space: nowrap;
    padding: 14px 20px;
    height: 56px;
    border-bottom: 1px solid #F0F0F0;
  }

  td:last-child {
    padding: 0;
  }
}

.active {
  color: @green;
}

.pagination {
  text-align: right;
  margin: 20px 0;
}

.btn {
  text-align: right;

  :global {
    .ant-btn {
     width: 100px;
    }
  }
}
@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;