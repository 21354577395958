@import 'src/styles/base/_variables.less';

.formSection {
  position: relative;

  .pagination {
    top: 410px;
  }
}

.contactRow {
  display: flex;
  flex-direction: column;
}

.form {
  background-color: @white;
  display: flex;
  flex-direction: column;
  padding: 40px 20px;

  @media (min-width: @breakpoint-md) {
    flex-direction: row;
    justify-content: space-between;
    gap: 40px;
    padding: 20px;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
    background-color: @white;
    border-radius: 20px;
    max-width: 1144px;
    width: 100%;
  }

  @media (min-width: @breakpoint-xl) {
    padding: 40px 40px 40px 80px;
  }

  &Box {
    width: 100%;
    max-width: 644px;
    margin: 0 auto;

    @media (min-width: @breakpoint-md) {
      margin: 0;
    }
  }

  &Side {
    margin: 0 auto 52px;

    @media (min-width: @breakpoint-md) {
      margin-bottom: 80px;
    }
  }

  &Title {
    margin-bottom: 24px;
  }

  .input {
    padding-bottom: 10px;

    @media (min-width: @breakpoint-xl) {
      padding-bottom: 20px;
    }

    :global {
      .ant-input {
        border-color: @gray-4;

        &:not(textarea) {
          line-height: 49px;
          padding-left: 20px;
        }
      }

      textarea {
        &.ant-input {
          padding: 20px;
          min-height: 122px;
        }
      }
    }
  }
}

.logo {
  margin: 0 auto;
  width: 270px;
}

.formPopup {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  z-index: 10;
  padding-top: 50%;

  @media (min-width: @breakpoint-sm) {
    padding-top: 20%;
  }

  &Content {
    width: 360px;
    height: 360px;
    margin: 0 auto;
    border-radius: 50%;
    background-image: url('../../../../../src/assets/img/landing/form-bg.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    text-align: center;
    padding-top: 80px;
  }

  &Btn {
    font-size: 18px;
    color: @gray-7;
    margin-bottom: 40px;
  }

  &Title {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-weight: 700;
  }

  &Text {
    font-size: 14px;
    line-height: 20px;
    color: @text-color;
    max-width: 174px;
    margin: 0 auto;
  }
}

.contactList {
  display: none;

  @media (min-width: @breakpoint-md) {
    display: block;
    li {
      margin-bottom: 46px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    a,
    address {
      display: inline-flex;
      align-items: center;
      color: @text-color-darken;
      font-size: 14px;
      font-weight: 500;
      margin: 0;
    }

    i {
      width: 27px;
      height: 27px;
      font-size: 27px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    span {
      padding-left: 36px;

      @media (min-width: @breakpoint-xl) {
        padding-left: 66px;
      }
    }
  }
}

.footerSection {
  padding: 0;

  @media (min-width: @breakpoint-md) {
    padding: 60px 15px 80px;
  }

  .formTitle {
    text-align: center;
  }
}

@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;