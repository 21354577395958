@import 'src/styles/base/_variables.less';

.button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid @green;
  position: relative;
  z-index: 5;
  background-color: @gray-1;

  &:before {
    content: '';
    position: absolute;
    width: 44px;
    height: 44px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 5px solid @green;
    border-radius: 50%;
  }
}

.icon {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: @green;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: @green;
    top: 50%;
    left: 50%;
  }

  &:before {
    transform: translateY(-50%) translateX(-8px);
  }

  &:after {
    transform: translateY(-50%) translateX(4px);
  }
}

.closed {
  &:before {
    animation: animTransLeft 0.2s ease 1 forwards,
      animWidth 0.1s ease 0.2s 1 forwards;
  }

  &:after {
    animation: animTransRight 0.2s ease 1 forwards,
      animWidth 0.1s ease 0.2s 1 forwards;
  }
}

@keyframes animTransRight {
  100% {
    transform: translateY(-50%) translateX(-50%) rotate(-45deg);
  }
}

@keyframes animTransLeft {
  100% {
    transform: translateY(-50%) translateX(-50%) rotate(45deg);
  }
}

@keyframes animWidth {
  100% {
    width: 20px;
    height: 2px;
    border-radius: 2px;
  }
}

@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;