@import 'src/styles/base/_variables.less';

.modal {
  &:global(.modal-root.ant-modal) {
    @media (min-width: @breakpoint-lg) {
      max-width: 1460px;
      margin: 0 auto;
    }

  }

  .modalScrollWrapper {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 20px 20px 0;
    max-height: 69vh;

    @media (max-width: @breakpoint-md) {
      max-height: calc(67vh - 110px);
    }
  }

  &Terms {
    color: @black;
    font-size: 11px;
    user-select: none;
    text-align: justify;

    a {
      text-decoration: underline;
      color: rgb(0, 0, 238);

      &:hover,
      &:focus,
      &:active {
        text-decoration: underline;
        color: rgb(0, 0, 238);
      }

      &:visited {
        color: rgb(85, 26, 139);
      }
    }
  }

  &Text {
    margin-bottom: 12px;
  }
}

.modalFooter {
  @media (min-width: @breakpoint-lg) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &Check {
    :global {
      .ant-form-item {
        @media (min-width: @breakpoint-lg) {
          margin-bottom: 0;
        }
      }
    }
  }

  &Btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 10px;

    > div {
      :global {
        .ant-btn {
          width: 145px;
        }
      }
    }
  }
}

@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;