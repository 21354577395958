@import 'src/styles/base/_variables.less';

.text {
  h3 {
    font-size: 14px;
  }
  @media (min-width: @breakpoint-md) {
    h3 {
      font-size: 24px;
    }
  }
}

.customizeSection {
  position: relative;
}

.pagination {
  top: 320px;
}

.wrapper {
  background-image: url('../../../../../src/assets/img/landing/customize-bg-mobile.svg');
  background-repeat: no-repeat;
  background-position: left center;
  padding: 60px 0 158px;

  @media (min-width: @breakpoint-sm) {
    background-size: 40%;
  }

  @media (min-width: @breakpoint-md) {
    padding: 100px 0 240px;
  }

  @media (min-width: @breakpoint-xl) {
    background-image: url('../../../../../src/assets/img/landing/customize-bg-new.png');
    background-position: left center;
  }

  @media (min-width: @breakpoint-xxl) {
    padding: 400px 0 180px;
    height: 1116px;
    background-size: 44%;
  }
}

.content {
  color: @black-2;
  max-width: 244px;
  margin-left: auto;

  @media (min-width: @breakpoint-xs) {
    max-width: 340px;
  }

  @media (min-width: @breakpoint-md) {
    max-width: 580px;
  }

  @media (min-width: @breakpoint-xl) {
    margin: 0 90px 0 auto;
    max-width: 640px;
  }

  @media (min-width: @breakpoint-xxl) {
    margin: 0 144px 0 auto;
  }
}

.title {
  margin-bottom: 40px;
}

.heroBtn {
  max-width: 332px;
  margin: 0 auto;

  @media (min-width: @breakpoint-xl) {
    margin: 0;
  }

  :global {
    .ant-btn {
      display: flex;
      align-items: center;
      gap: 20px;
      border-radius: 100px;
      background-color: @yellow-3 !important;

      > span {
        font-size: 16px;
      }

      > i {
        font-size: 24px;
      }

      @media (min-width: @breakpoint-lg) {
        height: 60px !important;

        > span {
          font-size: 20px;
        }

        > i {
          font-size: 30px;
        }
      }
    }
  }
}

@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;