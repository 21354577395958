@import 'src/styles/base/_variables.less';

.upload-wrapper {
  max-width: 388px;

  > span {
    display: block;
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-upload-list {
    color: @blue-2;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    .ant-upload-text-icon {
      &:before {
        content: '\e947';
        font-family: 'icon-font';
        display: inline-block;
      }

      svg {
        display: none;
      }
    }

    .ant-upload-list-item-card-actions {
      .ant-btn,
      .ant-btn:hover,
      .ant-btn:active,
      .ant-btn:focus {
        .disabled-icon {
          &:hover {
            cursor: not-allowed;
          }

          opacity: 0.4;
        }

        border: 0;
        opacity: 1;
        box-shadow: none;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 4px;
        min-width: 24px;
      }

      .anticon {
        color: #200e32;

        &:before {
          content: '\e948';
          font-family: 'icon-font';
          display: inline-block;
          font-size: 8px;
        }

        svg {
          display: none;
        }
      }
    }

    > div {
      &:first-child {
        .ant-upload-list-item {
          margin-top: 0;
        }
      }
    }

    .ant-upload-list-item {
      &:hover {
        .ant-upload-list-item-info {
          background-color: transparent;
        }

        .ant-upload-list-item-name,
        .ant-upload-text-icon {
          color: @blue;
        }
      }
    }
  }
}
