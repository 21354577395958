@import 'src/styles/base/_variables.less';

.subscribeBox {
  display: flex;
  justify-content: flex-end;
  font-weight: 500;
  color: @text-color-darken;
  margin: 0 10px 10px;
  border-bottom: 1px solid @gray-3;
  padding: 0 5px 20px;

  > div {
    padding: 0 5px;

    @media (min-width: @breakpoint-lg) {
      padding: 0;
    }
  }

  @media (min-width: @breakpoint-lg) {
    border: 0;
    margin: 0 0px 30px;
    padding-bottom: 0;
    padding-right: calc(100% / (5 * 3));
  }

  @media (min-width: @breakpoint-xl) {
    padding-right: calc(100% / (6 * 3));
  }

  @media (min-width: @breakpoint-xxl) {
    padding-right: calc(100% / (7 * 3));
  }
}

.subscribeBtn {
  cursor: pointer;

  @media (max-width: @breakpoint-lg - 1) {
    box-shadow: 0px 3px 8px rgba(@black, 0.1);
    width: 50px;
    height: 50px;
    background-color: @gray-3;
    border-radius: 4px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  i {
    @media (min-width: @breakpoint-lg) {
      padding-left: 5px;
    }
  }

  .subscribeBtnText {
    display: none;

    @media (min-width: @breakpoint-lg) {
      display: inline-block;
    }
  }
}

.stageContainer {
  padding: 0 10px 0 30px;
  @media (min-width: @breakpoint-md) {
    display: flex;
  }
}

.leftToRight {
  flex-direction: row;
}

.stageBox {
  position: relative;
  padding: 10px 16px 0 0;

  @media (min-width: @breakpoint-md) {
    padding: 0 0 132px;
    width: calc(100% / 5);
  }

  @media (min-width: @breakpoint-xl) {
    width: calc(100% / 6);
  }

  @media (min-width: @breakpoint-xxl) {
    width: calc(100% / 7);
  }

  &:last-child {
    .stageMore {
      @media (min-width: @breakpoint-md) {
        width: 2px;
        height: 66px;
        top: auto;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .stageMoreIcon {
      @media (min-width: @breakpoint-md) {
        top: 0;
        transform: translate(-50%, 0) rotate(90deg);
      }
    }
  }
}

.active {
  .stageCount {
    border-color: @green;
    color: @green;

    &:before,
    &:hover {
      border-color: @green;
    }
  }

  .stageName,
  .stagePercent {
    color: @text-color-darken;
  }
}

.newStage,
.stageCount {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: @white;
  cursor: pointer;
  position: relative;
}

.newStage {
  border: 2px solid @green;
  color: @green;
  width: 50px;
  height: 50px;
  font-size: 12px;

  @media (min-width: @breakpoint-lg) {
    border-width: 3px;
    width: 98px;
    height: 98px;
    font-size: 24px;
  }

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    border: 5px solid @green;
    width: 44px;
    height: 44px;

    @media (min-width: @breakpoint-lg) {
      border-width: 10px;
      width: 86px;
      height: 86px;
    }
  }
}

.stageCount {
  width: 64px;
  height: 64px;
  border: 2px solid @gray-2;
  font-weight: 700;
  transition: border-color 0.3s ease;
  color: @text-color-darken;

  @media (min-width: @breakpoint-md) {
    margin: 0 auto;
  }

  &:hover {
    border-color: @gray-4;
    transition: border-color 0.3s ease;
  }

  :global {
    .ant-progress-circle-trail {
      stroke: @gray-3;
    }

    .ant-progress-circle {
      &.ant-progress-status-success {
        .ant-progress-text {
          color: @green;
        }
      }

      .ant-progress-text {
        font-weight: 700;
        color: @text-color-darken;
        font-size: 14px;
      }
    }
  }
}

.stageWrap,
.newStageWrap {
  position: relative;
  z-index: 1;

  &:before {
    content: '';
    background-image: url('../../../src/assets/img/shadow.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: block;
    top: 0;
    width: 100px;
    height: 100px;
    position: absolute;
    left: 0;

    @media (min-width: @breakpoint-md) {
      left: 50%;
      transform: translateX(calc(-50% + 16px));
    }
  }
}

.newStageWrap {
  &:before {
    display: none;
    @media (min-width: @breakpoint-lg) {
      display: block;
      width: 154px;
      height: 154px;
      left: 0;
      transform: none;
    }
  }
}

.stageWrap {
  display: flex;
  min-height: 100px;

  @media (min-width: @breakpoint-md) {
    display: block;
    min-height: 0;
  }
}

.stageInfo {
  width: calc(100% - 94px);
  margin-left: 30px;
  border-bottom: 1px solid @gray-3;
  padding: 0 10px 10px 0;
}

.stageText {
  font-size: 12px;
  margin-bottom: 5px;
}

.stageActions {
  display: flex;
  justify-content: flex-end;
  margin: 0 -5px 0 auto;

  li {
    padding: 0 5px;
    font-size: 18px;
    line-height: 1;
  }

  i {
    width: 22px;
    display: inline-block;
    text-align: center;
  }
}

.stageHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.stageDate {
  font-size: 12px;
}

.stageName {
  font-weight: 500;

  @media (min-width: @breakpoint-md) {
    font-weight: 400;
    position: absolute;
    top: calc(100% + 20px);
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-align: center;
    width: 90%;
    left: 50%;
    transform: translateX(-50%);
  }
}

.stagePercent {
  font-weight: 700;
  margin-bottom: 5px;
}

.stageMore {
  display: block;
  background-color: @gray-2;
  position: absolute;
  width: 2px;
  height: 100%;
  top: 40px;
  left: 31px;

  @media (min-width: @breakpoint-md) {
    height: 2px;
    width: 100%;
    left: 50%;
    top: 30px;
  }
}

.stageMoreIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: @white;
  border: 2px solid @gray-4;
  color: @gray-4;
  font-size: 6px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 2;
}

.stageMoreIconEdit {
  cursor: pointer;
  transition: color border-color 0.3s ease;

  &:hover {
    color: @green;
    border-color: @green;
    transition: color border-color 0.3s ease;

    :global {
      .icon {
        &:before {
          content: '\e904';
        }
      }
    }
  }
}

.rightToLeft {
  @media (min-width: @breakpoint-md) {
    flex-direction: row-reverse;
  }

  .stageMore {
    @media (min-width: @breakpoint-md) {
      left: auto;
      right: 50%;
    }
  }

  .stageMoreIcon {
    @media (min-width: @breakpoint-md) {
      transform: translateY(-50%) translateX(-50%) rotate(180deg);
    }
  }
}

.progressWrap {
  position: relative;
  margin: 0;

  @media (min-width: @breakpoint-lg) {
    margin: 174px 10px 0;
  }

  &.searchPage {
    @media (min-width: @breakpoint-lg) {
      margin-top: 74px;
      padding-top: 40px;
    }
  }
}

.newStageBox {
  @media (min-width: @breakpoint-lg) {
    position: absolute;
    right: calc(100% + 60px);
    top: -18px;
  }

  &:before {
    content: '';
    height: 2px;
    width: 150px;
    background-color: @gray-2;
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
  }

  &:only-child {
    &:before {
      display: none;
    }
  }

  &.mNeg {
    margin-top: -20px;

    @media (min-width: @breakpoint-lg) {
      margin: 0;
    }
  }
}

.detailsBox {
  padding: 0;

  @media (min-width: @breakpoint-xxl) {
    padding: 116px 0 0 40px;
  }
}

.detailsHead {
  display: flex;
  align-items: center;

  @media (min-width: @breakpoint-lg) {
    display: block;
  }
}

.detailsInput {
  width: calc(100% - 50px);
  padding-right: 15px;

  @media (min-width: @breakpoint-lg) {
    width: 100%;
    padding: 0;
  }

  :global {
    .ant-form-item-has-error {
      .ant-input {
        background-color: transparent;

        &:hover,
        &:focus {
          background-color: transparent;
          box-shadow: none;
        }
      }
    }
    .ant-input {
      font-weight: 700;
      border: 0;
      padding: 0;
      background-color: transparent;
      font-size: 24px;

      @media (min-width: @breakpoint-lg) {
        font-size: 36px;
      }

      &:focus {
        box-shadow: none;
      }

      &::placeholder {
        text-decoration: underline;
        color: @text-color;
      }
    }
  }
}

.selectContainer {
  position: relative;

  @media (min-width: @breakpoint-xs) {
    max-width: 316px;
  }

  @media (min-width: @breakpoint-xxl) {
    padding-top: 20px;
  }

  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}

.selectLabel {
  margin-bottom: 10px;
  font-weight: 500;
  color: @text-color-darken;
}

.stageDetails {
  color: @text-color-darken;
  font-weight: 500;

  &Desc {
    margin-bottom: 20px;
    color: @text-color;
    word-break: break-all;
  }

  &Title {
    @media (min-width: @breakpoint-md) {
      margin-bottom: 10px;
    }
  }

  &List {
    @media (min-width: @breakpoint-md) {
      max-height: 100px;
      overflow-y: auto;
      overflow-x: hidden;
      margin-right: -20px;
      padding-right: 20px;
    }

    li {
      display: flex;
      line-height: 1;
      min-height: 24px;
    }

    :global {
      .ant-btn {
        font-size: 18px;
        width: 22px;
        height: 24px;
        padding: 0;
        color: @text-color;
        line-height: 1;

        &:hover,
        &:focus {
          color: @text-color-darken;
        }
      }
    }

    &Name {
      align-self: center;
      flex-grow: 1;
      padding-right: 5px;
    }

    &Icon {
      font-size: 18px;
      text-align: center;
      width: 22px;
      height: 24px;
      color: @green;
      display: inline-block;

      &:global(.icon) {
        line-height: 1.5;
      }
    }
  }
}

@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;