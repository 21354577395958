@import 'src/styles/base/_variables.less';

body {
  .ant-modal-root {
    .modal-root {
      margin: 0 auto;
      top: 120px;
      width: 100% !important;
      max-width: 430px;
      padding: 0 10px 10px;

      @media (max-width: @breakpoint-sm - 1) {
        top: 120px;
      }
    }

    .ant-modal-content {
      box-sizing: border-box;
      border-radius: 20px;
      padding: 0;
    }

    .ant-modal-header {
      padding: 20px;
      position: relative;
      border-bottom: 0;
      border-radius: 14px 14px 0 0;
      margin-bottom: 0;

      @media (min-width: @breakpoint-md) {
        background-color: transparent;
      }

      &:before {
        content: '';
        position: absolute;
        left: 20px;
        right: 20px;
        bottom: 0;
        height: 1px;
        background-color: @gray-7;
      }
    }

    .ant-modal-title {
      text-transform: uppercase;
      font-weight: 700;
      font-size: 14px;
    }

    .ant-modal-wrap {
      overflow: auto;
    }

    .ant-modal-body {
      padding: 0;
      font-weight: 500;
    }

    .ant-modal-footer {
      padding: 20px;
      border: 0;
      position: relative;
      margin-top: 0;

      &:before {
        content: '';
        position: absolute;
        left: 20px;
        right: 20px;
        top: 0;
        height: 1px;
        background-color: @gray-7;
      }
    }

    .modal-scroll-wrapper {
      overflow-y: auto;
      overflow-x: hidden;
      padding: 20px 20px 0;
      max-height: 100%;

      @media (min-width: @breakpoint-sm) {
        max-height: calc(67vh - 110px);
      }
    }
  }

  .confirm-modal {
    .ant-modal-content {
      border: 1px solid rgba(@yellow-2, 0.4);
      box-shadow: none;
    }

    .ant-modal-confirm {
      .ant-modal-confirm-body {
        display: block;
      }
    }

    .ant-modal-confirm-body-wrapper {
      padding: 20px;
    }

    .confirm-icon {
      width: 40px;
      height: 40px;
      background-color: @yellow-2;
      border-radius: 10px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      color: @white;
      font-size: 24px;
      float: left;
      margin-right: 20px;
      margin-bottom: 15px;
    }

    .ant-modal-confirm-title {
      font-weight: 400 !important;
      flex: auto !important;
    }
  }

  .confirm-modal-dark-button {
    .ant-btn-default {
      background-color: @gray-5;
      border-color: @gray-5;
      color: @white;
    }
  }
}
