@import 'src/styles/base/_variables.less';

.clientSection {
  position: relative;
  padding: 60px 0;

  @media (min-width: @breakpoint-xl) {
    padding: 140px 0 112px;
  }
}

.pagination {
  top: 176px;
}

.title {
  max-width: 630px;
  margin-bottom: 40px;

  @media (min-width: @breakpoint-md) {
    font-size: 32px;
    max-width: none;
    margin-bottom: 60px;
  }

  @media (min-width: @breakpoint-xxl) {
    max-width: 720px;
  }
}

.list {
  @media (min-width: @breakpoint-xl) {
    max-width: 828px;
  }

  li {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    position: relative;

    @media (min-width: @breakpoint-md) {
      margin-bottom: 80px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.listCount {
  display: flex;
  align-self: flex-end;

  i {
    font-size: 34px;
    color: @yellow-2;

    @media (min-width: @breakpoint-md) {
      font-size: 56px;
    }
  }
}

.listText {
  width: calc(100% - 68px);
  color: @black-2;
  padding-left: 22px;

  @media (min-width: @breakpoint-md) {
    padding-left: 74px;
    width: calc(100% - 118px);
  }

  strong {
    @media (min-width: @breakpoint-md) {
      font-size: 36px;
      line-height: 30px;
    }
  }
}

@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;