@import 'src/styles/base/_variables.less';

.tabs-main {
  .tab-link {
    display: block;
    width: 100%;
    padding: 8px 14px;
  }

  .ant-tabs {
    display: block;

    @media (min-width: @breakpoint-md) {
      display: flex;
    }

    .ant-tabs-tab {
      &:hover,
      &:focus {
        color: @black-2;
      }
    }
  }

  .ant-tabs-nav-wrap {
    margin-bottom: 20px;

    @media (min-width: @breakpoint-md) {
      width: 200px;
      margin-bottom: 0;
    }
  }

  .ant-tabs-left {
    > .ant-tabs-content-holder {
      border-left: 0;
      margin-left: 0;

      > .ant-tabs-content {
        > .ant-tabs-tabpane {
          padding-left: 0;
        }
      }
    }

    > .ant-tabs-nav {
      .ant-tabs-ink-bar {
        left: 0;
        border: 2px solid @yellow-3;
        background-color: rgba(@gray-4, 0.1);
        width: 160px;
      }

      .ant-tabs-tab {
        height: 40px;
        padding: 0;
        margin: 0 0 2px 0;
        border: 1px solid @gray-4;

        & + .ant-tabs-tab {
          margin: 0 0 2px 0;
        }

        &:hover {
          border: 1px solid @black-2;
        }
      }
    }

    .ant-tabs-tab {
      width: 160px;
      margin: 0;
      color: @black-2;

      &.ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          font-weight: 600;
          color: @black-2;
          text-shadow: none;
          line-height: 20px;
        }
      }
    }
  }

  .ant-tabs-tab-btn {
    &:hover,
    &:focus {
      color: @black-2;
    }

    width: 100%;
    text-align: start;
  }
}

.inverse {
  .ant-tabs {
    .ant-tabs-tab {
      &:hover,
      &:focus {
        color: @white;
      }
    }
  }

  .ant-tabs-left {
    > .ant-tabs-nav {
      .ant-tabs-tab {
        border: 1px solid @gray-4;

        &:hover {
          border: 1px solid @white;
        }
      }
    }

    .ant-tabs-tab {
      color: @gray-4;

      &.ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          color: @white;
        }
      }
    }
  }

  .ant-tabs-tab-btn {
    &:hover,
    &:focus {
      color: @white;
    }
  }
}
