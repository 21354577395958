@import 'src/styles/base/_variables.less';

.wrapper {
  width: 100%;
  padding: 20px;
  border: 1px solid rgba(@gray-4, 0.3);
  border-radius: 8px;
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 20px;
  margin-bottom: 20px;
}

.table {
  line-height: 20px;

  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:last-child {
      > div {
        font-weight: 700;
        text-transform: uppercase;
      }
    }

    .box {
      display: flex;
      align-items: center;
      gap: 20px;

      > strong {
        font-size: 20px;
      }

      .discount {
        font-weight: 400;
        text-decoration: line-through;
      }
    }
  }
}

.btn {
  display: block;

  > div {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media (min-width: @breakpoint-md) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
    margin: -5px;

    > div {
      padding: 5px;
      margin-bottom: 0;
    }
  }

  :global {
    .ant-btn {
      width: 100%;

      @media (min-width: @breakpoint-md) {
        width: auto;
      }
    }
  }
}

.line {
  @media (min-width: @breakpoint-lg) {
    border-left: 1px solid rgba(@gray-4, 0.3);
  }
}

.input {
  :global {
    .ant-input {
      height: 40px;
    }

    .ant-input-group-addon {
      padding: 0;
      background-color: @black-2;
      border: 1px solid @black-2;

      .ant-btn {
        width: 80px;
        height: 38px;
        color: @white;
        font-size: 14px;
        box-shadow: none;
        border: 0;
        background-color: transparent;

        &:hover {
          color: @white;
          background-color: transparent;
          box-shadow: none;
        }
      }
    }
  }
}

.dark {
  :global {
    .ant-input-group-addon {
      background-color: @white;
      border: 1px solid @white;

      .ant-btn {
        color: @black-2;

        &:hover {
          color: @black-2;
        }
      }
    }
  }
}
@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;