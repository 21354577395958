.progress-fadein {
  animation: shockwaveJump 1s ease-out;
}

.progress-fadein:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  animation: shockwave 1s 0.65s ease-out;
}

.progress-fadein:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  animation: shockwave 1s 0.5s ease-out;
}

@keyframes shockwaveJump {
  0% {
    transform: scale(1);
  }
  40% {
    transform: scale(1.18);
  }
  50% {
    transform: scale(0.98);
  }
  55% {
    transform: scale(1.02);
  }
  60% {
    transform: scale(0.98);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes shockwave {
  0% {
    transform: scale(1);
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.25), inset 0 0 1px rgba(0, 0, 0, 0.25);
  }
  95% {
    box-shadow: 0 0 50px rgba(0, 0, 0, 0), inset 0 0 30px rgba(0, 0, 0, 0);
  }
  100% {
    transform: scale(2.25);
  }
}

.progress-fadeout {
  animation: 0.8s wiggle ease !important;
}

@keyframes wiggle {
  0% {
    opacity: 1;
    transform: rotate(-3deg);
  }
  20% {
    transform: rotate(20deg);
  }
  40% {
    transform: rotate(-15deg);
  }
  60% {
    transform: rotate(5deg);
  }
  90% {
    transform: rotate(-1deg);
  }
  100% {
    opacity: 0.3;
    transform: rotate(0);
  }
}
