@import 'src/styles/base/_variables.less';

.detailsInput {
  width: 100%;
  padding: 0;
  position: relative;

  :global {
    .ant-form-item {
      margin-bottom: 0;
    }

    .ant-form-item-has-error {
      .ant-input,
      .ant-input-affix-wrapper {
        background-color: transparent;

        &:hover,
        &:focus {
          background-color: transparent;
          box-shadow: none;
        }
      }
    }

    .ant-input {
      font-weight: 500;
      border: 0;
      padding: 0;
      background-color: transparent;
      height: 32px;

      &:focus {
        box-shadow: none;
      }

      &::placeholder {
        color: @gray-4;
      }

      &:focus::placeholder {
        color: transparent;
      }
    }

    .ant-input-affix-wrapper {
      border: 0;
      background-color: transparent;
      font-style: 18px;

      &.ant-input-affix-wrapper-focused {
        border: 0;
        box-shadow: none;

        & .ant-input-prefix {
          display: none;
        }
      }
    }

    .ant-input-prefix {
      margin: 0;
    }

    .ant-form-item-explain {
      font-weight: 400;
    }
  }
}

.btn {
  position: relative;
  left: -10px;

  @media (min-width: @breakpoint-xl) {
    left: -50px;
  }

  &Wrap {
    :global {
      .ant-btn {
        padding: 2px 10px;
        width: 132px;
        height: 50px;
        border-radius: 100px;
        background-color: @green;
        border: 1px solid @gray-3;

        &:hover {
          background-color: rgba(@green, 0.8);
          border: 1px solid @gray-3;
        }

        &:disabled {
          background-color: rgba(@gray-4, 0.9);
          filter: grayscale(1);

          &:hover {
            background-color: rgba(@gray-4, 0.9);
          }
        }
      }
    }
  }

  &Add {
    display: flex;
    align-items: center;
  }

  &Circle {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    background-color: @white;
    position: relative;
    border: 2px solid @green;
    color: @green;
    width: 30px;
    height: 30px;
    font-size: 12px;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      border: 3px solid @green;
      width: 22px;
      height: 22px;
    }
  }

  &Text {
    color: @white;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    padding-left: 10px;
  }
}

.cursor {
  position: absolute;
  width: 0.5px;
  height: 24px;
  background-color: @black-2;
  left: 0px;
  top: 16%;
  animation: cursor-blink 800ms infinite;
  opacity: 1;
}

@keyframes cursor-blink {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  color: @gray-4;

  > div {
    width: 64px;
  }
}

.active {
  font-weight: 700;
  color: @gray-5;
}

.category {
  :global {
    .ant-select {
      &:not(.ant-select-disabled) {
        &:not(.ant-select-customize-input) {
          &:not(.ant-pagination-size-changer) {
            &:hover {
              .ant-select-selector {
                border-color: @gray-4;
                box-shadow: none;
              }
            }
          }
        }
      }
    }

    .ant-select-focused {
      &.ant-select {
        &:not(.ant-select-disabled) {
          &:not(.ant-select-customize-input) {
            &:not(.ant-pagination-size-changer) {
              .ant-select-selector {
                border-color: @gray-4;
                box-shadow: none;
              }
            }
          }
        }
      }
    }
  }
}

@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;