@import 'src/styles/base/_variables.less';

.card {
  width: 100%;

  :global {
    .ant-radio-group {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }

    .ant-radio-button-wrapper {
      padding: 0;
      border: 0;
      flex-shrink: 0;
      height: 100%;
      width: 100%;

      @media (min-width: @breakpoint-md) {
        width: 50%;
      }

      @media (min-width: @breakpoint-lg) {
        width: 33.33%;
      }

      &:first-child,
      &:last-child {
        border: 0;
      }

      &:not(&:first-child) {
        &::before {
          display: none;
        }
      }
    }
  }

  &Wrapper {
    padding: 5px;
  }
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: @gray-4;
  margin-bottom: 10px;

  > i {
    color: @white;
  }
}

@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;