@import 'src/styles/base/_variables.less';

.scheduleSection {
  position: relative;
  padding: 124px 0 60px;

  @media (min-width: @breakpoint-xxl) {
    height: 1000px;
    padding: 286px 0;
  }
}

.pagination {
  top: 210px;
}

.title {
  margin-bottom: 40px;

  @media (min-width: @breakpoint-lg) {
    margin-bottom: 80px;
    max-width: 610px;
  }
}

.text {
  color: @text-color-darken;
  font-size: 14px;
  line-height: 16px;

  @media (min-width: @breakpoint-md) {
    font-size: 20px;
    line-height: 22px;
    max-width: 530px;
  }

  @media (min-width: @breakpoint-lg) {
    font-size: 24px;
    line-height: 30px;
  }
}

@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;