@import 'src/styles/base/_variables.less';

.label {
  display: inline-block;
  padding: 10px;
  border: 1px solid;
  border-radius: 8px;
  line-height: 18px;
  text-align: center;
  color: @gray-4;

  > span {
    font-weight: 700;
  }

  &.monthly,
  &.annually {
    background: rgba(@yellow-3, 0.1);
    border-color: @yellow-3;
  }

  &.annually {
    color: @gray-5;
  }

  &.label-dark {
    color: @gray-4;

    &.monthly,
    &.annually {
      background: rgba(147, 149, 151, 0.1);
      border: 1px solid rgba(147, 149, 151, 0.3);
    }

    &.annually {
      color: @white;
    }
  }
}
