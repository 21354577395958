@import 'src/styles/base/_variables.less';

.table {
  display: flex;
  font-size: 14px;
  color: @text-color;

  > div {
    width: calc(100% / 5);
  }
}

.tableRow {
  display: flex;
  justify-content: space-between;

  @media (min-width: @breakpoint-xl) {
    justify-content: flex-start;
  }
}

.tableCell {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.tableCol {
  width: calc(100% - 182px);
  text-align: center;

  @media (min-width: @breakpoint-xl) {
    width: calc((100% - 212px) / 4);
    border-left: 1px solid @gray-3;
  }

  &:first-child {
    width: 182px;
    text-align: left;

    @media (min-width: @breakpoint-xl) {
      width: 212px;
      border-left: 0;
    }
  }
}

.card {
  width: 100%;
  border-radius: 8px;
  padding: 10px;
  text-align: center;

  &.cardAccent {
    border: 1px solid @gray-4;
  }

  &Title {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 10px;
  }

  &Caption {
    font-size: 14px;
    line-height: 20px;
    color: @text-color;

    strong {
      color: @black-2;
      font-weight: 600;
      font-size: 20px;
      line-height: 20px;
    }
  }

  &Price {
    font-size: 24px;
    line-height: 20px;
  }

  &Btn {
    padding: 0 5px;
    margin-top: auto;
  }
}

.tableBody {
  border: 1px solid @gray-3;
  border-width: 0 1px 1px;
  border-radius: 0 0 4px 4px;

  @media (min-width: @breakpoint-xl) {
    border-radius: 0 0 20px 20px;
  }

  .tableRow {
    border-bottom: 1px solid @gray-3;

    &:last-child {
      border-bottom: 0;
    }
  }

  .tableCol {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 40px;
    padding: 6px 20px;
    font-size: 12px;

    @media (min-width: @breakpoint-xl) {
      padding: 0 10px;
      font-size: 14px;
    }

    i {
      color: @text-color-darken;

      @media (min-width: @breakpoint-xl) {
        color: @text-color;
        font-size: 18px;
      }
    }
  }

  .tableRowHead {
    .tableCol {
      padding: 35px 10px 10px;
    }
  }
}

.footer {
  border: 1px solid @gray-3;
  border-radius: 20px;
  color: @text-color-darken;
  padding: 20px;
  margin-bottom: 20px;

  @media (min-width: @breakpoint-xl) {
    padding: 20px 30px;
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }

  &Caption {
    font-weight: 400;
    max-width: 200px;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    margin: 0 auto 25px;

    @media (min-width: @breakpoint-xl) {
      max-width: 300px;
      font-size: 18px;
      padding-left: 35px;
      margin: 0;
      text-align: left;
    }
  }

  &Icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 58px;
    height: 58px;
    position: relative;
    border-radius: 50%;
    border: 1px solid @gray-5;
    font-size: 22px;
    color: @gray-5;
    margin: 0 auto 20px;

    @media (min-width: @breakpoint-xl) {
      margin: 0;
    }

    &:before,
    &:after {
      content: '\e90f';
      font-family: 'icon-font';
      position: absolute;
    }

    &:before {
      font-size: 8px;
      color: @gray-4;
      top: -4px;
      left: -4px;
      transform: rotate(45deg);
    }

    &:after {
      font-size: 5px;
      color: rgba(@gray-5, 0.2);
      transform: rotate(45deg);
      top: -5px;
      right: 0;
    }

    &Wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      border: 2px solid @gray-5;

      &:before,
      &:after {
        content: '\e90f';
        font-family: 'icon-font';
        position: absolute;
      }

      &:before {
        font-size: 4px;
        color: @gray-4;
        bottom: -4px;
        left: 5px;
        transform: rotate(45deg);
      }

      &:after {
        font-size: 8px;
        color: @gray-5;
        transform: rotate(45deg);
        bottom: -5px;
        right: 0;
      }
    }

    i {
      &:after {
        content: '\e90f';
        font-family: 'icon-font';
        position: absolute;
        bottom: 20px;
        left: -10px;
        font-size: 3px;
      }
    }
  }

  &Price {
    display: flex;
    margin: 0 -10px;
    line-height: 15px;

    @media (min-width: @breakpoint-xl) {
      line-height: 27px;
      margin: 0 0 0 auto;
    }

    &Col {
      padding: 0 10px;
      width: 50%;
      text-align: center;

      @media (min-width: @breakpoint-xl) {
        width: auto;
        text-align: left;
        padding: 0 20px;
      }
    }

    &Accent {
      font-size: 36px;
      letter-spacing: -3px;
    }

    &Symbol {
      font-size: 18px;
    }
  }
}

.description {
  color: @text-color-darken;
}

.collapse {
  :global {
    .ant-collapse {
      border: 0;
      background-color: transparent;
      margin-bottom: 20px;

      .ant-collapse-content {
        border-top: 0;
        background-color: transparent;
      }

      .ant-collapse-item {
        &:last-child {
          border-bottom: 0;
        }

        &.ant-collapse-no-arrow {
          &.ant-collapse-item-active {
            .ant-collapse-header {
              border-radius: 4px 4px 0 0;
            }
          }

          .ant-collapse-header {
            display: block;
            padding: 0;
            border-radius: 4px;
            overflow: hidden;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          }
        }
      }

      .ant-collapse-content-box {
        padding: 0;
      }
    }
  }

  &Head {
    min-height: 150px;
    border-left: 50px solid @gray-5;
    padding: 20px;
    text-align: center;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &.collapseHeadAccent {
      background-color: @primary-color;
    }

    &.collapseHeadAccent {
      &.dark {
        background-color: #23262b;
        color: @white;
      }
    }

    &Transform {
      font-size: 11px;
      color: @white;
      position: absolute;
      bottom: -16px;
      left: -33px;
      transform-origin: 0 0;
      transform: rotate(270deg);
      width: 150px;
    }

    &Title {
      font-weight: 700;
      text-transform: uppercase;
    }

    &Text {
      margin-bottom: 15px;
    }

    &Price {
      font-size: 24px;
      line-height: 20px;
    }
  }
}

.fixedCol {
  flex-shrink: 0;

  > div {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    height: 100px;
    font-weight: 500;
    color: @gray-5;
    border-bottom: 1px solid @gray-3;

    &:first-child,
    &:last-child {
      border-bottom: 0;
    }
  }
}

.mainCol {
  position: relative;

  > div {
    height: 100px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    border-bottom: 1px solid @gray-3;

    &:first-child,
    &:last-child {
      border-bottom: 0;
    }
  }

  &:hover {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 10px;
      right: 0;
      border: 1px solid @green;
      border-radius: 8px;
    }
  }
}

.details {
  width: 100%;
  padding: 10px;
  border: 1px solid rgba(@gray-4, 0.3);
  border-radius: 8px;

  @media (min-width: @breakpoint-md) {
    padding: 20px;
  }

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  @media (min-width: @breakpoint-lg) {
    display: flex;
    gap: 20px;
  }

  &Card {
    flex-shrink: 0;
    margin-bottom: 20px;

    @media (min-width: @breakpoint-lg) {
      margin-bottom: 0;
    }

    :global(.card) {
      border-color: @green;

      @media (min-width: @breakpoint-md) {
        width: 300px;
        min-height: 222px;
      }
    }
  }

  &Info {
    width: 100%;
  }

  &Btn {
    display: block;

    > div {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    @media (min-width: @breakpoint-md) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-end;
      flex-grow: 1;
      margin: -5px;

      > div {
        padding: 5px;
        margin-bottom: 0;
      }
    }

    :global {
      .ant-btn {
        width: 100%;

        @media (min-width: @breakpoint-md) {
          width: auto;
        }
      }
    }
  }
}

.label {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 10px;
}

.input {
  margin-bottom: 20px;

  :global {
    .ant-input {
      height: 40px;
    }
  }
}

.box {
  width: 100%;
  max-width: 940px;
  margin: 0 auto 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}

.switch {
  display: flex;
  align-items: center;
  gap: 10px;
  color: @gray-4;

  > div {
    &:first-child,
    &:last-child {
      width: 64px;
    }

    &:first-child {
      text-align: end;
    }
  }

  &Checked {
    font-weight: 700;
    color: @gray-5;
  }

  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}

.switchDark {
  .switchChecked {
    color: @white;
  }
}


.dark {
  background-color: #23262b69;
  color: @gray-4;
}
@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;