@import 'src/styles/base/_variables.less';

.pagination {
  display: none;

  @media (min-width: @breakpoint-xxl) {
    display: block;
    width: 200px;
    position: absolute;
    right: 50%;
    margin-right: -800px;
    z-index: 2;
  }

  li {
    @media (min-width: @breakpoint-xxl) {
      margin-bottom: 20px;
      position: relative;
    }

    a {
      @media (min-width: @breakpoint-xxl) {
        font-weight: 600;
        font-size: 14px;
        display: inline-flex;

        &:hover {
          span {
            font-weight: 500;
          }
        }
      }

      span {
        @media (min-width: @breakpoint-xxl) {
          color: @black-2;
          align-self: center;
          padding-left: 14px;
          font-weight: 500;
        }
      }

      &:before {
        @media (min-width: @breakpoint-xxl) {
          content: '\e949';
          font-family: 'icon-font';
          display: flex;
          justify-content: center;
          align-items: center;
          width: 36px;
          height: 36px;
          border-radius: 50%;
          border: 1px solid rgba(@gray-4, 0.2);
          background-color: rgba(@gray-4, 0.2);
          box-shadow: 0px 0px 0px 5px @white-2 inset;
          color: @black-2;
          flex-shrink: 0;
          font-size: 11px;
        }
      }

      &.active {
        &:before {
          @media (min-width: @breakpoint-xxl) {
            background-color: @yellow-2;
            color: @white;
            box-shadow: 0px 0px 0px 3px @white-2 inset;
            border: 2px solid @yellow-2;
          }
        }

        span {
          @media (min-width: @breakpoint-xxl) {
            color: @yellow-2;
            font-weight: 700;
          }
        }

        li {
          &:before {
            @media (min-width: @breakpoint-xxl) {
              background-color: @yellow-2;
            }
          }
        }
      }
    }

    &:before {
      @media (min-width: @breakpoint-xxl) {
        content: '';
        position: absolute;
        width: 1px;
        height: 22px;
        background-color: rgba(@gray-4, 0.2);
        top: 100%;
        left: calc(36px / 2);
      }
    }

    &:nth-last-child(2) {
      @media (min-width: @breakpoint-xxl) {
        margin-bottom: 100px;
      }

      &:before {
        @media (min-width: @breakpoint-xxl) {
          height: 102px;
        }
      }
    }

    &:last-child {
      @media (min-width: @breakpoint-xxl) {
        margin-bottom: 0;
      }

      &:before {
        @media (min-width: @breakpoint-xxl) {
          display: none;
        }
      }

      a {
        span {
          @media (min-width: @breakpoint-xxl) {
            color: @gray-5;
            font-weight: 700;
            text-transform: uppercase;
          }
        }

        &:before {
          @media (min-width: @breakpoint-xxl) {
            content: '\e902';
            font-family: 'icon-font';
            background-color: @gray-5;
            border-color: @gray-5;
            color: @white;
            box-shadow: 0px 0px 0px 5px @white-2 inset;
            font-size: 10px;
          }
        }
      }
    }

    &.active {
      &:before {
        @media (min-width: @breakpoint-xxl) {
          width: 2px;
          left: calc(34px / 2);
          background-color: @yellow-2;
        }
      }

      &:not(&:first-child) {
        &:after {
          @media (min-width: @breakpoint-xxl) {
            content: '';
            position: absolute;
            width: 2px;
            height: 22px;
            background-color: @yellow-2;
            top: -54%;
            left: calc(34px / 2);
          }
        }
      }
    }
  }

  &.paginationDark {
    li {
      a {
        span {
          @media (min-width: @breakpoint-xxl) {
            color: @white;
          }
        }

        &:before {
          @media (min-width: @breakpoint-xxl) {
            color: @white;
            box-shadow: 0px 0px 0px 5px @black-2 inset;
          }
        }

        &.active {
          &:before {
            @media (min-width: @breakpoint-xxl) {
              background-color: @yellow-2;
              color: @black-2;
              box-shadow: 0px 0px 0px 4px @black-2 inset;
              border: 2px solid @yellow-2;
            }
          }

          span {
            @media (min-width: @breakpoint-xxl) {
              color: @yellow-2;
            }
          }
        }
      }

      &:last-child {
        a {
          span {
            @media (min-width: @breakpoint-xxl) {
              color: @white;
            }
          }

          &:before {
            @media (min-width: @breakpoint-xxl) {
              box-shadow: 0px 0px 0px 5px @black-2 inset;
              color: @black-2;
              background-color: @white;
              border: 2px solid @white;
            }
          }

          &.active {
            span {
              @media (min-width: @breakpoint-xxl) {
                color: @white;
              }
            }
          }
        }
      }

      &.active {
        &:before {
          @media (min-width: @breakpoint-xxl) {
            width: 2px;
            left: calc(34px / 2);
            background-color: @yellow-2;
          }
        }

        &:not(&:first-child) {
          &:after {
            @media (min-width: @breakpoint-xxl) {
              content: '';
              position: absolute;
              width: 2px;
              height: 22px;
              background-color: @yellow-2;
              top: -54%;
              left: calc(34px / 2);
            }
          }
        }
      }
    }
  }

  &.paginationLeft {
    left: 50%;
    right: auto;
    margin: 0 0 0 -800px;
  }
}

@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;