@import 'src/styles/base/_variables.less';

.informSection {
  position: relative;
  z-index: 2;

  .pagination {
    top: 200px;
  }

  .titleBox {
    @media (max-width: @breakpoint-md - 1) {
      color: @black-2;
      margin-bottom: 80px;
    }
  }
}

.happy {
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  background-image: url('../../../../../src/assets/img/landing/circle-mobile.png');
  background-position: center left;
  height: 512px;

  @media (min-width: @breakpoint-lg) {
    padding: 100px 0 40px;
    height: 580px;
    background-image: url('../../../../../src/assets/img/landing/circle.svg');
    background-position: right -40% center;
  }

  @media (min-width: @breakpoint-xl) {
    height: 780px;
  }

  @media (min-width: @breakpoint-xxl) {
    padding: 260px 0 40px;
    height: 1000px;
    background-position: right -22% center;
  }
}

.title {
  margin-bottom: 20px;

  @media (min-width: @breakpoint-md) {
    margin-bottom: 0;
  }

  strong {
    white-space: nowrap;
  }
}

.text {
  color: @black-2;
  max-width: 168px;
  padding: 150px 0 0 0;
  margin-left: auto;

  @media (min-width: @breakpoint-xs) {
    max-width: 250px;
  }

  @media (min-width: @breakpoint-sm) {
    max-width: 360px;
  }

  @media (min-width: @breakpoint-lg) {
    margin: 0;
    padding: 0 15px 40px;
    max-width: 335px;
  }

  @media (min-width: @breakpoint-xl) {
    max-width: 524px;
    padding: 200px 0 0 0;
  }

  @media (min-width: @breakpoint-xxl) {
    padding: 0;
    margin: 0 auto 0 110px;
  }

  @media (min-width: @breakpoint-xxl) {
    max-width: 610px;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    @media (min-width: @breakpoint-sm) {
      font-size: 18px;
      line-height: 20px;
    }

    @media (min-width: @breakpoint-md) {
      max-width: 468px;
      font-size: 24px;
      line-height: 30px;
    }
  }
}

.subText {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  margin-bottom: 20px;

  @media (min-width: @breakpoint-md) {
    font-size: 24px;
    line-height: 25px;
  }
}

.tagline {
  font-weight: 700;
  margin-bottom: 40px;
  line-height: 40px;
  font-size: 44px;
  text-transform: uppercase;
  color: @gray-5;

  @media (min-width: @breakpoint-lg) {
    font-size: 128px;
    line-height: 100px;
  }

  i {
    font-size: 32px;

    @media (min-width: @breakpoint-lg) {
      font-size: 90px;
    }
  }

  &Happy {
    display: inline-flex;
    align-items: center;
  }
}

.advantages {
  padding-top: 110px;

  @media (min-width: @breakpoint-md) {
    padding-top: 200px;
  }
}

.titleBox {
  text-align: center;

  @media (min-width: @breakpoint-md) {
    padding: 75px 20px 84px;
    background-color: @white;
    box-shadow: 8px 8px 16px rgba(166, 171, 189, 0.5);
    border-radius: 400px;
    position: relative;
  }

  &:before {
    @media (min-width: @breakpoint-md) {
      content: '';
      position: absolute;
      top: 40px;
      left: 50%;
      transform: translateX(-50%);
      width: 54px;
      height: 5px;
      background-color: #cacaca;
    }
  }
}

.row {
  @media (min-width: @breakpoint-md) {
    display: flex;
    justify-content: center;
    position: relative;
    margin: -46px -8px 0;
  }
}

.card {
  width: 260px;
  text-align: center;
  padding: 0 8px;
  margin: 0 auto 40px;

  @media (min-width: @breakpoint-md) {
    margin: 0;
  }

  &Circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90px;
    height: 90px;
    border: 8px solid @white;
    background-color: @gray-5;
    color: @white;
    border-radius: 50%;
    font-size: 39px;
    margin: 0 auto 10px;
    box-shadow: 12px 12px 16px rgb(166 171 189);

    img {
      display: block;
      width: 38px;
    }
  }

  &Line {
    width: 7px;
    height: 40px;
    margin: 0 auto 20px;
  }

  &Text {
    font-size: 24px;
    line-height: 30px;
    color: @text-color;

    strong {
      color: @text-color-darken;
    }
  }
}

.subTitle {
  font-size: 24px;
  text-transform: uppercase;
  color: @black-2;
  margin-bottom: 40px;

  i {
    color: @yellow-3;
  }
}

@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;