@import 'src/styles/base/_variables.less';

body {
  .ant-switch {
    width: 90px;
    height: 30px;

    &.icon-switch {
      background: @yellow-3;

      .ant-switch-handle {
        &::before {
          content: '\e928';
          font-family: 'icon-font';
          font-size: 11px;
          color: @black-2;
        }
      }
    }

    &:hover {
      background: @yellow-3;

      &:not(.ant-switch-disabled) {
        background: @yellow-3;
      }
    }

    .ant-switch-inner {
      padding-inline-start: 30px;
    }

    .ant-switch-handle {
      width: 23px;
      height: 23px;
      top: 3px;
      left: 4px;

      &::before {
        border-radius: 20px;
        background: linear-gradient(180deg, #ffffff 0%, #e8eaea 100%);
      }
    }

    &.ant-switch-checked {
      background: @yellow-3;

      .ant-switch-handle {
        inset-inline-start: calc(100% - 23px - 3px);
      }

      &:hover {
        background: @yellow-3;

        &:not(.ant-switch-disabled) {
          background: @yellow-3;
        }
      }
    }

    .ant-switch-inner {
      .ant-switch-inner-checked {
        margin-top: 3px;
      }
    }
  }
}
