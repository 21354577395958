@import 'src/styles/base/_variables.less';


.tree {
  :global {
    .ant-select-multiple {
      .ant-select-selection-item {
        display: flex;
        height: 32px;
        padding: 4px 5px;
        justify-content: center;
        align-items: center;
        gap: 5px;
        border-radius: 10px;
        background-color: #e6e6e6;
        font-size: 14px;
        line-height: 20px;
        color: @gray-5;
      }

      .ant-select-selection-item-remove {
        font-size: 14px;
        color: @gray-5;
      }
    }
  }
}



.treeDrop {
  &:global(.ant-tree-select-dropdown) {
    border-radius: 8px;
    padding: 0;
    border: 0;
  }

  :global {
    .ant-select-tree {
      .ant-select-tree-treenode {
        width: 100%;
        gap: 5px;
        padding: 5px 20px 5px 10px !important;
        flex-direction: row-reverse;

        &:hover {
          background-color: #e7f4ff;
        }

        .ant-select-tree-switcher {
          display: none;
        }

        .ant-select-tree-indent {
          display: none;
        }

        .ant-select-tree-checkbox {
          margin: 0;

          .ant-select-tree-checkbox-inner {
            width: 24px;
            height: 24px;
            border: 2px solid @gray-4;
            background-color: transparent;
            border-radius: 6px;
          }
        }

        .ant-select-tree-checkbox-checked {
          .ant-select-tree-checkbox-inner {
            border-color: #200e32;

            &::after {
              border-color: #200e32;
              top: 48%;
              inset-inline-start: 30%;
              width: 6px;
              height: 10px;
              border-radius: 1px;
            }
          }
        }

        .ant-select-tree-checkbox + span {
          padding: 0;

          &:hover {
            background-color: transparent;
          }
        }
      }
    }
  }
}

.dark {
  :global {
    .ant-select-tree {
      .ant-select-tree-treenode {
        .ant-select-tree-checkbox {
          .ant-select-tree-checkbox-inner {
            border-color: @white;
          }
        }

        .ant-select-tree-checkbox-checked {
          .ant-select-tree-checkbox-inner {
            border-color: @white;

            &::after {
              border-color: @white;
            }
          }
        }
      }

      .ant-select-tree-treenode {
        &:hover {
          background-color: @gray-4;
        }
      }
    }
  }
}
@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;