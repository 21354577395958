@import 'src/styles/base/_variables.less';

body {
  .ant-popover {
    z-index: 100;
    width: 100%;
    max-width: 430px;
    padding: 0 10px;

    .ant-popover-inner {
      border: 3px solid @white;
      box-sizing: border-box;
      border-radius: 20px;
      box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
      padding: 0;
    }
  }

  .ant-popover-content {
    margin-bottom: 15px;
  }

  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner-content {
    padding: 20px 20px 10px;
  }
}

.popover {
  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid @gray-3;
  }

  &__title {
    color: @text-color-darken;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    padding-right: 10px;
  }

  &__date {
    font-weight: 500;
  }

  &__body {
    padding: 20px 0;
    font-weight: 500;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    margin: 0 -5px;
    padding-top: 10px;
    border-top: 1px solid @gray-3;

    > div {
      padding: 0 5px;
    }
  }
}
