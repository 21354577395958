@import 'src/styles/base/_variables.less';

.widget {
  position: fixed;
  top: 100px;
  right: -500px;
  width: 340px;
  min-height: 300px;
  height: calc(100vh - 300px);
  overflow-y: scroll;
  transition: right 0.5s ease;
  border-radius: 8px 0 0 8px;
  border: 3px solid @white;
  background-color: @white;
  box-shadow: 0px 20px 40px 0px rgba(@black, 0.25);
  z-index: 99;

  @media (min-width: @breakpoint-md) {
    width: 400px;
    height: 600px;
  }

  &.active {
    right: 0;
  }

  &Head {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 10px;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    position: sticky;
    top: 0;
    background-color: @white;

    @media (min-width: @breakpoint-md) {
      padding: 20px;
      font-size: 24px;
      line-height: 28px;
    }
  }

  &Body {
    min-height: 100%;
    height: 100%;
    overflow: auto;
    padding: 0 10px 10px 10px;

    @media (min-width: @breakpoint-md) {
      padding: 0 20px 20px 20px;

    }
  }

  &.dark {
    background-color: @black-2;
    border-color: @black-2;

    .widgetHead {
      background-color: @black-2;
    }
  }
}


.btn {
  :global {
    .ant-btn {
      &:not(.ant-btn-link) {
        padding: 0;
        background-color: transparent;
        border: 0;
        box-shadow: none;
        font-size: 16px;
        color: @gray-5;

        &:hover {
          box-shadow: none;
          border: 0;
          color: @gray-5;
          opacity: 0.7;
        }

        &:not(.ant-btn-circle) {
          min-width: 24px;
        }
      }
    }
  }

  &.dark {
    :global {
      .ant-btn {
        &:not(.ant-btn-link) {
          color: @white;
        }
      }
    }
  }
}
@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;