@import 'src/styles/base/_variables.less';

body {
  .ant-tooltip-arrow {
    display: none;
  }

  .ant-tooltip-inner {
    min-width: 148px;
    background-color: @white;
    border: 1px solid @gray-3;
    border-radius: 20px;
    color: @text-color-darken;
    text-align: center;
    box-shadow: 4px 4px 10px rgba(166, 171, 189, 0.65);
  }
}
