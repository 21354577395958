@import 'src/styles/base/_variables.less';

.page {
  width: 100%;
  flex-shrink: 1;
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  overflow: hidden;
  background-color: @white;
  position: relative;

  &Item {
    display: none;

    @media (min-width: @breakpoint-xxl) {
      display: block;
      position: absolute;
    }

    &.one {
      width: 38px;
      top: 34%;
      left: 14%;
    }

    &.two {
      width: 48px;
      top: 56%;
      left: 19%;
    }

    &.three {
      width: 44px;
      bottom: 10%;
      left: 13%;
    }

    &.four {
      width: 40px;
      top: 28%;
      right: 10%;
    }

    &.five {
      width: 56px;
      top: 45%;
      right: 15%;
    }

    &.six {
      width: 55px;
      right: 19%;
      bottom: 28%;
    }

    &.seven {
      width: 42px;
      right: 11%;
      bottom: 12%;
    }
  }
}

.wrapper {
  width: 100%;
  flex-shrink: 1;
  flex-grow: 1;
  flex-basis: 0;
  overflow-y: auto;
  overflow-x: hidden;
}

.bannerWrapper {
  padding: 20px;
}

.banner {
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  min-height: 180px;
  background: @black-2;
  box-shadow: 0px 3px 8px rgba(@black, 0.1);
  border-radius: 8px 8px 0px 0px;
  font-weight: 700;
  padding: 20px;
  color: @white;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right -30% bottom;
  background-image: url('./../../assets/img/landing/triangle.svg');
  margin-bottom: 20px;

  > div {
    width: 220px;
    font-size: 28px;
    line-height: 32px;
  }

  @media (min-width: @breakpoint-md) {
    background-position: right bottom;

    > div {
      width: auto;
      font-size: 24px;
      line-height: 20px;
    }
  }

  @media (min-width: @breakpoint-lg) {
    justify-content: center;
    background-size: auto;
    background-position: bottom 12% right -12%;
    margin-bottom: 40px;
  }
}

@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;