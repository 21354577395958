*,
*::before,
*::after {
  box-sizing: border-box;
  outline: none;
}

body {
  margin: 0;
}

img,
video,
canvas,
audio,
iframe,
embed,
object {
  vertical-align: middle;
  max-width: 100%;
}

img {
  width: 100%;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

input {
  border: 0;
  outline: 0;

  &[type='text'],
  &[type='password'],
  &[type='email'],
  &[type='search'] {
    -webkit-appearance: none;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

textarea {
  resize: none !important;
  -webkit-appearance: none;
}

body {
  ul,
  li,
  ol,
  dl {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

address {
  font-style: normal;
}
