@import 'src/styles/base/_variables.less';

.chart {
  height: 340px;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid rgba(@gray-4, 0.3);

  &Title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18px;

    h1 {
      font-size: 24px;
      font-weight: 700;
    }
  }

  &Projects {
    text-align: right;
    font-size: 38px;
    line-height: 30px;

    >div:not(:first-child) {
      font-size: 14px;
      line-height: 20px;
      color: @gray-4;
      text-transform: capitalize;
    }
  }

  &Footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 18px;
  }

  &Label {
    display: flex;
    align-items: center;
    padding-left: 21px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      width: 16px;
      height: 16px;
      border-radius: 4px;
      background: linear-gradient(180deg, #5A7EF2 0%, #C15AF2 100%);
    }
  }

  &Box {
    display: flex;
    align-items: center;
    margin: 0 -5px;

    >div {
      padding: 0 5px;
    }
  }
}

.btn {
  :global {
    .ant-btn {
      &:not(.ant-btn-link) {
        padding: 0;
        background-color: transparent;
        border: 0;
        box-shadow: none;
        font-size: 28px;
        color: @blue-2;

        &:hover {
          box-shadow: none;
          border: 0;
          color: @blue-2;
          opacity: 0.7;
        }

        &:disabled {
          color: @gray-7;
        }

        &:not(.ant-btn-circle) {
          min-width: 28px;
        }
      }
    }
  }
}
@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;