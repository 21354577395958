@import 'src/styles/base/_variables.less';

.activity {
  width: 100%;
  max-height: 340px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid rgba(@gray-4, 0.3);

  @media (min-width: @breakpoint-md) {
    max-height: 546px;
  }

  @media (min-width: @breakpoint-lg) {
    padding: 20px;
    max-height: 510px;

  }

  &Wrap {
    height: 100%;
    overflow: hidden;
  }

  &Title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;

    :global {
      .ant-btn {
        font-size: 16px;
        color: @blue-2;
        padding: 0;
      }
    }
  }
}

.date {
  color: @gray-4;
  font-size: 14px;
  line-height: 20px;
  text-transform: capitalize;
  margin-bottom: 20px;
}

.item {
  display: flex;
  gap: 20px;
  line-height: 20px;
  margin-bottom: 20px;

  p {
    color: @gray-4;
    margin-bottom: 0;
  }
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  border-radius: 30px;
  color: @white;
  font-size: 16px;

  &.project {
    background-color: @yellow-3;
  }

  &.tracker {
    background-color: @blue-2;
  }

  &.deleted {
    background-color: #FF728C;
  }

  &.completed {
    background-color: @green;
  }

  &.archivated {
    background-color: @gray-4;
  }

  &.reset {
    background-color: #B95EF3;
  }
}
@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;