.grid-row {
  width: 100%;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: auto minmax(0, 1fr);
  position: relative;
  flex-shrink: 1;
  flex-grow: 1;
  flex-basis: 0;
}
@media (min-width: 992px) {
  .grid-row {
    grid-template-rows: minmax(0, 1fr);
    grid-template-columns: 500px minmax(0, 1fr);
  }
}
@media (min-width: 1600px) {
  .grid-row {
    grid-template-columns: 600px minmax(0, 1fr);
  }
}
.grid-scroll {
  overflow-x: hidden;
  overflow-y: auto;
}
*,
*::before,
*::after {
  box-sizing: border-box;
  outline: none;
}
body {
  margin: 0;
}
img,
video,
canvas,
audio,
iframe,
embed,
object {
  vertical-align: middle;
  max-width: 100%;
}
img {
  width: 100%;
}
article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}
[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}
input {
  border: 0;
  outline: 0;
}
input[type='text'],
input[type='password'],
input[type='email'],
input[type='search'] {
  -webkit-appearance: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
textarea {
  resize: none !important;
  -webkit-appearance: none;
}
body ul,
body li,
body ol,
body dl {
  list-style: none;
  margin: 0;
  padding: 0;
}
address {
  font-style: normal;
}
html {
  font-size: 16px;
  font-family: 'Seaford', sans-serif !important;
}
body {
  min-width: 320px;
}
body.fogging:before {
  content: '';
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.05);
  z-index: 4;
  pointer-events: none;
}
#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  overflow: hidden;
}
a {
  text-decoration: none;
  color: inherit;
}
a:hover {
  color: inherit;
}
.overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.full-width {
  width: 100% !important;
}
.cursor-pointer {
  cursor: pointer;
}
.d-flex {
  display: flex !important;
}
.no-margin {
  margin: 0 !important;
}
.no-padding {
  padding: 0 !important;
}
.page-wrapper {
  width: 100%;
  flex-shrink: 1;
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  overflow: hidden;
}
.page-wrapper > .ant-row {
  width: 100%;
  flex-grow: 1;
}
.page-wrapper.ant-row {
  flex-wrap: initial;
}
.scroll-box {
  flex-shrink: 1;
  flex-grow: 1;
  flex-basis: 0;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
.page-inner {
  padding: 20px;
  position: relative;
}
@media (min-width: 1200px) {
  .page-inner {
    padding: 40px;
  }
}
@media (max-width: 991px) {
  .page-inner.pt-0 {
    padding-top: 0;
  }
}
.page-holder {
  width: 100%;
  margin: 0 auto;
  max-width: 1180px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.page-box {
  padding: 20px;
}
.filters-actions {
  margin: 0 -5px 10px;
  padding-top: 20px;
}
@media (min-width: 992px) {
  .filters-actions {
    padding: 0;
  }
}
.filters-actions > div {
  padding: 5px;
}
.filters-actions__search.ant-input-affix-wrapper,
.filters-actions__search .ant-input {
  padding: 2px 0 2px 10px;
  border-color: rgba(147, 149, 151, 0.3);
  background-color: #f6f6f6;
}
.filters-actions__search.ant-input-affix-wrapper:hover,
.filters-actions__search .ant-input:hover,
.filters-actions__search.ant-input-affix-wrapper:focus,
.filters-actions__search .ant-input:focus {
  background-color: #f6f6f6;
  border-color: #939597;
  box-shadow: none;
}
.filters-actions__search.ant-input-affix-wrapper.ant-input-affix-wrapper-focused,
.filters-actions__search .ant-input.ant-input-affix-wrapper-focused {
  border-color: #939597;
  box-shadow: none;
}
.filters-actions__search.ant-input-affix-wrapper.ant-input-affix-wrapper-focused .ant-input-prefix,
.filters-actions__search .ant-input.ant-input-affix-wrapper-focused .ant-input-prefix {
  display: none;
}
.filters-actions__search .ant-input-prefix {
  font-size: 14px;
  color: #939597;
  margin: 0;
}
.filters-actions__search .ant-input-suffix {
  cursor: pointer;
}
.filters-actions__search .ant-input-suffix:hover > span:before {
  color: #939597;
}
.filters-actions__search .ant-input-suffix > span:before {
  content: '\e90e';
  font-family: 'icon-font';
  display: block;
  font-size: 20px;
  color: #242424;
}
.filters-actions__search .ant-input-suffix > span svg {
  display: none;
}
.filters-actions__search .ant-input {
  background-color: transparent;
  font-size: 12px;
  color: #939597;
}
.filters-actions__search .ant-input::placeholder {
  color: #939597;
  opacity: 1;
}
.filters-actions__search_dark.ant-input-affix-wrapper,
.filters-actions__search_dark .ant-input {
  padding: 2px 0 2px 10px;
  border-color: rgba(147, 149, 151, 0.3);
}
.filters-actions__search_dark.ant-input-affix-wrapper:hover,
.filters-actions__search_dark .ant-input:hover,
.filters-actions__search_dark.ant-input-affix-wrapper:focus,
.filters-actions__search_dark .ant-input:focus {
  border-color: #939597;
  box-shadow: none;
}
.filters-actions__search_dark.ant-input-affix-wrapper.ant-input-affix-wrapper-focused,
.filters-actions__search_dark .ant-input.ant-input-affix-wrapper-focused {
  border-color: #939597;
  box-shadow: none;
}
.filters-actions__search_dark.ant-input-affix-wrapper.ant-input-affix-wrapper-focused .ant-input-prefix,
.filters-actions__search_dark .ant-input.ant-input-affix-wrapper-focused .ant-input-prefix {
  display: none;
}
.filters-actions__search_dark .ant-input-prefix {
  font-size: 14px;
  color: #939597;
  margin: 0;
}
.filters-actions__search_dark .ant-input-suffix {
  cursor: pointer;
}
.filters-actions__search_dark .ant-input-suffix:hover > span:before {
  color: #939597;
}
.filters-actions__search_dark .ant-input-suffix > span:before {
  content: '\e90e';
  font-family: 'icon-font';
  display: block;
  font-size: 20px;
  color: #242424;
}
.filters-actions__search_dark .ant-input-suffix > span svg {
  display: none;
}
.filters-actions__search_dark .ant-input {
  background-color: transparent;
  font-size: 12px;
  color: inherit;
}
.filters-actions__search_dark .ant-input::placeholder {
  opacity: 1;
  color: inherit;
}
.filters-actions__drop {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0 -5px;
}
.filters-actions__drop > div {
  padding: 0 5px;
}
.filters-actions__wrap {
  width: 100%;
}
.ant-app {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  overflow: hidden;
}
.ant-layout-content {
  display: flex;
  flex-direction: column;
}
.bg-transparent {
  background-color: transparent;
}
.h1 {
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
}
@media (min-width: 992px) {
  .h1 {
    font-size: 36px;
    line-height: 40px;
  }
}
.h2 {
  font-size: 24px;
  line-height: 24px;
  font-weight: 700;
}
.page-title {
  margin-bottom: 22px;
}
.noData {
  padding-top: 40px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  text-align: center;
}
@media (min-width: 768px) {
  .noData {
    padding-top: 114px;
  }
}
.noData .image {
  width: 140px;
  margin: 0 auto 60px;
}
.noData .title {
  font-size: 24px;
  line-height: 20px;
  margin-bottom: 10px;
}
.noData .text {
  max-width: 420px;
  margin: 0 auto;
  color: #939597;
}
.noData .text span,
.noData .text a {
  text-decoration: underline;
}
.ElementsApp input {
  color: #ffffff;
}
.multiple-select .ant-select-multiple {
  width: 100%;
  font-size: 16px;
}
.multiple-select .ant-select-multiple .ant-select-selector {
  padding: 5px 10px;
}
.multiple-select .ant-select-multiple .ant-select-selection-search {
  margin: 0;
  color: #939597;
}
.multiple-select .ant-select .ant-select-selection-placeholder {
  color: #939597;
}
.multiple-select .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: rgba(147, 149, 151, 0.3);
  background-color: #f6f6f6;
}
.multiple-select .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
  border-color: #939597;
}
.multiple-select .ant-select-focused.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
  border-color: #939597;
}
.multiple-select.dark-multiple .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: rgba(147, 149, 151, 0.3);
  background-color: transparent;
}
.multiple-select.dark-multiple .ant-select .ant-select-selection-placeholder {
  color: #ffffff;
}
@font-face {
  font-family: 'Montserrat';
  src: url('../../assets/fonts/montserrat/Montserrat-Light.eot');
  src: url('../../assets/fonts/montserrat/Montserrat-Light.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/montserrat/Montserrat-Light.woff') format('woff'), url('../../assets/fonts/montserrat/Montserrat-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Montserrat';
  src: url('../../assets/fonts/montserrat/Montserrat-Regular.eot');
  src: url('../../assets/fonts/montserrat/Montserrat-Regular.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/montserrat/Montserrat-Regular.woff') format('woff'), url('../../assets/fonts/montserrat/Montserrat-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Montserrat';
  src: url('../../assets/fonts/montserrat/Montserrat-Medium.eot');
  src: url('../../assets/fonts/montserrat/Montserrat-Medium.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/montserrat/Montserrat-Medium.woff') format('woff'), url('../../assets/fonts/montserrat/Montserrat-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Montserrat';
  src: url('../../assets/fonts/montserrat/Montserrat-Bold.eot');
  src: url('../../assets/fonts/montserrat/Montserrat-Bold.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/montserrat/Montserrat-Bold.woff') format('woff'), url('../../assets/fonts/montserrat/Montserrat-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Allerta Stencil';
  src: url('../../assets/fonts/AllertaStencil/AllertaStencil.eot');
  src: url('../../assets/fonts/AllertaStencil/AllertaStencil.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/AllertaStencil/AllertaStencil.woff') format('woff'), url('../../assets/fonts/AllertaStencil/AllertaStencil.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Seaford';
  src: url('../../assets/fonts/seaford/SeafordRg.ttf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Seaford';
  src: url('../../assets/fonts/seaford/SeafordBold.ttf');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'icon-font';
  src: url('../../assets/fonts/icon-font/icon-font.ttf?974ist') format('truetype'), url('../../assets/fonts/icon-font/icon-font.woff?974ist') format('woff'), url('../../assets/fonts/icon-font/icon-font.svg?974ist#icon-font') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icon-font' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-burger:before {
  content: "\e977";
}
.icon-transaction:before {
  content: "\e973";
}
.icon-more-square:before {
  content: "\e974";
}
.icon-history:before {
  content: "\e975";
}
.icon-users:before {
  content: "\e976";
}
.icon-arrow-circle-right:before {
  content: "\e971";
}
.icon-arrow-circle-left:before {
  content: "\e972";
}
.icon-stage:before {
  content: "\e96f";
}
.icon-tracker:before {
  content: "\e970";
}
.icon-graph:before {
  content: "\e96b";
}
.icon-work:before {
  content: "\e96e";
}
.icon-load:before {
  content: "\e96d";
}
.icon-bell:before {
  content: "\e96c";
}
.icon-headphones:before {
  content: '\e968';
}
.icon-newspaper:before {
  content: '\e969';
}
.icon-server-network:before {
  content: '\e96a';
}
.icon-eye:before {
  content: '\e966';
}
.icon-not-available-eye:before {
  content: '\e967';
}
.icon-meeting-board:before {
  content: '\e965';
}
.icon-download-file:before {
  content: '\e964';
}
.icon-pencil:before {
  content: '\e963';
}
.icon-twitter:before {
  content: '\e95e';
}
.icon-youtube:before {
  content: '\e95f';
}
.icon-facebook:before {
  content: '\e960';
}
.icon-instagram:before {
  content: '\e961';
}
.icon-linkedin:before {
  content: '\e962';
}
.icon-wrench:before {
  content: '\e958';
}
.icon-x-add:before {
  content: '\e959';
}
.icon-sitemap:before {
  content: '\e95a';
}
.icon-file-contract-dollar:before {
  content: '\e95b';
}
.icon-cloud-block:before {
  content: '\e95c';
}
.icon-chart-down:before {
  content: '\e95d';
}
.icon-caret-down-solid:before {
  content: '\e957';
}
.icon-integrations:before {
  content: '\e954';
}
.icon-server:before {
  content: '\e955';
}
.icon-account-settings:before {
  content: '\e956';
}
.icon-exit:before {
  content: '\e94f';
}
.icon-deleted-folders:before {
  content: '\e950';
}
.icon-archived:before {
  content: '\e951';
}
.icon-bill:before {
  content: '\e952';
}
.icon-user:before {
  content: '\e953';
}
.icon-resend:before {
  content: '\e94e';
}
.icon-question-circle:before {
  content: '\e94d';
}
.icon-camera:before {
  content: '\e94c';
}
.icon-export:before {
  content: '\e94a';
}
.icon-import:before {
  content: '\e94b';
}
.icon-triangle:before {
  content: '\e949';
}
.icon-close-rounded:before {
  content: '\e948';
}
.icon-clip:before {
  content: '\e947';
}
.icon-whatsapp:before {
  content: '\e944';
}
.icon-sms:before {
  content: '\e945';
}
.icon-email:before {
  content: '\e946';
}
.icon-angle-right-light:before {
  content: '\e943';
}
.icon-trash-can:before {
  content: '\e941';
}
.icon-unarchived:before {
  content: '\e942';
}
.icon-arrows-with-plus:before {
  content: '\e93e';
}
.icon-square-plus:before {
  content: '\e93f';
}
.icon-arrows:before {
  content: '\e940';
}
.icon-sing-up:before {
  content: '\e93b';
}
.icon-blog:before {
  content: '\e93c';
}
.icon-login:before {
  content: '\e93d';
}
.icon-american-express:before {
  content: '\e937';
}
.icon-maestro:before {
  content: '\e938';
}
.icon-paypal:before {
  content: '\e939';
}
.icon-visa:before {
  content: '\e93a';
}
.icon-arrow-down:before {
  content: '\e935';
}
.icon-arrow-up:before {
  content: '\e936';
}
.icon-calling:before {
  content: '\e934';
}
.icon-send:before {
  content: '\e907';
}
.icon-delete:before {
  content: '\e933';
}
.icon-check-single:before {
  content: '\e928';
}
.icon-lock:before {
  content: '\e932';
}
.icon-password:before {
  content: '\e92c';
}
.icon-Profile:before {
  content: '\e92d';
}
.icon-wallet:before {
  content: '\e92f';
}
.icon-wallet-outline:before {
  content: '\e931';
}
.icon-move:before {
  content: '\e930';
}
.icon-dots:before {
  content: '\e92e';
}
.icon-check-filled:before {
  content: '\e92b';
}
.icon-paper-minus:before {
  content: '\e929';
}
.icon-paper-plus:before {
  content: '\e92a';
}
.icon-hide:before {
  content: '\e926';
}
.icon-notification:before {
  content: '\e927';
}
.icon-call:before {
  content: '\e925';
}
.icon-angle-top:before {
  content: '\e924';
}
.icon-check:before {
  content: '\e922';
}
.icon-danger:before {
  content: '\e923';
}
.icon-notify:before {
  content: '\e921';
}
.icon-undo:before {
  content: '\e920';
}
.icon-info-circle:before {
  content: '\e91f';
}
.icon-template:before {
  content: '\e91e';
}
.icon-info:before {
  content: '\e91a';
}
.icon-arrow-left:before {
  content: '\e919';
}
.icon-message:before {
  content: '\e91b';
}
.icon-pin:before {
  content: '\e91c';
}
.icon-smartphone:before {
  content: '\e91d';
}
.icon-schedule:before {
  content: '\e916';
}
.icon-lightbulb:before {
  content: '\e917';
}
.icon-question:before {
  content: '\e918';
}
.icon-calendar:before {
  content: '\e905';
}
.icon-home:before {
  content: '\e915';
}
.icon-checkbox:before {
  content: '\e914';
}
.icon-archive:before {
  content: '\e910';
}
.icon-angle-down:before {
  content: '\e911';
}
.icon-arrow-right:before {
  content: '\e912';
}
.icon-profile:before {
  content: '\e913';
}
.icon-star:before {
  content: '\e90f';
}
.icon-close-square:before {
  content: '\e90e';
}
.icon-share-network:before {
  content: '\e90d';
}
.icon-share:before {
  content: '\e90c';
}
.icon-angle-bottom:before {
  content: '\e900';
}
.icon-angle-left:before {
  content: '\e901';
}
.icon-angle-right:before {
  content: '\e902';
}
.icon-close:before {
  content: '\e903';
}
.icon-plus:before {
  content: '\e904';
}
.icon-copy:before {
  content: '\e906';
}
.icon-edit:before {
  content: '\e908';
}
.icon-programming:before {
  content: '\e909';
}
.icon-search:before {
  content: '\e90a';
}
.icon-url:before {
  content: '\e90b';
}
*::-webkit-scrollbar {
  width: 4px;
}
*::-webkit-scrollbar-track {
  background: transparent;
}
*::-webkit-scrollbar-thumb {
  background: #939597;
  border-radius: 4px;
}
*::-webkit-scrollbar-thumb:hover {
  background: #939597;
}
body .ant-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
body .ant-btn:not(.ant-btn-link) {
  background-color: #f0f0f0;
  border-color: #f0f0f0;
  color: inherit;
  font-size: 10px;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
}
body .ant-btn:not(.ant-btn-link):not(.ant-btn-circle) {
  min-width: 68px;
}
body .ant-btn:not(.ant-btn-link).ant-btn-link {
  box-shadow: none;
}
body .ant-btn:not(.ant-btn-link).ant-btn-text {
  font-weight: 600;
  box-shadow: none;
  background-color: transparent;
  border: 0;
}
body .ant-btn:not(.ant-btn-link).ant-btn-text:hover {
  box-shadow: none;
  color: inherit;
}
body .ant-btn:not(.ant-btn-link).ant-btn-text > i {
  font-size: 20px;
}
body .ant-btn:not(.ant-btn-link):hover {
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.3);
  opacity: 0.9;
  color: inherit;
  border-color: #f0f0f0;
}
body .ant-btn:not(.ant-btn-link).ant-btn-lg {
  font-size: 14px;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
}
body .ant-btn:not(.ant-btn-link).ant-btn-lg:not(.ant-btn-circle) {
  height: 50px;
}
body .ant-btn:not(.ant-btn-link).ant-btn-lg:not(.ant-btn-circle):not(.ant-btn-block) {
  min-width: 160px;
}
body .ant-btn:not(.ant-btn-link).ant-btn-lg:not(.ant-btn-circle):not(.ant-btn-block).icon-btn {
  width: 50px;
  min-width: 0;
}
body .ant-btn:not(.ant-btn-link).ant-btn-lg:hover {
  opacity: 0.9;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.3);
}
body .ant-btn:not(.ant-btn-link).ant-btn-primary {
  background-color: #f5df4d;
  border-color: #f5df4d;
  color: initial;
}
body .ant-btn:not(.ant-btn-link).ant-btn-primary:hover {
  background-color: #f5df4d;
  border-color: #f5df4d;
  color: #242424;
}
body .ant-btn:not(.ant-btn-link).dark-btn {
  background-color: #242424;
  border-color: #242424;
  color: #ffffff;
}
body .ant-btn:not(.ant-btn-link).disabled-btn {
  background-color: #242424;
  border-color: #242424;
  color: #ffffff;
  opacity: 0.7;
}
body .ant-btn:not(.ant-btn-link).disabled-btn:hover {
  background-color: #242424;
  border-color: #242424;
  color: #ffffff;
  opacity: 0.7;
}
body .ant-btn:not(.ant-btn-link).disabled-btn-light {
  border-color: #939597;
  background-color: #939597;
  color: #ffffff;
  opacity: 0.7;
}
body .ant-btn:not(.ant-btn-link).disabled-btn-light:hover {
  border-color: #939597;
  background-color: #939597;
  color: #ffffff;
  opacity: 0.7;
}
body .ant-btn:not(.ant-btn-link).yellow-btn {
  background-color: #f5df4d;
  border-color: #f5df4d;
}
body .ant-btn:not(.ant-btn-link).dark-btn-outline {
  border-color: #939597;
  background-color: transparent;
  box-shadow: none;
  font-weight: 600;
}
body .ant-btn:not(.ant-btn-link).ant-btn-ghost {
  background-color: transparent;
  border: 1px solid rgba(147, 149, 151, 0.3);
  border-radius: 4px;
  box-shadow: none;
  height: 40px;
  min-width: 40px;
  padding: 6px;
}
body .ant-btn:not(.ant-btn-link).ant-btn-ghost:hover {
  border-color: #939597;
}
body .ant-btn:not(.ant-btn-link).ant-btn-ghost i {
  font-size: 20px;
}
body .ant-btn:not(.ant-btn-link).with-icon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: none;
  background-color: transparent;
  border: none;
  text-transform: uppercase;
  font-weight: 500;
}
body .ant-btn:not(.ant-btn-link).with-icon .icon {
  flex-shrink: 0;
  flex-grow: 0;
  color: inherit;
}
body .ant-btn:not(.ant-btn-link).with-icon .icon:first-child {
  margin-right: 10px;
}
body .ant-btn:not(.ant-btn-link).with-icon .icon:last-child {
  margin-left: 10px;
}
body .ant-btn:not(.ant-btn-link).with-icon:hover {
  box-shadow: none;
  color: #fec021;
}
body .ant-btn:not(.ant-btn-link).timer-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0;
  font-size: 14px;
  color: #fcd228;
  box-shadow: none;
  background-color: transparent;
  border: none;
}
body .ant-btn:not(.ant-btn-link).timer-btn:disabled {
  color: rgba(0, 0, 0, 0.25);
}
body .ant-btn:not(.ant-btn-link).timer-btn .ant-statistic-content {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.25);
}
body .ant-btn:not(.ant-btn-link).timer-btn .ant-statistic-content-prefix,
body .ant-btn:not(.ant-btn-link).timer-btn .ant-statistic-content-suffix {
  margin: 0;
}
body .ant-btn:not(.ant-btn-link).start-btn {
  color: #ffffff;
  background-color: #4EBB3C;
  border-color: #4EBB3C;
}
body .ant-btn:not(.ant-btn-link).end-btn {
  color: #ffffff;
  background-color: #FF6868;
  border-color: #FF6868;
}
body .ant-input-affix-wrapper {
  padding: 0;
}
body .ant-input-affix-wrapper .ant-input.ant-input-lg {
  padding: 5px 36px 5px 11px;
}
body .ant-input-affix-wrapper .ant-input-suffix {
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  right: 11px;
}
body .ant-input {
  border-color: #939597;
}
body .ant-input:hover {
  border-color: #939597;
}
body .ant-input::placeholder {
  color: #c4c5c6;
}
body .ant-form-item {
  margin-bottom: 20px;
}
body .ant-form-item .ant-form-item-label > label {
  color: inherit;
}
body .ant-form-item-explain,
body .ant-form-item-extra {
  line-height: 20px;
}
body .ant-form-item-explain-success {
  color: #00aa63;
}
body .pseudo-label {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  margin-bottom: 10px;
}
body .light-input-group .ant-input,
body .light-input-group .ant-input-affix-wrapper {
  border-color: #ffffff;
  color: #ffffff;
  background-color: #242424;
}
body .light-input-group .ant-input:hover,
body .light-input-group .ant-input-affix-wrapper:hover,
body .light-input-group .ant-input:focus,
body .light-input-group .ant-input-affix-wrapper:focus {
  background-color: #242424;
  border-color: #ffffff;
  box-shadow: none;
}
body .light-input-group .ant-input:-webkit-autofill,
body .light-input-group .ant-input-affix-wrapper:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px #242424 inset !important;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  color: #ffffff;
}
body .light-input-group .ant-input:-webkit-autofill::first-line,
body .light-input-group .ant-input-affix-wrapper:-webkit-autofill::first-line {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  color: #ffffff;
}
body .light-input-group .ant-input:-webkit-autofill:hover,
body .light-input-group .ant-input-affix-wrapper:-webkit-autofill:hover,
body .light-input-group .ant-input:-webkit-autofill:focus,
body .light-input-group .ant-input-affix-wrapper:-webkit-autofill:focus,
body .light-input-group .ant-input:-webkit-autofill:active,
body .light-input-group .ant-input-affix-wrapper:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 50px #242424 inset !important;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  color: #ffffff;
}
body .light-input-group.ant-form-item-has-error .ant-input,
body .light-input-group.ant-form-item-has-error .ant-input-affix-wrapper {
  border-color: #b91600;
}
body .light-input-group .ant-input-password-icon {
  color: #ffffff;
}
body .light-input-group .ant-form-item-label > label {
  color: #ffffff;
}
body .dark-input-group .ant-input,
body .dark-input-group .ant-input-affix-wrapper {
  border-color: #f0f0f0;
  background-color: transparent;
}
body .dark-input-group .ant-input:hover,
body .dark-input-group .ant-input-affix-wrapper:hover,
body .dark-input-group .ant-input:focus,
body .dark-input-group .ant-input-affix-wrapper:focus {
  background-color: transparent;
  border-color: #939597;
  box-shadow: none;
}
body .dark-input-group.ant-form-item-has-error .ant-input,
body .dark-input-group.ant-form-item-has-error .ant-input-affix-wrapper {
  border-color: #b91600;
}
body .dark-input-group .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: rgba(219, 219, 219, 0.25);
}
body .dark-input-group .ant-select .ant-select-selection-item {
  color: rgba(36, 36, 36, 0.8);
  font-weight: 400;
}
body .ant-input:focus,
body .ant-input-focused {
  border-color: #242424;
  box-shadow: none;
}
body .ant-picker {
  width: 100%;
  border-color: #f0f0f0;
  background-color: transparent;
}
body .ant-picker .ant-picker-input > input::placeholder {
  color: #c4c5c6;
}
body .ant-picker .ant-picker-input > input:placeholder-shown {
  color: #c4c5c6;
}
body .ant-picker:hover,
body .ant-picker:focus {
  background-color: transparent;
  border-color: #939597;
  box-shadow: none;
}
body .ant-picker.ant-picker-focused {
  background-color: transparent;
  border-color: #939597;
  box-shadow: none;
}
body .ant-picker.ant-picker-focused .ant-picker-suffix:before {
  color: #242424;
}
body .ant-picker .ant-picker-suffix > span {
  display: none;
}
body .ant-picker .ant-picker-suffix:before {
  content: '\e905';
  font-family: 'icon-font';
  display: block;
  font-size: 18px;
}
body .ant-picker .ant-picker-clear > span {
  display: none;
}
body .ant-picker .ant-picker-clear:before {
  content: '\e920';
  font-family: 'icon-font';
  display: block;
  font-size: 18px;
  background-color: rgba(219, 219, 219, 0.25);
}
body .ant-picker-dropdown .ant-picker-today-btn {
  color: #f5df4d;
}
body .ant-picker-dropdown .ant-picker-today-btn:hover {
  color: #fec021;
}
body .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: transparent;
  border-color: #f0f0f0;
}
body .ant-select.ant-select-focused:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
  border-color: #e6e6e6;
  box-shadow: none;
}
body .ant-select .ant-select-arrow {
  width: 18px;
  height: 18px;
  margin-top: -10px;
}
body .ant-select .ant-select-arrow > span {
  display: none;
}
body .ant-select .ant-select-arrow:before {
  content: '\e911';
  font-family: 'icon-font';
  display: block;
  font-size: 18px;
}
body .ant-select-dropdown {
  border: 3px solid #ffffff;
  border-radius: 20px;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
  padding: 0 20px;
}
body .ant-select-dropdown .ant-select-item {
  padding: 10px 0;
  border-radius: 0;
  border-bottom: 1px solid #f0f0f0;
}
body .ant-select-dropdown .ant-select-item:last-child {
  border: 0;
}
body .ant-select-dropdown .ant-select-item.ant-select-item-option-selected {
  background-color: transparent;
  font-weight: 700;
}
body .ant-select-dropdown .ant-select-item.ant-select-item-option-active {
  background-color: transparent;
  font-weight: 700;
}
body .ant-radio-wrapper .ant-radio-inner {
  width: 20px;
  height: 20px;
  border: 2px solid #242424;
}
body .ant-radio-wrapper .ant-radio-inner::after {
  background-color: #242424;
}
body .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: #242424;
  background-color: #ffffff;
}
body .ant-radio-wrapper .ant-radio-checked .ant-radio-inner::after {
  transform: scale(0.7);
}
body .ant-radio-wrapper:hover .ant-radio-inner {
  border-color: #242424;
}
body .input-bg .ant-input,
body .input-bg .ant-input-affix-wrapper {
  background-color: #f6f6f6;
}
body .ant-divider.ant-divider-horizontal {
  margin: 20px 0;
}
body .ant-divider.ant-divider-horizontal.light-divider {
  border-color: #ffffff;
}
body .ant-divider.ant-divider-horizontal.lighten-divider {
  border-color: #f0f0f0;
}
body .ant-divider.ant-divider-horizontal.dark-divider {
  border-color: #e6e6e6;
}
body .ant-divider.ant-divider-horizontal.mt-0 {
  margin-top: 0;
}
body .ant-modal-root .modal-root {
  margin: 0 auto;
  top: 120px;
  width: 100% !important;
  max-width: 430px;
  padding: 0 10px 10px;
}
@media (max-width: 575px) {
  body .ant-modal-root .modal-root {
    top: 120px;
  }
}
body .ant-modal-root .ant-modal-content {
  box-sizing: border-box;
  border-radius: 20px;
  padding: 0;
}
body .ant-modal-root .ant-modal-header {
  padding: 20px;
  position: relative;
  border-bottom: 0;
  border-radius: 14px 14px 0 0;
  margin-bottom: 0;
}
@media (min-width: 768px) {
  body .ant-modal-root .ant-modal-header {
    background-color: transparent;
  }
}
body .ant-modal-root .ant-modal-header:before {
  content: '';
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: 0;
  height: 1px;
  background-color: #e6e6e6;
}
body .ant-modal-root .ant-modal-title {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
}
body .ant-modal-root .ant-modal-wrap {
  overflow: auto;
}
body .ant-modal-root .ant-modal-body {
  padding: 0;
  font-weight: 500;
}
body .ant-modal-root .ant-modal-footer {
  padding: 20px;
  border: 0;
  position: relative;
  margin-top: 0;
}
body .ant-modal-root .ant-modal-footer:before {
  content: '';
  position: absolute;
  left: 20px;
  right: 20px;
  top: 0;
  height: 1px;
  background-color: #e6e6e6;
}
body .ant-modal-root .modal-scroll-wrapper {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px 20px 0;
  max-height: 100%;
}
@media (min-width: 576px) {
  body .ant-modal-root .modal-scroll-wrapper {
    max-height: calc(67vh - 110px);
  }
}
body .confirm-modal .ant-modal-content {
  border: 1px solid rgba(254, 192, 33, 0.4);
  box-shadow: none;
}
body .confirm-modal .ant-modal-confirm .ant-modal-confirm-body {
  display: block;
}
body .confirm-modal .ant-modal-confirm-body-wrapper {
  padding: 20px;
}
body .confirm-modal .confirm-icon {
  width: 40px;
  height: 40px;
  background-color: #fec021;
  border-radius: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 24px;
  float: left;
  margin-right: 20px;
  margin-bottom: 15px;
}
body .confirm-modal .ant-modal-confirm-title {
  font-weight: 400 !important;
  flex: auto !important;
}
body .confirm-modal-dark-button .ant-btn-default {
  background-color: #242424;
  border-color: #242424;
  color: #ffffff;
}
body .ant-popover {
  z-index: 100;
  width: 100%;
  max-width: 430px;
  padding: 0 10px;
}
body .ant-popover .ant-popover-inner {
  border: 3px solid #ffffff;
  box-sizing: border-box;
  border-radius: 20px;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
  padding: 0;
}
body .ant-popover-content {
  margin-bottom: 15px;
}
body .ant-popover-arrow {
  display: none;
}
body .ant-popover-inner-content {
  padding: 20px 20px 10px;
}
.popover__head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid #f0f0f0;
}
.popover__title {
  color: #242424;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  padding-right: 10px;
}
.popover__date {
  font-weight: 500;
}
.popover__body {
  padding: 20px 0;
  font-weight: 500;
}
.popover__footer {
  display: flex;
  justify-content: flex-end;
  margin: 0 -5px;
  padding-top: 10px;
  border-top: 1px solid #f0f0f0;
}
.popover__footer > div {
  padding: 0 5px;
}
.card-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
}
.card-box {
  padding: 5px;
  flex-shrink: 0;
  display: flex;
  width: 100%;
}
@media (min-width: 768px) {
  .card-box {
    width: 50%;
  }
}
@media (min-width: 992px) {
  .card-box {
    width: 33.33%;
  }
}
.card {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  padding: 20px;
  cursor: pointer;
  min-height: 200px;
}
.card:hover {
  border-color: #00aa63;
}
.card__head {
  margin-bottom: 24px;
}
.card__img {
  width: 60px;
}
.card__img img {
  display: block;
}
.card__number {
  font-weight: 600;
}
.card__default {
  color: #ffffff;
  background-color: #cccccc;
  width: min-content;
  margin-top: 6px;
  padding: 0 8px 1px;
  border-radius: 14px;
}
.card__name {
  font-size: 14px;
  font-weight: 700;
  color: #242424;
  line-height: 20px;
  flex-grow: 1;
  padding-right: 15px;
  width: calc(100% - 42px);
  word-break: break-all;
}
.card__name.card__name--light {
  color: #939597;
  font-weight: 400;
  width: calc(100% - 24px);
}
.card__foot {
  margin-top: auto;
  text-align: right;
}
.card.card--btn {
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: rgba(147, 149, 151, 0.04);
  border: 1px solid rgba(147, 149, 151, 0.05);
}
.card-title {
  text-transform: uppercase;
  color: #242424;
}
@media (min-width: 576px) {
  .card-title {
    margin-bottom: 20px;
  }
}
.ticket-list {
  padding-top: 20px;
}
@media (min-width: 576px) {
  .ticket-list {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;
  }
}
.ticket-list.mt-0 {
  margin-top: 0;
}
.ticket-box {
  margin-bottom: 5px;
  padding: 5px;
  flex-shrink: 0;
  display: flex;
  width: 100%;
}
.ticket {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border: 1px solid rgba(147, 149, 151, 0.3);
  border-radius: 8px;
  padding: 10px;
  min-height: 100px;
  cursor: pointer;
  text-decoration: none;
}
@media (min-width: 992px) {
  .ticket {
    padding: 20px;
  }
}
.ticket:hover {
  border-color: #3bb55d;
  position: relative;
}
.ticket:hover:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translate(-50%, -50%);
  border-radius: 13px;
  border: 3px solid #3bb55d;
  height: 40px;
}
.ticket__left {
  display: flex;
  align-items: center;
}
.ticket__progress {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  box-shadow: 0px 0px 0px 2px #e6e6e6;
  margin-right: 20px;
}
.ticket__progress .ant-progress-circle-trail {
  stroke: #f0f0f0;
}
.ticket__progress .ant-progress-circle .ant-progress-text {
  font-size: 12px;
  font-weight: 500;
  color: #242424;
}
.ticket__name {
  font-size: 14px;
  line-height: 20px;
  word-break: break-all;
}
.ticket__item {
  font-size: 14px;
  line-height: 20px;
}
.ticket__item > span {
  color: #939597;
}
.ticket__shared {
  font-size: 14px;
  color: #191c21;
}
.ticket__btn {
  display: flex;
  align-items: center;
  margin: 0 -5px;
}
.ticket__btn > div {
  padding: 0 5px;
}
.ticket__circle {
  display: inline-block;
}
.ticket__circle .ticket-circle {
  position: relative;
  padding-left: 16px;
}
.ticket__circle .ticket-circle:before {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  left: 2px;
  top: 50%;
  transform: translate(0%, -50%);
}
.ticket.ticket--shadow {
  border-color: transparent;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
  flex-wrap: wrap;
}
.ticket.ticket--shadow:hover {
  border-color: #3bb55d;
}
.ticket.ticket--shadow .ticket__left,
.ticket.ticket--shadow .ticket__right {
  width: 100%;
}
@media (min-width: 768px) {
  .ticket.ticket--shadow .ticket__left,
  .ticket.ticket--shadow .ticket__right {
    width: 50%;
  }
}
@media (min-width: 992px) {
  .ticket.ticket--shadow .ticket__left,
  .ticket.ticket--shadow .ticket__right {
    width: 100%;
  }
}
@media (min-width: 1200px) {
  .ticket.ticket--shadow .ticket__left,
  .ticket.ticket--shadow .ticket__right {
    width: 50%;
  }
}
.ticket.ticket--shadow .ticket__right {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (min-width: 768px) {
  .ticket.ticket--shadow .ticket__inner {
    display: flex;
    align-items: center;
    margin: 0 -20px;
  }
  .ticket.ticket--shadow .ticket__inner > div {
    padding: 0 20px;
  }
}
.ticket.ticket--full {
  display: block;
  border-color: transparent;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
  flex-wrap: wrap;
  min-height: 80px;
  color: #242424;
}
.ticket.ticket--full:hover {
  border-color: #3bb55d;
}
@media (min-width: 768px) {
  .ticket.ticket--full {
    display: flex;
  }
}
@media (min-width: 768px) {
  .ticket.ticket--full .ticket__name {
    width: 260px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
@media (min-width: 576px) {
  .ticket.ticket--full .ticket__main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}
@media (min-width: 992px) {
  .ticket.ticket--full .ticket__main {
    width: calc(100% - 310px);
  }
}
@media (min-width: 768px) {
  .ticket.ticket--full .ticket__inner {
    display: flex;
    align-items: center;
    width: calc(100% - 200px);
  }
  .ticket.ticket--full .ticket__inner > div {
    width: 50%;
  }
}
.ticket.inverse {
  background-color: #1e2126;
  color: #ffffff;
}
.ticket.inverse .ticket__progress {
  box-shadow: 0px 0px 0px 2px rgba(230, 230, 230, 0.3);
}
.ticket.inverse .ticket__progress .ant-progress-circle-trail {
  stroke: rgba(240, 240, 240, 0.1);
}
.ticket.inverse .ticket__progress .ant-progress-circle .ant-progress-text {
  color: #ffffff;
}
.ticket.inverse:hover {
  border-color: #fcd228;
}
.ticket.inverse:hover:before {
  border-color: #fcd228;
}
body .ant-tooltip-arrow {
  display: none;
}
body .ant-tooltip-inner {
  min-width: 148px;
  background-color: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 20px;
  color: #242424;
  text-align: center;
  box-shadow: 4px 4px 10px rgba(166, 171, 189, 0.65);
}
body .ant-dropdown .ant-dropdown-menu {
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding: 0 20px;
  min-width: 230px;
}
body .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
  padding: 10px 0;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 0;
}
body .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:last-child {
  border-bottom: 0;
}
body .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover {
  background-color: transparent;
  font-weight: 700;
}
body .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item .icon {
  padding-right: 10px;
  font-size: 18px;
  vertical-align: text-bottom;
}
body .Toastify__toast-container {
  width: 100%;
  max-width: 526px;
  color: #939597;
  padding: 10px;
}
body .Toastify__toast-container--top-center {
  top: 100px;
}
@media (max-width: 575px) {
  body .Toastify__toast-container--top-center {
    top: 50px;
  }
}
body .Toastify__toast {
  border-radius: 20px;
  background-color: #ffffff;
  padding: 10px 20px;
  cursor: default;
  margin-bottom: 10px;
  font-family: inherit;
  border: 1px solid;
}
body .Toastify__toast-body {
  width: 100%;
  padding: 0;
}
body .Toastify__toast.Toastify__toast--error {
  color: #b91600;
  border-color: rgba(185, 22, 0, 0.4);
}
body .Toastify__toast.Toastify__toast--error .Toastify__toast-icon {
  background-color: #b91600;
}
body .Toastify__toast.Toastify__toast--success {
  color: #00aa63;
  border-color: rgba(0, 170, 99, 0.4);
}
body .Toastify__toast.Toastify__toast--success .Toastify__toast-icon {
  background-color: #00aa63;
}
body .Toastify__toast.Toastify__toast--warning,
body .Toastify__toast.Toastify__toast--info {
  color: #f5df4d;
  border-color: rgba(245, 223, 77, 0.4);
}
body .Toastify__toast.Toastify__toast--warning .Toastify__toast-icon,
body .Toastify__toast.Toastify__toast--info .Toastify__toast-icon {
  background-color: #f5df4d;
}
.details-wrap {
  max-width: 440px;
  width: calc(100% - 20px);
  margin: 0 auto;
  color: #242424;
  z-index: 4;
  position: absolute;
  top: 74px;
  right: 0;
  transition: transform 0.3s ease;
}
@media (min-width: 992px) {
  .details-wrap {
    position: static;
    padding-top: 96px;
    width: 100%;
  }
}
.details-wrap.details-hidden {
  transform: translateX(100%);
  transition: transform 0.3s ease;
}
@media (min-width: 992px) {
  .details-wrap.details-hidden {
    transform: translateX(0);
  }
}
.details-wrap.search-page {
  padding-top: 0;
  top: 102px;
}
.details-name {
  font-size: 36px;
  margin-bottom: 40px;
  font-weight: 700;
}
.details-card {
  border: 2px solid #f0f0f0;
  font-weight: 500;
  background-color: #ffffff;
  border-radius: 20px 0 0 20px;
  border-right-width: 0;
}
@media (min-width: 992px) {
  .details-card {
    border-right-width: 2px;
    border-radius: 20px;
  }
}
.details-card__head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #242424;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 20px 0 0 0;
  padding: 20px 25px;
}
@media (min-width: 992px) {
  .details-card__head {
    padding: 30px 40px;
    border-radius: 20px 20px 0 0;
  }
}
.details-card__head-icon {
  flex-shrink: 0;
  width: 98px;
  height: 98px;
  border-radius: 50%;
  border: 4px solid rgba(240, 240, 240, 0.05);
  box-shadow: 0px 0px 0px 4px #242424 inset;
  background-color: rgba(243, 243, 243, 0.2);
  color: #ffffff;
  font-size: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (min-width: 768px) {
  .details-card__head-icon {
    width: 128px;
    height: 128px;
  }
}
.details-card__info {
  width: 170px;
  flex-shrink: 0;
  padding-left: 8px;
  position: relative;
  color: #939597;
}
.details-card__info:after {
  content: '';
  position: absolute;
  top: 4px;
  bottom: 4px;
  left: 0;
  width: 1px;
  background-color: #f5df4d;
}
.details-card__caption {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 5px;
  color: #f5df4d;
}
.details-card__progress {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 98px;
  height: 98px;
  border-radius: 50%;
  box-shadow: 0px 0px 0px 4px rgba(240, 240, 240, 0.05);
  padding: 4px;
}
@media (min-width: 992px) {
  .details-card__progress {
    width: 128px;
    height: 128px;
  }
}
.details-card__progress .ant-progress-circle-trail {
  stroke: rgba(243, 243, 243, 0.2);
}
.details-card__progress .ant-progress-circle {
  width: 100%;
  height: 100%;
}
.details-card__progress .ant-progress-circle.ant-progress-status-success .ant-progress-text {
  color: #ffffff;
}
.details-card__progress .ant-progress-circle .ant-progress-text {
  font-weight: 700;
  color: #ffffff;
  font-size: 14px;
}
@media (min-width: 992px) {
  .details-card__progress .ant-progress-circle .ant-progress-text {
    font-size: 24px;
  }
}
.details-card__progress .ant-progress-inner {
  width: 100% !important;
  height: 100% !important;
}
.details-card__body {
  overflow: hidden;
}
.details-card__title {
  flex-grow: 1;
  padding: 0 10px;
  color: #000000;
  font-weight: 500;
}
.details-card__row {
  display: flex;
  align-items: center;
}
@media (min-width: 1200px) {
  .details-card__row:hover .details-card__copy {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s ease;
  }
}
.details-card__icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 14px;
  background-color: #242424;
  flex-shrink: 0;
}
.details-card__content {
  word-break: break-word;
  color: #242424;
}
.details-card__copy {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  cursor: pointer;
  flex-shrink: 0;
  color: #939597;
}
.details-card__copy:hover {
  color: #242424;
}
@media (min-width: 1200px) {
  .details-card__copy {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease;
  }
}
.details-card__collapse .ant-collapse {
  border: 0;
  background-color: transparent;
}
.details-card__collapse .ant-collapse .ant-collapse-content {
  border-color: #f0f0f0;
  background-color: transparent;
}
.details-card__collapse .ant-collapse .ant-collapse-item {
  border-color: #f0f0f0;
}
.details-card__collapse .ant-collapse .ant-collapse-item:last-child {
  border-bottom: 0;
}
.details-card__collapse .ant-collapse .ant-collapse-item.ant-collapse-item-active .ant-collapse-header .ant-collapse-arrow {
  color: #242424;
}
.details-card__collapse .ant-collapse .ant-collapse-item .ant-collapse-header {
  padding: 6px 50px 6px 20px;
}
.details-card__collapse .ant-collapse .ant-collapse-item .ant-collapse-header .ant-collapse-arrow {
  padding: 0;
  font-size: 18px;
  top: 50%;
  transform: translateY(-50%);
  color: #939597;
}
.details-card__collapse .ant-collapse .ant-collapse-item .ant-collapse-header .ant-collapse-arrow:hover {
  color: #242424;
}
.progress-fadein {
  animation: shockwaveJump 1s ease-out;
}
.progress-fadein:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  animation: shockwave 1s 0.65s ease-out;
}
.progress-fadein:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  animation: shockwave 1s 0.5s ease-out;
}
@keyframes shockwaveJump {
  0% {
    transform: scale(1);
  }
  40% {
    transform: scale(1.18);
  }
  50% {
    transform: scale(0.98);
  }
  55% {
    transform: scale(1.02);
  }
  60% {
    transform: scale(0.98);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes shockwave {
  0% {
    transform: scale(1);
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.25), inset 0 0 1px rgba(0, 0, 0, 0.25);
  }
  95% {
    box-shadow: 0 0 50px rgba(0, 0, 0, 0), inset 0 0 30px rgba(0, 0, 0, 0);
  }
  100% {
    transform: scale(2.25);
  }
}
.progress-fadeout {
  animation: 0.8s wiggle ease !important;
}
@keyframes wiggle {
  0% {
    opacity: 1;
    transform: rotate(-3deg);
  }
  20% {
    transform: rotate(20deg);
  }
  40% {
    transform: rotate(-15deg);
  }
  60% {
    transform: rotate(5deg);
  }
  90% {
    transform: rotate(-1deg);
  }
  100% {
    opacity: 0.3;
    transform: rotate(0);
  }
}
.banner {
  display: block;
  padding: 10px;
  margin: 0 4px 20px;
  border-radius: 8px;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
}
@media (min-width: 768px) {
  .banner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    gap: 40px;
  }
}
.banner.settings {
  text-align: center;
  justify-content: center;
}
@media (min-width: 768px) {
  .banner.settings {
    min-height: 100px;
  }
}
@media (max-width: 767px) {
  .banner > div:first-child:not(:only-child) {
    padding-bottom: 10px;
  }
}
.banner__left {
  display: flex;
  align-items: center;
  flex-grow: 1;
}
.banner__right {
  display: block;
  padding-top: 10px;
}
@media (min-width: 576px) {
  .banner__right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding-top: 0;
  }
}
@media (min-width: 992px) {
  .banner__right {
    gap: 20px;
  }
}
.banner__box {
  display: block;
}
@media (min-width: 576px) {
  .banner__box {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}
@media (min-width: 992px) {
  .banner__box {
    gap: 20px;
  }
}
.banner__actions {
  display: block;
}
.banner__actions > div {
  margin-bottom: 10px;
}
.banner__actions > div:last-child {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .banner__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: -5px;
  }
  .banner__actions > div {
    padding: 5px;
    margin-bottom: 0;
  }
}
.banner__actions .ant-btn {
  width: 100%;
}
@media (min-width: 768px) {
  .banner__actions .ant-btn {
    width: auto;
  }
}
.banner__actions .ant-btn:is(.banner-btn) {
  margin-left: auto;
  width: auto;
}
.banner__name {
  font-size: 16px;
  line-height: 18px;
  word-break: break-all;
}
@media (min-width: 768px) {
  .banner__name {
    font-size: 24px;
    line-height: 20px;
  }
}
.banner__name.mb-8 {
  margin-bottom: 8px;
}
.banner__count {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  min-width: 40px;
  padding: 10px;
  border: 1px solid rgba(147, 149, 151, 0.3);
  border-radius: 4px;
  flex-shrink: 0;
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  margin-right: 10px;
}
@media (min-width: 768px) {
  .banner__count {
    height: 60px;
    min-width: 60px;
    font-size: 24px;
    line-height: 20px;
    margin-right: 20px;
  }
}
.banner__item {
  font-size: 14px;
  line-height: 18px;
  white-space: nowrap;
}
.banner__item > span {
  color: #242424;
  padding-left: 4px;
}
.banner__progress {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  box-shadow: 0px 0px 0px 2px #e6e6e6;
  margin-right: 20px;
}
.banner__progress .ant-progress-circle-trail {
  stroke: #f0f0f0;
}
.banner__progress .ant-progress-circle.ant-progress-status-success .ant-progress-text {
  color: #242424;
}
.banner__progress .ant-progress-circle .ant-progress-text {
  font-size: 12px;
  font-weight: 500;
  color: #242424;
}
.banner__btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0 -5px 10px;
}
@media (min-width: 576px) {
  .banner__btn {
    justify-content: center;
    margin: 0 -5px;
  }
}
@media (min-width: 992px) {
  .banner__btn {
    margin: 0 -10px;
  }
}
.banner__btn > div {
  padding: 0 5px;
}
@media (min-width: 992px) {
  .banner__btn > div {
    padding: 0 10px;
  }
}
.banner__profile {
  display: flex;
  gap: 20px;
}
.banner__image {
  width: 58px;
  height: 58px;
  border: 1px solid rgba(147, 149, 151, 0.3);
  border-radius: 3px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  flex-shrink: 0;
}
.banner__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.banner__content .name {
  font-weight: 700;
  font-size: 24px;
  line-height: 20px;
}
.banner__content .item {
  line-height: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 224px;
}
@media (min-width: 480px) {
  .banner__content .item {
    width: auto;
  }
}
.banner__content .item > span {
  color: #939597;
  padding-right: 4px;
}
.banner__content .item a {
  color: #191c21;
}
.banner__description {
  font-size: 14px;
  line-height: 18px;
}
.banner.mb-10 {
  margin-bottom: 10px;
}
.banner.inverse {
  border: 1px solid rgba(147, 149, 151, 0.3);
}
.banner.inverse .banner__count {
  background-color: #1e2126;
}
.banner.inverse .banner__item > span {
  color: #939597;
}
.banner.inverse .banner__item a {
  color: #939597;
}
.banner.inverse .banner__progress {
  box-shadow: 0px 0px 0px 2px rgba(230, 230, 230, 0.3);
}
.banner.inverse .banner__progress .ant-progress-circle-trail {
  stroke: rgba(240, 240, 240, 0.1);
}
.banner.inverse .banner__progress .ant-progress-circle .ant-progress-text {
  color: #ffffff;
}
.banner.inverse .banner__image {
  background: #1e2126;
}
.banner.inverse .banner__content .item a {
  color: #ffffff;
}
.category-circle {
  position: relative;
  padding-left: 15px;
}
.category-circle:before {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  left: 0;
  top: 50%;
  transform: translate(0%, -50%);
}
.cards-wrapper {
  position: relative;
  display: flex;
  gap: 10px;
}
.cards-wrapper.cards-wrapper--center {
  align-items: center;
}
.cards-list {
  position: relative;
}
.cards-list > div:last-child {
  margin-bottom: 24px;
}
.cards-list > div:last-child div[class*='filled']:before {
  background: linear-gradient(180deg, #00aa63 0%, rgba(0, 170, 99, 0) 100%);
}
.cards-list > div:nth-child(20) div[class*='numeration']::before {
  display: none;
}
.cards-list > div[class*='cards-wrapper']:first-child > div[class*='cards'] {
  border: 1px solid #f0f0f0;
  border-radius: 8px 8px 0 0;
}
.cards-list > div[class*='cards-wrapper']:last-child > div[class*='cards'] {
  border: 1px solid #f0f0f0;
  border-radius: 0 0 8px 8px;
}
.cards-list > div[class*='cards-wrapper']:only-child > div[class*='cards'] {
  border: 1px solid #f0f0f0;
  border-radius: 8px;
}
.cards-list > div[class*='cards-wrapper'] > div[class*='cards'] {
  border: 1px solid #f0f0f0;
  border-top: 0;
}
.cards-list > div[class*='cards-wrapper'] > div[class*='cards-ended'] {
  border: 1px solid #FF6868;
}
.cards-list > div[class*='cards-wrapper--center']:last-child div[class*='numeration-wrapper--center'] div[class*='numeration--center']::before {
  display: none;
}
.ended-label {
  border-radius: 0px 16px 16px 0px;
  background-color: #FF6868;
  padding: 0 10px;
  width: 60px;
  height: 20px;
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
  position: absolute;
  top: 0;
  left: 0;
}
.cards {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  position: relative;
  cursor: pointer;
}
.cards:hover:before {
  content: '';
  position: absolute;
  top: 40px;
  left: 0%;
  transform: translate(-50%, -50%);
  border-radius: 13px;
  border: 3px solid #3bb55d;
  height: 40px;
}
.cards__label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  padding: 1px 7px;
  text-transform: uppercase;
  background-color: #fec021;
  color: #ffffff;
  border-radius: 8px 0px;
  font-weight: 600;
  font-size: 10px;
}
.cards__name {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}
.cards__item {
  font-size: 14px;
  line-height: 14px;
}
.cards__item > span {
  color: #939597;
  padding-left: 4px;
}
.cards__description {
  word-break: break-word;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #939597;
}
.cards__box {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 14px 0;
}
@media (min-width: 480px) {
  .cards__box {
    flex-direction: row;
    gap: 0;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0 0 0;
  }
}
@media (min-width: 768px) {
  .cards__box {
    padding: 0;
    justify-content: flex-end;
    margin: 0 -20px 10px;
  }
  .cards__box > div {
    padding: 0 20px;
  }
}
.cards__box .ant-btn {
  width: 100%;
}
@media (min-width: 480px) {
  .cards__box .ant-btn {
    width: auto;
  }
}
.cards__btn {
  display: flex;
  align-self: start;
  justify-content: flex-end;
  margin: 0 -5px;
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .cards__btn {
    margin-bottom: 0;
  }
}
.cards__btn > div {
  padding: 0 5px;
}
.cards__file {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #5a7ef2;
  display: flex;
  align-items: center;
}
.cards__file > span {
  padding-left: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 180px;
}
@media (min-width: 1600px) {
  .cards__file > span {
    max-width: 300px;
  }
}
.cards__file:hover {
  color: #399cff;
}
.cards.cards--center:hover:before {
  top: 50%;
}
.cards.cards-ended:hover:before {
  border-color: #FF6868;
}
.numeration {
  cursor: default;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid transparent;
  flex-shrink: 0;
  font-size: 12px;
  line-height: 12px;
  font-weight: 700;
  color: #ffffff;
}
.numeration:before {
  content: '';
  display: block;
  width: 4px;
  height: 100%;
  position: absolute;
  top: 55px;
  z-index: 0;
}
.numeration.numeration--center:before {
  top: calc(50% + 15px);
  height: calc(100% - 15px);
}
.numeration.empty {
  background-color: #939597;
  box-shadow: 0px 0px 0px 3px #ffffff inset;
}
.numeration.empty:before {
  background: linear-gradient(180deg, #f0f0f0 0%, rgba(240, 240, 240, 0) 100%);
}
.numeration.stage {
  background-color: #939597;
  box-shadow: 0px 0px 0px 3px #ffffff inset;
}
.numeration.stage:before {
  background-color: #939597;
}
.numeration.filled {
  background-color: #00aa63;
  box-shadow: 0px 0px 0px 3px #ffffff inset;
}
.numeration.filled:before {
  background-color: #00aa63;
}
.numeration.ended {
  background-color: #FEC021;
  box-shadow: 0px 0px 0px 3px #ffffff inset;
}
.numeration.ended:before {
  background-color: #FEC021;
}
.numeration.dashed-first:before {
  height: 50%;
  border-right: 4px dashed #939597;
  background: transparent;
}
.numeration.dashed-last::after {
  content: '';
  display: block;
  position: absolute;
  height: 50%;
  top: -15px;
  border-right: 4px dashed #939597;
  background: transparent;
}
.numeration.short-first:before {
  height: 50%;
}
.numeration.short-last::after {
  content: '';
  display: block;
  position: absolute;
  height: 50%;
  top: -15px;
  width: 4px;
  background-color: #939597;
}
.numeration.dashed:after,
.numeration.dashed:before {
  border-right-color: #00aa63;
}
.numeration.inverse.filled {
  box-shadow: 0px 0px 0px 3px #191c21 inset;
}
.numeration.inverse.empty {
  box-shadow: 0px 0px 0px 3px #191c21 inset;
}
.numeration.inverse.stage {
  box-shadow: 0px 0px 0px 3px #191c21 inset;
}
.numeration.inverse.ended {
  box-shadow: 0px 0px 0px 3px #191c21 inset;
}
.numeration-wrapper {
  margin: 25px 0 0 0;
}
@media (min-width: 1200px) {
  .numeration-wrapper {
    margin: 25px 0 0 -40px;
  }
}
.numeration-wrapper.numeration-wrapper--center {
  margin: 0;
}
@media (min-width: 1200px) {
  .numeration-wrapper.numeration-wrapper--center {
    margin: 0 0 0 -40px;
  }
}
.upload-wrapper {
  max-width: 388px;
}
.upload-wrapper > span {
  display: block;
}
.upload-wrapper .ant-form-item {
  margin-bottom: 0;
}
.upload-wrapper .ant-upload-list {
  color: #5a7ef2;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.upload-wrapper .ant-upload-list .ant-upload-text-icon:before {
  content: '\e947';
  font-family: 'icon-font';
  display: inline-block;
}
.upload-wrapper .ant-upload-list .ant-upload-text-icon svg {
  display: none;
}
.upload-wrapper .ant-upload-list .ant-upload-list-item-card-actions .ant-btn,
.upload-wrapper .ant-upload-list .ant-upload-list-item-card-actions .ant-btn:hover,
.upload-wrapper .ant-upload-list .ant-upload-list-item-card-actions .ant-btn:active,
.upload-wrapper .ant-upload-list .ant-upload-list-item-card-actions .ant-btn:focus {
  border: 0;
  opacity: 1;
  box-shadow: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 4px;
  min-width: 24px;
}
.upload-wrapper .ant-upload-list .ant-upload-list-item-card-actions .ant-btn .disabled-icon,
.upload-wrapper .ant-upload-list .ant-upload-list-item-card-actions .ant-btn:hover .disabled-icon,
.upload-wrapper .ant-upload-list .ant-upload-list-item-card-actions .ant-btn:active .disabled-icon,
.upload-wrapper .ant-upload-list .ant-upload-list-item-card-actions .ant-btn:focus .disabled-icon {
  opacity: 0.4;
}
.upload-wrapper .ant-upload-list .ant-upload-list-item-card-actions .ant-btn .disabled-icon:hover,
.upload-wrapper .ant-upload-list .ant-upload-list-item-card-actions .ant-btn:hover .disabled-icon:hover,
.upload-wrapper .ant-upload-list .ant-upload-list-item-card-actions .ant-btn:active .disabled-icon:hover,
.upload-wrapper .ant-upload-list .ant-upload-list-item-card-actions .ant-btn:focus .disabled-icon:hover {
  cursor: not-allowed;
}
.upload-wrapper .ant-upload-list .ant-upload-list-item-card-actions .anticon {
  color: #200e32;
}
.upload-wrapper .ant-upload-list .ant-upload-list-item-card-actions .anticon:before {
  content: '\e948';
  font-family: 'icon-font';
  display: inline-block;
  font-size: 8px;
}
.upload-wrapper .ant-upload-list .ant-upload-list-item-card-actions .anticon svg {
  display: none;
}
.upload-wrapper .ant-upload-list > div:first-child .ant-upload-list-item {
  margin-top: 0;
}
.upload-wrapper .ant-upload-list .ant-upload-list-item:hover .ant-upload-list-item-info {
  background-color: transparent;
}
.upload-wrapper .ant-upload-list .ant-upload-list-item:hover .ant-upload-list-item-name,
.upload-wrapper .ant-upload-list .ant-upload-list-item:hover .ant-upload-text-icon {
  color: #399cff;
}
.stageItem {
  cursor: default;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid transparent;
  flex-shrink: 0;
  font-size: 12px;
  font-weight: 700;
  color: #ffffff;
  position: relative;
}
.stageItem:first-child:before {
  content: '';
  display: block;
  position: absolute;
  width: 74px;
  height: 2px;
  left: calc(100% - 102px);
  background-color: #00aa63;
}
.stageItem:after {
  content: '';
  display: block;
  position: absolute;
  width: 74px;
  height: 2px;
  right: calc(100% - 100px);
}
.stageItem.disabled {
  background-color: #939597;
  box-shadow: 0px 0px 0px 3px #ffffff inset;
}
.stageItem.disabled:after {
  background-color: #939597;
}
.stageItem.successful {
  background-color: #00aa63;
  box-shadow: 0px 0px 0px 3px #ffffff inset;
}
.stageItem.successful::after {
  background-color: #00aa63;
}
.stageItem.error {
  background-color: #b91600;
  box-shadow: 0px 0px 0px 3px #ffffff inset;
}
.stageItem.error:after {
  background-color: #b91600;
}
.stageConfirm {
  cursor: default;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid transparent;
  flex-shrink: 0;
  font-size: 12px;
  font-weight: 700;
  color: #ffffff;
  position: relative;
}
.stageConfirm:before {
  content: '';
  display: block;
  position: absolute;
  width: 228px;
  height: 2px;
  left: 28px;
  background-color: #00aa63;
}
.stageConfirm:after {
  content: '';
  display: block;
  position: absolute;
  width: 228px;
  height: 2px;
  right: 28px;
}
.stageConfirm.successful {
  background-color: #00aa63;
  box-shadow: 0px 0px 0px 3px #ffffff inset;
}
.stageConfirm.successful::after {
  background-color: #00aa63;
}
.stageConfirm.disabled {
  background-color: #939597;
  box-shadow: 0px 0px 0px 3px #ffffff inset;
}
.stageConfirm.disabled:after {
  background-color: #939597;
}
.stageConfirm.error {
  background-color: #b91600;
  box-shadow: 0px 0px 0px 3px #ffffff inset;
}
.stageConfirm.error:after {
  background-color: #b91600;
}
.stageLogIn {
  cursor: default;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid transparent;
  flex-shrink: 0;
  font-size: 12px;
  font-weight: 700;
  color: #ffffff;
  position: relative;
}
.stageLogIn:first-child:before {
  content: '';
  display: block;
  position: absolute;
  width: 138px;
  height: 2px;
  left: calc(100% - 164px);
  background-color: #00aa63;
}
.stageLogIn:after {
  content: '';
  display: block;
  position: absolute;
  width: 140px;
  height: 2px;
  right: calc(100% - 168px);
}
.stageLogIn.disabled {
  background-color: #939597;
  box-shadow: 0px 0px 0px 3px #ffffff inset;
}
.stageLogIn.disabled:after {
  background-color: #939597;
}
.stageLogIn.successful {
  background-color: #00aa63;
  box-shadow: 0px 0px 0px 3px #ffffff inset;
}
.stageLogIn.successful::after {
  background-color: #00aa63;
}
.stageLogIn.error {
  background-color: #b91600;
  box-shadow: 0px 0px 0px 3px #ffffff inset;
}
.stageLogIn.error:after {
  background-color: #b91600;
}
.stageConfirmRevert {
  cursor: default;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid transparent;
  flex-shrink: 0;
  font-size: 12px;
  font-weight: 700;
  color: #ffffff;
  position: relative;
}
.stageConfirmRevert:before {
  content: '';
  display: block;
  position: absolute;
  width: 228px;
  height: 2px;
  left: 28px;
}
.stageConfirmRevert:after {
  content: '';
  display: block;
  position: absolute;
  width: 228px;
  height: 2px;
  right: 28px;
  background-color: #00aa63;
}
.stageConfirmRevert.disabled {
  background-color: #939597;
  box-shadow: 0px 0px 0px 3px #ffffff inset;
}
.stageConfirmRevert.disabled::before {
  background-color: #939597;
}
.stageConfirmRevert.error {
  background-color: #b91600;
  box-shadow: 0px 0px 0px 3px #ffffff inset;
}
.stageConfirmRevert.error::before {
  background-color: #b91600;
}
.stageConfirmRevert.successful {
  background-color: #00aa63;
  box-shadow: 0px 0px 0px 3px #ffffff inset;
}
.stageConfirmRevert.successful::before {
  background-color: #00aa63;
}
.tabs-main .tab-link {
  display: block;
  width: 100%;
  padding: 8px 14px;
}
.tabs-main .ant-tabs {
  display: block;
}
@media (min-width: 768px) {
  .tabs-main .ant-tabs {
    display: flex;
  }
}
.tabs-main .ant-tabs .ant-tabs-tab:hover,
.tabs-main .ant-tabs .ant-tabs-tab:focus {
  color: #191c21;
}
.tabs-main .ant-tabs-nav-wrap {
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .tabs-main .ant-tabs-nav-wrap {
    width: 200px;
    margin-bottom: 0;
  }
}
.tabs-main .ant-tabs-left > .ant-tabs-content-holder {
  border-left: 0;
  margin-left: 0;
}
.tabs-main .ant-tabs-left > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
  padding-left: 0;
}
.tabs-main .ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar {
  left: 0;
  border: 2px solid #fcd228;
  background-color: rgba(147, 149, 151, 0.1);
  width: 160px;
}
.tabs-main .ant-tabs-left > .ant-tabs-nav .ant-tabs-tab {
  height: 40px;
  padding: 0;
  margin: 0 0 2px 0;
  border: 1px solid #939597;
}
.tabs-main .ant-tabs-left > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 2px 0;
}
.tabs-main .ant-tabs-left > .ant-tabs-nav .ant-tabs-tab:hover {
  border: 1px solid #191c21;
}
.tabs-main .ant-tabs-left .ant-tabs-tab {
  width: 160px;
  margin: 0;
  color: #191c21;
}
.tabs-main .ant-tabs-left .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-weight: 600;
  color: #191c21;
  text-shadow: none;
  line-height: 20px;
}
.tabs-main .ant-tabs-tab-btn {
  width: 100%;
  text-align: start;
}
.tabs-main .ant-tabs-tab-btn:hover,
.tabs-main .ant-tabs-tab-btn:focus {
  color: #191c21;
}
.inverse .ant-tabs .ant-tabs-tab:hover,
.inverse .ant-tabs .ant-tabs-tab:focus {
  color: #ffffff;
}
.inverse .ant-tabs-left > .ant-tabs-nav .ant-tabs-tab {
  border: 1px solid #939597;
}
.inverse .ant-tabs-left > .ant-tabs-nav .ant-tabs-tab:hover {
  border: 1px solid #ffffff;
}
.inverse .ant-tabs-left .ant-tabs-tab {
  color: #939597;
}
.inverse .ant-tabs-left .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ffffff;
}
.inverse .ant-tabs-tab-btn:hover,
.inverse .ant-tabs-tab-btn:focus {
  color: #ffffff;
}
.label {
  display: inline-block;
  padding: 10px;
  border: 1px solid;
  border-radius: 8px;
  line-height: 18px;
  text-align: center;
  color: #939597;
}
.label > span {
  font-weight: 700;
}
.label.monthly,
.label.annually {
  background: rgba(252, 210, 40, 0.1);
  border-color: #fcd228;
}
.label.annually {
  color: #242424;
}
.label.label-dark {
  color: #939597;
}
.label.label-dark.monthly,
.label.label-dark.annually {
  background: rgba(147, 149, 151, 0.1);
  border: 1px solid rgba(147, 149, 151, 0.3);
}
.label.label-dark.annually {
  color: #ffffff;
}
body .ant-switch {
  width: 90px;
  height: 30px;
}
body .ant-switch.icon-switch {
  background: #fcd228;
}
body .ant-switch.icon-switch .ant-switch-handle::before {
  content: '\e928';
  font-family: 'icon-font';
  font-size: 11px;
  color: #191c21;
}
body .ant-switch:hover {
  background: #fcd228;
}
body .ant-switch:hover:not(.ant-switch-disabled) {
  background: #fcd228;
}
body .ant-switch .ant-switch-inner {
  padding-inline-start: 30px;
}
body .ant-switch .ant-switch-handle {
  width: 23px;
  height: 23px;
  top: 3px;
  left: 4px;
}
body .ant-switch .ant-switch-handle::before {
  border-radius: 20px;
  background: linear-gradient(180deg, #ffffff 0%, #e8eaea 100%);
}
body .ant-switch.ant-switch-checked {
  background: #fcd228;
}
body .ant-switch.ant-switch-checked .ant-switch-handle {
  inset-inline-start: calc(100% - 23px - 3px);
}
body .ant-switch.ant-switch-checked:hover {
  background: #fcd228;
}
body .ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background: #fcd228;
}
body .ant-switch .ant-switch-inner .ant-switch-inner-checked {
  margin-top: 3px;
}
.color-Education:before {
  background-color: #5a7ef2;
}
.color-Legal:before {
  background-color: #fec021;
}
.color-ProfessionalServices:before {
  background-color: #f5df4d;
}
.color-GovernmentAndPublicSector:before {
  background-color: #aa0047;
}
.color-Healthcare:before {
  background-color: #8e90b4;
}
.color-FinanceAndBanking:before {
  background-color: #009caa;
}
.color-RealEstate:before {
  background-color: #00aa63;
}
.color-ECommerce:before {
  background-color: #b91600;
}
.color-Manufacturing:before {
  background-color: #9caa00;
}
.color-HumanResources:before {
  background-color: #6300aa;
}
.color-TransportationAndLogistics:before {
  background-color: #00aa0e;
}
.color-Insurance:before {
  background-color: #00eaff;
}
.color-InformationTechnology:before {
  background-color: #ff00eb;
}
.color-Aviation:before {
  background-color: #2d6a00;
}
.color-Staffing:before {
  background-color: #002d6a;
}
.color-Accounting:before {
  background-color: #9113f6;
}
.color-Retail:before {
  background-color: #4760cc;
}
.color-HospitalityAndTourism:before {
  background-color: #9dd559;
}
.color-MediaAndEntertainment:before {
  background-color: #863d39;
}
.color-NonProfitAndCharity:before {
  background-color: #c98828;
}
.color-EnergyAndUtilities:before {
  background-color: #221736;
}
.color-Consulting:before {
  background-color: #207040;
}
.color-Sales:before {
  background-color: #4ee16c;
}
.color-MarketingAndAdvertising:before {
  background-color: #e14e98;
}
.color-FoodAndBeverage:before {
  background-color: #3f0822;
}
.color-Agriculture:before {
  background-color: #092426;
}
.color-Religion:before {
  background-color: #e85a4b;
}
.color-Construction:before {
  background-color: #6f8a35;
}
body .ant-checkbox-wrapper:after {
  display: none;
}
body .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled):after {
  border-color: #242424;
}
body .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
  background-color: transparent;
  border-color: #242424;
}
body .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner {
  border-color: #242424;
}
body .ant-checkbox .ant-checkbox-inner {
  width: 24px;
  height: 24px;
  border: 2px solid #242424;
  border-radius: 6px;
  background-color: transparent;
}
body .ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  border-color: #242424;
}
body .ant-checkbox-checked .ant-checkbox-inner {
  background-color: transparent;
}
body .ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #242424;
  inset-inline-start: 25.5%;
}
body .ant-checkbox-checked:after {
  display: none;
}
body .ant-checkbox-checked:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  background-color: transparent;
  border-color: #242424;
}
body .ant-checkbox-disabled .ant-checkbox-inner {
  border-color: #f0f0f0;
}
body .ant-checkbox-disabled .ant-checkbox-inner::after {
  border-color: #f0f0f0;
}
