@import 'src/styles/base/_variables.less';

.page {
  background-color: @gray-1;
  border-radius: 20px;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: auto minmax(0, 1fr);
  overflow: hidden;
  min-height: 100vh;
}

.header {
  padding: 20px;
  background: @gray-5;
  color: @white;
  height: 200px;
  font-weight: 500;
  font-size: 14px;
  border-radius: 20px;
  margin-bottom: -80px;
}

.title {
  font-size: 24px;
  color: @primary-color;
  font-weight: 700;
  margin-bottom: 0px;
}

.miniDetails {
  max-width: 400px;
  margin: 0 auto 20px;
  width: 100%;
}

.detailsName {
  font-size: 24px;
  font-weight: 700;
  color: @text-color-darken;
}

.wrap {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: auto auto minmax(0, 1fr);
  padding: 0 10px 10px;

  @media (min-width: @breakpoint-lg) {
    padding: 0 40px 10px;
  }
}

.scrollBox {
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: @gray-4;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: @gray-4;
  }
}

@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;