@import 'src/styles/base/_variables.less';

.success {
  padding: 20px;
  margin: 20px auto;
  min-height: 140px;
  border: 1px solid rgba(@gray-4, 0.3);
  border-radius: 8px;

  @media (min-width: @breakpoint-md) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }

  @media (min-width: @breakpoint-lg) {
    padding: 20px 40px;
    min-width: 760px;
    gap: 0;
  }

  &Box {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;

    @media (min-width: @breakpoint-md) {
      margin-bottom: 0;
    }

    @media (min-width: @breakpoint-lg) {
      gap: 40px;
    }
  }

  &Title {
    font-weight: 700;
    font-size: 24px;
    line-height: 20px;
    margin-bottom: 10px;
  }

  &Icon {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    background-color: @green;
    border: 1px solid rgba(@gray-4, 0.3);
    border-radius: 8px;
    color: @white;
    font-size: 40px;

    @media (min-width: @breakpoint-lg) {
      width: 80px;
      height: 80px;
      font-size: 50px;
    }
  }

  &Btn {
    :global {
      .ant-btn {
        width: 100%;

        @media (min-width: @breakpoint-md) {
          width: auto;
        }
      }
    }
  }
}

@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;