@import 'src/styles/base/_variables.less';

.stageItem {
  cursor: default;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid transparent;
  flex-shrink: 0;
  font-size: 12px;
  font-weight: 700;
  color: @white;
  position: relative;

  &:first-child {
    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 74px;
      height: 2px;
      left: calc(100% - 102px);
      background-color: @green;
    }
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 74px;
    height: 2px;
    right: calc(100% - 100px);
  }

  &.disabled {
    background-color: @gray-4;
    box-shadow: 0px 0px 0px 3px @white inset;

    &:after {
      background-color: @gray-4;
    }
  }

  &.successful {
    background-color: @green;
    box-shadow: 0px 0px 0px 3px @white inset;

    &::after {
      background-color: @green;
    }
  }

  &.error {
    background-color: @red;
    box-shadow: 0px 0px 0px 3px @white inset;

    &:after {
      background-color: @red;
    }
  }
}

.stageConfirm {
  cursor: default;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid transparent;
  flex-shrink: 0;
  font-size: 12px;
  font-weight: 700;
  color: @white;
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 228px;
    height: 2px;
    left: 28px;
    background-color: @green;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 228px;
    height: 2px;
    right: 28px;
  }

  &.successful {
    background-color: @green;
    box-shadow: 0px 0px 0px 3px @white inset;

    &::after {
      background-color: @green;
    }
  }

  &.disabled {
    background-color: @gray-4;
    box-shadow: 0px 0px 0px 3px @white inset;

    &:after {
      background-color: @gray-4;
    }
  }

  &.error {
    background-color: @red;
    box-shadow: 0px 0px 0px 3px @white inset;

    &:after {
      background-color: @red;
    }
  }
}

.stageLogIn {
  cursor: default;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid transparent;
  flex-shrink: 0;
  font-size: 12px;
  font-weight: 700;
  color: @white;
  position: relative;

  &:first-child {
    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 138px;
      height: 2px;
      left: calc(100% - 164px);
      background-color: @green;
    }
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 140px;
    height: 2px;
    right: calc(100% - 168px);
  }

  &.disabled {
    background-color: @gray-4;
    box-shadow: 0px 0px 0px 3px @white inset;

    &:after {
      background-color: @gray-4;
    }
  }

  &.successful {
    background-color: @green;
    box-shadow: 0px 0px 0px 3px @white inset;

    &::after {
      background-color: @green;
    }
  }

  &.error {
    background-color: @red;
    box-shadow: 0px 0px 0px 3px @white inset;

    &:after {
      background-color: @red;
    }
  }
}

.stageConfirmRevert {
  cursor: default;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid transparent;
  flex-shrink: 0;
  font-size: 12px;
  font-weight: 700;
  color: @white;
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 228px;
    height: 2px;
    left: 28px;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 228px;
    height: 2px;
    right: 28px;
    background-color: @green;
  }

  &.disabled {
    background-color: @gray-4;
    box-shadow: 0px 0px 0px 3px @white inset;

    &::before {
      background-color: @gray-4;
    }
  }

  &.error {
    background-color: @red;
    box-shadow: 0px 0px 0px 3px @white inset;

    &::before {
      background-color: @red;
    }
  }

  &.successful {
    background-color: @green;
    box-shadow: 0px 0px 0px 3px @white inset;

    &::before {
      background-color: @green;
    }
  }
}
