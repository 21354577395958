@import 'src/styles/base/_variables.less';

.notifyPopover {
  &:global(.ant-popover) {
    max-width: 546px;
  }

  :global {
    .ant-popover-title {
      background-color: @gray-5;
      border-radius: 20px 20px 0 0;
      padding: 0;
    }

    .ant-popover-inner-content {
      padding: 0;
    }
  }

  &Opener {
    &:global(.ant-btn) {
      height: 34px;

      @media (min-width: @breakpoint-md) {
        height: 30px;
      }
    }

    :global {
      .ant-badge {
        font-size: 26px;

        @media (min-width: @breakpoint-md) {
          font-size: 20px;
        }
      }

      .ant-badge-count {
        font-size: 8px;
        font-weight: 700;
        height: 10px;
        line-height: 10px;
        min-width: 16px;
        padding: 0 4px;
        box-shadow: none;
        background-color: @green;
        top: 2px;
      }

      .ant-scroll-number-only {
        height: 10px;
        > .ant-scroll-number-only-unit {
          height: 10px;
        }
      }
    }
  }
}

.notificationList {
  height: 59vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px;
}

.notifyCard {
  padding: 20px 20px 10px;
  border: 1px solid @gray-3;
  border-radius: 20px;
  margin-bottom: 10px;
  transition: all 0.3s ease;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    transition: all 0.3s ease;

    .notifyCardClose {
      opacity: 1;
      visibility: visible;
      transition: all 0.3s ease;
    }
  }

  &:global(.new-notification) {
    border-color: @green;

    .notifyCardPoint {
      background-color: @green;
    }
  }

  &Head {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  &Title {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    padding: 0 10px;
  }

  &Point {
    flex-shrink: 0;
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: @gray-3;
  }

  &Close {
    font-size: 10px;
    text-align: center;
    line-height: 14px;
    margin-left: auto;

    @media (min-width: @breakpoint-xl) {
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s ease;
    }
  }

  &Text {
    margin-bottom: 10px;
  }
}

.notifyEmpty {
  text-align: center;
  max-width: 380px;
  margin: 0 auto;
  font-weight: 500;
  padding-top: 20px;

  @media (min-width: @breakpoint-md) {
    padding-top: 70px;
  }

  &Progress {
    width: 150px;
    height: 150px;
    margin: 0 auto 20px;
    border-radius: 50%;
    border: 1px solid @gray-3;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 31px;
    position: relative;

    @media (min-width: @breakpoint-md) {
      margin-bottom: 80px;
    }

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 120px;
      height: 120px;
      border-radius: 50%;
      border: 2px solid @white;
      box-shadow: 0px 0px 0px 4px @gray-2, 0px 0px 0px 14px @gray-6 inset;
    }
  }

  &Title {
    margin-bottom: 15px;
  }
}

@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;