@import 'src/styles/base/_variables.less';

.tabs {
  :global {
    .ant-tabs {
      .ant-tabs-nav-wrap {
        .ant-tabs-tab {
          padding: 0 10px 16px 10px;
          font-weight: 700;
          justify-content: center;
        }
      }

      .ant-tabs-tab {
        &.ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            color: inherit;
            text-shadow: none;
          }
        }

        &:hover {
          color: inherit;
        }
      }

      .ant-tabs-ink-bar {
        height: 3px;
        background-color: @green;
        border-radius: 2px;
      }
    }

    .ant-tabs-tab {
      + .ant-tabs-tab {
        margin: 0 0 0 20px;
      }
    }

    .ant-tabs-top {
      > .ant-tabs-nav {
        margin: 0 0 20px 0;

        &::before {
          border-bottom-color: rgba(147, 149, 151, 0.3);
        }
      }
    }
  }
}

@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;