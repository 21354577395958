@import '../../../styles/base/_variables.less';

@borderRows: 1px solid @gray-3;

.directionWrap {
  overflow: auto;
  margin-right: -10px;
  padding-right: 10px;
}

.directionBox,
.directionWrap {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.directionBox {
  padding-bottom: 10px;
  flex-grow: 1;
  min-height: 200px;
}

.role,
.info {
  color: @gray-4;
}

.title {
  display: none;

  @media (min-width: @breakpoint-xl) {
    display: block;
    margin-bottom: 20px;
    font-weight: 700;
    line-height: 40px;
  }
}

.tabName {
  margin-bottom: 10px;
}

.info {
  padding: 0;

  a {
    color: @blue-2;
  }
}

.inviteBtnBox {
  margin-bottom: 20px;

  @media (min-width: @breakpoint-xl) {
    text-align: right;
  }
}

.inviteBtn {
  :global {
    width: 100px;
  }
}

.tableHead {
  margin-bottom: 20px;
  background: rgba(@gray-4, 0.04);

  .tableCol {
    padding: 12px 20px;
  }
}

.tableRow {
  @media (min-width: @breakpoint-xl) {
    display: grid;
    grid-template-columns: 30% auto 10%;
  }

  &.xsNone {
    display: none;

    @media (min-width: @breakpoint-xl) {
      display: grid;
      grid-template-columns: 30% auto 10%;
    }
  }

  &.lgNone {
    @media (min-width: @breakpoint-xl) {
      display: none;
    }
  }
}

.tableCol {
  padding: 5px 50px 5px 0;

  @media (min-width: @breakpoint-xl) {
    padding: 8px 20px;
  }
}

.tableBody {
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 10px;
  flex-grow: 1;

  .tableRow {
    border-top: @borderRows;
    position: relative;
    padding: 10px 0;

    @media (min-width: @breakpoint-xl) {
      padding: 0;
    }

    &:last-child {
      border-bottom: @borderRows;
    }

    .tableCol {
      display: flex;
      align-items: center;
      gap: 10px;

      &.actions {
        text-align: center;
        position: absolute;
        top: 20px;
        right: -35px;

        @media (min-width: @breakpoint-xl) {
          position: static;
        }
      }
    }
  }
}

.active {
  color: @green;
}

.pending {
  color: @yellow-2;
}

.resentBtn {
  &:global(.ant-btn-icon-only.ant-btn-sm) {
    > * {
      font-size: 18px;
      color: @gray-4;
    }
  }
}

.avatar {
  :global {
    .ant-avatar {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 24px;
      width: 24px;
      border: 1px solid @white;
      filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.1));
    }
  }
}

.btn {
  :global {
    .ant-btn {
      &:not(.ant-btn-link) {
        &.ant-btn-ghost {
          i {
            color: @gray-4;
          }
        }
      }
    }
  }
}

.header {
  display: grid;
  grid-template-areas:
    'title title'
    'avatar btn';

    @media (min-width: @breakpoint-xl) {
    grid-template-areas: none;
    grid-template-columns: 30% auto 10%;
  }

  &Avatar {
    display: flex;
    align-items: center;
    grid-area: avatar;

    @media (min-width: @breakpoint-xl) {
      grid-area: auto;
    }
  }

  &Title {
    font-weight: 700;
    text-transform: uppercase;
    align-self: center;
    grid-area: title;

    @media (min-width: @breakpoint-xl) {
      grid-area: auto;
    }
  }

  &Box {
    text-align: right;
    grid-area: btn;

    @media (min-width: @breakpoint-xl) {
      grid-area: auto;
    }
  }
}

.collapse {
  :global {
    .ant-collapse {
      background-color: transparent;
      border: solid #f0f0f0;
      border-width: 1px 0 0 0;
      border-radius: 0;

      .ant-collapse-content {
        border-top: 0;
        background: transparent;

        > .ant-collapse-content-box {
          padding: 0;
        }
      }

      > .ant-collapse-item {
        border-bottom: 1px solid #f0f0f0;

        > .ant-collapse-header {
          align-items: center;
          padding: 10px 15px 10px 0;
          gap: 10px;

          @media (min-width: @breakpoint-xl){
            gap: 0;
            padding: 9px 52px 9px 20px;
          }

          .ant-collapse-expand-icon {
            width: 18px;
            height: 18px;
            justify-content: center;
            padding-inline-end: 0;
            margin-inline-start: 0;

            @media (min-width: @breakpoint-xl) {
              padding-inline-end: 10px;
            }
          }

          .ant-collapse-arrow {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.team {
  margin-bottom: 0;

  &.tableBody {
    .tableRow {
      &:last-child {
        border-bottom: 0;
      }
    }
  }
}

.pagination {
  margin-top: 15px;
}

@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;