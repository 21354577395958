@import 'src/styles/base/_variables.less';

.detailsCardHead {
  display: flex;
  align-items: center;
  background-color: @white;
  border-radius: 20px;
  box-shadow: 0px 3px 8px rgba(@black, 0.1);
  padding: 15px;

  @media (min-width: @breakpoint-xs) {
    padding: 20px 30px;
  }
}

.detailsProgress {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 82px;
  height: 82px;
  border-radius: 50%;

  :global {
    .ant-progress-circle-trail {
      stroke: rgba(@gray-6, 0.85);
    }

    .ant-progress-circle {
      &.ant-progress-status-success {
        .ant-progress-text {
          color: @gray-5;
        }
      }

      .ant-progress-text {
        font-size: 17px;
        font-weight: 700;
        color: @gray-5;
      }
    }
  }
}

.detailsInfo {
  flex-shrink: 0;
  padding-left: 8px;
  position: relative;
  color: @text-color;
  margin-left: 10px;

  @media (min-width: @breakpoint-lg) {
    margin-left: 12%;
  }

  &:after {
    content: '';
    position: absolute;
    top: 4px;
    bottom: 4px;
    left: 0;
    width: 1px;
    background-color: @primary-color;
  }

  &Title {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 5px;
    color: @primary-color;
  }
}

.detailsCardTitle {
  color: @black;
}

@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;