@import 'src/styles/base/_variables.less';

.billingCard {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 40px 20px;
  border: 1px solid rgba(@gray-4, 0.3);
  border-radius: 8px;
}

.input {
  :global {
    .ant-input {
      height: 40px;

      &.stripe {
        padding: 4px 11px;
        border: 1px solid #f0f0f0;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    .ant-select {
      &:not(.ant-select-customize-input) {
        .ant-select-selector {
          font-size: 14px;
          border: 1px solid @gray-3;
          background-color: transparent !important;
        }
      }

      &:not(.ant-select-disabled) {
        &:hover {
          .ant-select-selector {
            background-color: transparent;
            border-color: @gray-4;
            box-shadow: none;
          }
        }
      }
    }
  }
}

.title {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 20px;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: @gray-4;
  margin-bottom: 10px;

  > i {
    color: @white;
  }
}

@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;