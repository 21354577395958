@import 'src/styles/base/_variables.less';

.btn {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  left: 0;

  @media (min-width: @breakpoint-xl) {
    left: -40px;
  }

  &Wrap {
    :global {
      .ant-btn {
        padding: 0;
        width: 100%;
        min-width: 132px;
        color: @green;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;

        &:hover {
          color: rgba(@green, 0.7);
        }
      }
    }
  }

  &Circle {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: @white;
    border: 3px dashed @gray-4;
    position: relative;
    z-index: 10;
  }
}

.inverse {
  .btnCircle {
    background-color: @black-2;
  }
}

@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;