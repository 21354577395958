@import './_variables.less';

html {
  font-size: 16px;
  font-family: @font-third !important;
}

body {
  min-width: 320px;

  &.fogging {
    &:before {
      content: '';
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(@black, 0.05);
      z-index: 4;
      pointer-events: none;
    }
  }
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  overflow: hidden;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  color: inherit;
}

.overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.full-width {
  width: 100% !important;
}

.cursor-pointer {
  cursor: pointer;
}

.d-flex {
  display: flex !important;
}

.no-margin {
  margin: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.page-wrapper {
  width: 100%;
  flex-shrink: 1;
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  overflow: hidden;

  > .ant-row {
    width: 100%;
    flex-grow: 1;
  }

  &.ant-row {
    flex-wrap: initial;
  }
}

.scroll-box {
  flex-shrink: 1;
  flex-grow: 1;
  flex-basis: 0;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.page-inner {
  padding: 20px;
  position: relative;

  @media (min-width: @breakpoint-xl) {
    padding: 40px;
  }

  &.pt-0 {
    @media (max-width: @breakpoint-lg - 1) {
      padding-top: 0;
    }
  }
}

.page-holder {
  width: 100%;
  margin: 0 auto;
  max-width: 1180px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.page-box {
  padding: 20px;
}

.filters-actions {
  margin: 0 -5px 10px;
  padding-top: 20px;

  @media (min-width: @breakpoint-lg) {
    padding: 0;
  }

  > div {
    padding: 5px;
  }

  &__search {
    &.ant-input-affix-wrapper,
    .ant-input {
      padding: 2px 0 2px 10px;
      border-color: rgba(@gray-4, 0.3);
      background-color: @search-bg;

      &:hover,
      &:focus {
        background-color: @search-bg;
        border-color: @gray-4;
        box-shadow: none;
      }

      &.ant-input-affix-wrapper-focused {
        border-color: @gray-4;
        box-shadow: none;

        .ant-input-prefix {
          display: none;
        }
      }
    }

    .ant-input-prefix {
      font-size: 14px;
      color: @text-color;
      margin: 0;
    }

    .ant-input-suffix {
      cursor: pointer;

      &:hover {
        > span {
          &:before {
            color: @text-color;
          }
        }
      }

      > span {
        &:before {
          content: '\e90e';
          font-family: 'icon-font';
          display: block;
          font-size: 20px;
          color: @gray-5;
        }

        svg {
          display: none;
        }
      }
    }

    .ant-input {
      background-color: transparent;
      font-size: 12px;
      color: @text-color;

      &::placeholder {
        color: @text-color;
        opacity: 1;
      }
    }
  }

  &__search_dark {
    &.ant-input-affix-wrapper,
    .ant-input {
      padding: 2px 0 2px 10px;
      border-color: rgba(@gray-4, 0.3);

      &:hover,
      &:focus {
        border-color: @gray-4;
        box-shadow: none;
      }

      &.ant-input-affix-wrapper-focused {
        border-color: @gray-4;
        box-shadow: none;

        .ant-input-prefix {
          display: none;
        }
      }
    }

    .ant-input-prefix {
      font-size: 14px;
      color: @text-color;
      margin: 0;
    }

    .ant-input-suffix {
      cursor: pointer;

      &:hover {
        > span {
          &:before {
            color: @text-color;
          }
        }
      }

      > span {
        &:before {
          content: '\e90e';
          font-family: 'icon-font';
          display: block;
          font-size: 20px;
          color: @gray-5;
        }

        svg {
          display: none;
        }
      }
    }

    .ant-input {
      background-color: transparent;
      font-size: 12px;
      color: inherit;

      &::placeholder {
        opacity: 1;
        color: inherit;
      }
    }
  }

  &__drop {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0 -5px;

    > div {
      padding: 0 5px;
    }
  }

  &__wrap {
    width: 100%;
  }
}

.ant-app {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.ant-layout-content {
  display: flex;
  flex-direction: column;
}

.bg-transparent {
  background-color: transparent;
}

.h1 {
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;

  @media (min-width: @breakpoint-lg) {
    font-size: 36px;
    line-height: 40px;
  }
}

.h2 {
  font-size: 24px;
  line-height: 24px;
  font-weight: 700;
}

.page-title {
  margin-bottom: 22px;
}

.noData {
  padding-top: 40px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  text-align: center;

  @media (min-width: @breakpoint-md) {
    padding-top: 114px;
  }

  .image {
    width: 140px;
    margin: 0 auto 60px;
  }

  .title {
    font-size: 24px;
    line-height: 20px;
    margin-bottom: 10px;
  }

  .text {
    max-width: 420px;
    margin: 0 auto;
    color: @gray-4;

    span,
    a {
      text-decoration: underline;
    }
  }
}

.ElementsApp {
  input {
    color: @white;
  }
}

.multiple-select {
  .ant-select-multiple {
    width: 100%;
    font-size: 16px;

    .ant-select-selector {
      padding: 5px 10px;
    }

    .ant-select-selection-search {
      margin: 0;
      color: @gray-4;
    }
  }

  .ant-select {
    .ant-select-selection-placeholder {
      color: @gray-4;
    }
  }

  .ant-select:not(.ant-select-customize-input) {
    .ant-select-selector {
      border-color: rgba(@gray-4, 0.3);
      background-color: #f6f6f6;
    }
  }

  .ant-select:not(.ant-select-disabled) {
    &:not(.ant-select-customize-input) {
      &:not(.ant-pagination-size-changer) {
        &:hover {
          .ant-select-selector {
            border-color: @gray-4;
          }
        }
      }
    }
  }

  .ant-select-focused {
    &.ant-select:not(.ant-select-disabled) {
      &:not(.ant-select-customize-input) {
        &:not(.ant-pagination-size-changer) {
          .ant-select-selector {
            border-color: @gray-4;
          }
        }
      }
    }
  }

  &.dark-multiple {
    .ant-select:not(.ant-select-customize-input) {
      .ant-select-selector {
        border-color: rgba(@gray-4, 0.3);
        background-color: transparent;
      }
    }

    .ant-select {
      .ant-select-selection-placeholder {
        color: @white;
      }
    }


  }
}

