@import 'src/styles/base/_variables.less';

.footer {
  padding: 60px 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  background-color: @black-2;

  @media (min-width: @breakpoint-md) {
    padding: 118px 0;
  }

  @media (min-width: @breakpoint-xxl) {
    background-repeat: no-repeat;
    background-position: bottom 14% right -13%;
    background-image: url('../../../../src/assets/img/landing/triangle.svg');
  }

  &Logo {
    width: 225px;
    margin: 0 auto 20px;

    @media (min-width: @breakpoint-md) {
      margin: 0;
    }

    img {
      display: block;
      max-width: 100%;
    }
  }

  &Box {
    @media (min-width: @breakpoint-md) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &Inner {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &Links {
    display: flex;
    align-items: center;
    justify-content: center;
    color: @white;
    margin: 0 -10px;

    @media (min-width: @breakpoint-md) {
      justify-content: flex-start;
    }

    &:last-child {
      margin: 0 -20px;
    }

    > li {
      padding: 0 10px;

      &:last-child {
        padding: 0 20px;
      }
    }

    a {
      color: @white;

      &:hover {
        color: @yellow-3;
      }

      &.active {
        color: @yellow-3;

        &:hover {
          color: rgba(@yellow-3, 0.8);
        }
      }
    }

    &Copy {
      display: none;

      @media (min-width: @breakpoint-md) {
        display: block;
      }
    }
  }

  &Social {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    margin: 0 -7px;

    @media (min-width: @breakpoint-md) {
      justify-content: flex-start;
    }

    > li {
      padding: 0 7px;
    }

    a {
      color: @white;

      &:hover {
        color: @yellow-3;
      }
    }
  }
}

.copy {
  display: block;
  text-align: center;
  color: @white;

  @media (min-width: @breakpoint-md) {
    display: none;
  }
}

@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;