@import 'src/styles/base/_variables.less';

body {
  .ant-input-affix-wrapper {
    padding: 0;

    .ant-input.ant-input-lg {
      padding: 5px 36px 5px 11px;
    }

    .ant-input-suffix {
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
      right: 11px;
    }
  }

  .ant-input {
    border-color: @gray-4;
    &:hover {
      border-color: @gray-4;
    }

    &::placeholder {
      color: #c4c5c6;
    }
  }

  .ant-form-item {
    margin-bottom: 20px;

    .ant-form-item-label {
      > label {
        color: inherit;
      }
    }
  }

  .ant-form-item-explain,
  .ant-form-item-extra {
    line-height: 20px;
  }

  .ant-form-item-explain-success {
    color: #00aa63;
  }

  .pseudo-label {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .light-input-group {
    .ant-input,
    .ant-input-affix-wrapper {
      border-color: @white;
      color: @white;
      background-color: @gray-5;

      &:hover,
      &:focus {
        background-color: @gray-5;
        border-color: @white;
        box-shadow: none;
      }

      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 50px @gray-5 inset !important;
        font-family: @font-main;
        font-size: 16px;
        color: @white;

        &::first-line {
          font-family: @font-main;
          font-size: 16px;
          color: @white;
        }

        &:hover,
        &:focus,
        &:active {
          -webkit-box-shadow: 0 0 0 50px @gray-5 inset !important;
          font-family: @font-main;
          font-size: 16px;
          color: @white;
        }
      }
    }

    &.ant-form-item-has-error {
      .ant-input,
      .ant-input-affix-wrapper {
        border-color: @red;
      }
    }

    .ant-input-password-icon {
      color: @white;
    }

    .ant-form-item-label {
      > label {
        color: @white;
      }
    }
  }

  .dark-input-group {
    .ant-input,
    .ant-input-affix-wrapper {
      border-color: @gray-3;
      background-color: transparent;

      &:hover,
      &:focus {
        background-color: transparent;
        border-color: @gray-4;
        box-shadow: none;
      }
    }

    &.ant-form-item-has-error {
      .ant-input,
      .ant-input-affix-wrapper {
        border-color: @red;
      }
    }

    .ant-select {
      &:not(.ant-select-customize-input) {
        .ant-select-selector {
          background-color: rgba(@gray-16, 0.25);
        }
      }

      .ant-select-selection-item {
        color: rgba(@text-color-darken, 0.8);
        font-weight: 400;
      }
    }
  }

  .ant-input:focus,
  .ant-input-focused {
    border-color: @gray-5;
    box-shadow: none;
  }

  .ant-picker {
    width: 100%;
    border-color: @gray-3;
    background-color: transparent;

    .ant-picker-input {
      > input {
        &::placeholder {
          color: #c4c5c6;
        }
        &:placeholder-shown {
          color: #c4c5c6;
        }
      }
    }

    &:hover,
    &:focus {
      background-color: transparent;
      border-color: @gray-4;
      box-shadow: none;
    }

    &.ant-picker-focused {
      background-color: transparent;
      border-color: @gray-4;
      box-shadow: none;

      .ant-picker-suffix {
        &:before {
          color: @text-color-darken;
        }
      }
    }

    .ant-picker-suffix {
      > span {
        display: none;
      }

      &:before {
        content: '\e905';
        font-family: 'icon-font';
        display: block;
        font-size: 18px;
      }
    }

    .ant-picker-clear {
      > span {
        display: none;
      }

      &:before {
        content: '\e920';
        font-family: 'icon-font';
        display: block;
        font-size: 18px;
        background-color: rgba(@gray-16, 0.25);
      }
    }
  }

  .ant-picker-dropdown {
    .ant-picker-today-btn {
      color: @yellow;

      &:hover {
        color: @yellow-2;
      }
    }
  }

  .ant-select {
    &:not(.ant-select-customize-input) {
      .ant-select-selector {
        background-color: transparent;
        border-color: @gray-3;
      }
    }

    &.ant-select-focused {
      &:not(.ant-select-disabled) {
        &:not(.ant-select-customize-input) {
          .ant-select-selector {
            border-color: @gray-10;
            box-shadow: none;
          }
        }
      }
    }

    .ant-select-arrow {
      width: 18px;
      height: 18px;
      margin-top: -10px;

      > span {
        display: none;
      }

      &:before {
        content: '\e911';
        font-family: 'icon-font';
        display: block;
        font-size: 18px;
      }
    }
  }

  .ant-select-dropdown {
    border: 3px solid @white;
    border-radius: 20px;
    box-shadow: 0px 3px 8px rgba(@black, 0.1);
    padding: 0 20px;

    .ant-select-item {
      padding: 10px 0;
      border-radius: 0;
      border-bottom: 1px solid @gray-3;

      &:last-child {
        border: 0;
      }

      &.ant-select-item-option-selected {
        background-color: transparent;
        font-weight: 700;
      }

      &.ant-select-item-option-active {
        background-color: transparent;
        font-weight: 700;
      }
    }
  }

  .ant-radio-wrapper {
    .ant-radio-inner {
      width: 20px;
      height: 20px;
      border: 2px solid @gray-5;

      &::after {
        background-color: @gray-5;
      }
    }

    .ant-radio-checked {
      .ant-radio-inner {
        border-color: @gray-5;
        background-color: @white;

        &::after {
          transform: scale(0.7);
        }
      }
    }

    &:hover {
      .ant-radio-inner {
        border-color: @gray-5;
      }
    }
  }

  .input-bg {
    .ant-input,
    .ant-input-affix-wrapper {
      background-color: @search-bg;
    }
  }
}
