@import 'src/styles/base/_variables.less';

.btnBox {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0 -5px 40px;

  > div {
    padding: 0 5px;
  }
}

.input {
  :global {
    .ant-input {
      height: 60px;
      box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
      border: none;
      border-radius: 4px !important;
      padding: 10px 80px 10px 7px !important;

      &:hover,
      &:focus {
        box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
        border: none;
        border-radius: 4px;
      }
    }

    .ant-input-prefix{
      margin-inline-end: 0;
    }
  }
}

.wrapper {
  position: relative;
}

.dark {
  .input {
    :global {
      .ant-input {
        box-shadow: none;
        background: #1e2126;
        border: 1px solid rgba(147, 149, 151, 0.3);
        border-radius: 4px;
        padding: 10px 80px 10px 7px !important;

        &:hover,
        &:focus {
          border: 1px solid rgba(147, 149, 151, 0.3);
          box-shadow: none;
        }
      }

      .ant-input-status-error {
        &:not(.ant-input-disabled) {
          &:not(.ant-input-borderless) {
            .ant-input {
              &:focus {
                box-shadow: none;
              }
            }
          }
        }
      }
    }
  }
}

.items {
  display: flex;
  align-items: center;
  position: absolute;
  top: 10px;
  right: 20px;
  margin: 0 -5px;
  z-index: 10;

  > div {
    padding: 0 5px;
  }
}

.btn {
  :global {
    .ant-btn {
      height: 40px;
    }
  }
}

.popover {
  &:global(.ant-popover) {
    max-width: fit-content;
  }

  :global {
    .ant-popover-inner {
      box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.2);
      border-radius: 8px;
    }

    .ant-popover-inner-content {
      padding: 8px;

      .cards-wrapper {
        justify-content: flex-end;
      }

      .cards {
        max-width: 520px;
      }
    }
  }
}

.cursorPosition {
  top: 28%;
  left: 8px;
  z-index: 10;
}

.darkCursor {
  background-color: @white;
}
@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;