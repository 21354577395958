@import '../../../../styles/base/_variables.less';

.radioGroup {
  :global {
    .ant-radio-wrapper {
      > span {
        &:first-child {
          align-self: center;
        }
      }
    }
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &Price {
    color: @black-2;
    font-size: 14px;
    line-height: 20px;

    > span {
      font-size: 20px;
    }
  }
}

.info {
  border: 1px solid @gray-3;
  border-radius: 4px;
  padding: 10px;

  &Title {
    font-size: 16px;
    font-weight: 500;
  }

  &Text {
    color: @gray-4;
  }
}

.input {
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }

    .ant-select-multiple {
      .ant-select-selector {
        padding: 5px 4px;
      }
    }
  }
}

.wrapper {
  > div {
    &:first-child {
      border-top: 1px solid @gray-3;
    }

    &:not(:last-child) {
      border-bottom: 1px solid @gray-3;
    }
  }
}

.item {
  padding: 10px 0;

  :global {
    .ant-form-item {
      margin-bottom: 6px;

      .ant-form-item-control-input {
        min-height: auto;
      }
    }

    .ant-input {
      border: none;
      color: @blue-2;
      background-color: transparent;
      padding: 0;
    }
  }
}

@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;