@import 'src/styles/base/_variables.less';

.skeletonWrap {
  :global {
    .ant-skeleton-content {
      .ant-skeleton-paragraph {
        display: grid;
        grid-template-columns: minmax(0, 336px);
        grid-gap: 10px;
        justify-content: center;
        margin-bottom: 20px;

        @media (min-width: @breakpoint-sm) {
          justify-content: flex-start;
          grid-template-columns: repeat(2, 1fr);
        }

        @media (min-width: @breakpoint-md) {
          grid-template-columns: repeat(3, 1fr);
        }

        @media (min-width: @breakpoint-lg) {
          grid-template-columns: repeat(2, 1fr);
        }

        @media (min-width: @breakpoint-xl) {
          grid-template-columns: repeat(3, 1fr);
        }

        @media (min-width: @breakpoint-xxl) {
          grid-template-columns: repeat(5, 1fr);
        }

        li {
          width: auto !important;
          min-height: 160px;
          border-radius: 20px;

          & + li {
            margin: 0;
          }
        }
      }
    }
  }
}

.skeletonTitle {
  text-transform: uppercase;
  color: @text-color-darken;
  width: 100%;

  @media (min-width: @breakpoint-sm) {
    margin-bottom: 20px;
  }

  @media (min-width: @breakpoint-md) {
    width: calc(66.66% - 5px);
  }

  @media (min-width: @breakpoint-lg) {
    width: 100%;
  }

  @media (min-width: @breakpoint-xl) {
    width: calc(66.66% - 5px);
  }

  @media (min-width: @breakpoint-xxl) {
    width: calc(40% - 5px);
  }

  :global {
    .ant-skeleton-content {
      .ant-skeleton-paragraph {
        li {
          width: 100% !important;
          min-height: 40px;
          border-radius: 4px;

          & + li {
            margin: 0;
          }
        }
      }
    }
  }
}


@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;