@import 'src/styles/base/_variables.less';

.layout {
  .side-nav {

    .ant-layout-sider-children {
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    &.ant-layout-sider {
      background: @black-2;
    }

    .ant-menu {
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      padding-top: 30px;
      background: transparent;
      border: 0;
    }

    .ant-menu-item {
      height: 80px;
      padding: 0;
      margin: 0 0 10px 0;
      width: 100%;
      border-radius: 0;
      background-color: transparent !important;

      &:last-child {
        margin: 0;
      }

      .ant-menu-title-content {
        .selected {
          font-weight: 700;
          color: @yellow-2;

          .side-icon {
            color: @gray-1;
            background-color: @yellow-2;
          }
        }
      }
    }

    .ant-menu-title-content {
      padding: 4px 10px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      width: 100%;
      color: @gray-1;
      font-size: 14px;
      line-height: 16px;
      text-transform: uppercase;
      white-space: break-spaces;
      text-align: center;
      transition: none;

      a {
        display: flex;
        flex-direction: column;
        align-items: center;

        .side-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
          font-size: 24px;
          width: 34px;
          height: 34px;
          border-radius: 7px;
          background-color: @black-2;
          margin: 0 0 6px 0;
        }
      }
    }

    &.mobile-nav {

      &.ant-layout-sider {
        transition: all 0.6s linear;
      }

      .ant-menu-item {
        height: 60px;
        margin: 0;
      }

      .ant-menu-title-content {
        flex-direction: row;
        justify-content: flex-start;

        a {
          display: flex;
          flex-direction: row;

          .side-icon {
            margin: 0 5px 0 0;
          }
        }
      }
    }
  }
}

@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;