@import '../../../styles/base/_variables.less';

.list {
  > .wrapper {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}

.wrapper {
  width: 100%;
  border: 1px solid rgba(@gray-4, 0.3);
  border-radius: 8px;
}

.box {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  @media (min-width: @breakpoint-md) {
    padding: 10px 20px;
    height: 60px;
    gap: 40px;
  }

  &.border {
    border-bottom: 1px solid rgba(@gray-4, 0.3);
  }
}

.actions {
  display: flex;
  align-items: center;
  margin: 0 -12px;

  > div {
    padding: 0 12px;
  }
}

.menu {
  :global {
    .ant-menu-horizontal {
      border: 0;
      font-weight: 700;
      width: 110px;
      line-height: 40px;

      @media (min-width: @breakpoint-md) {
        width: 220px;
        line-height: 60px;
      }

      &:not(.ant-menu-dark) {
        > .ant-menu-item-selected {
          color: @black-2;

          &::after {
            border-bottom: 3px solid @green-1;
            border-radius: 2px;

            @media (min-width: @breakpoint-md) {
              width: 68px;
            }
          }
        }

        > .ant-menu-item {
          padding: 0 10px;

          @media (min-width: @breakpoint-md) {
            padding: 0 35px;
          }

          &:hover {
            color: @black-2;

            &:after {
              border-bottom: 3px solid @green-1;
            }
          }
        }
      }
    }
  }
}

.menuInverse {
  :global {
    .ant-menu-horizontal {
      &:not(.ant-menu-dark) {
        > .ant-menu-item-selected {
          color: @white;
        }

        > .ant-menu-item {
          &:hover {
            color: @white;
          }
        }
      }
    }
  }
}

.search {
  width: 100%;

  :global {
    .ant-input-affix-wrapper,
    .ant-input {
      padding: 8px 0 8px 10px;
      border-color: rgba(@gray-4, 0.3);
      background-color: @search-bg;

      &:hover,
      &:focus {
        background-color: @search-bg;
        border-color: @gray-4;
        box-shadow: none;
      }

      &.ant-input-affix-wrapper-focused {
        border-color: @gray-4;
        box-shadow: none;

        .ant-input-prefix {
          display: none;
        }
      }
    }

    .ant-input-prefix {
      font-size: 14px;
      color: @text-color;
      margin: 0 14px 0 0;
    }

    .ant-input-suffix {
      cursor: pointer;

      &:hover {
        > span {
          &:before {
            color: @text-color;
          }
        }
      }

      > span {
        &:before {
          content: '\e90e';
          font-family: 'icon-font';
          display: block;
          font-size: 20px;
          color: @gray-5;
        }

        svg {
          display: none;
        }
      }
    }

    .ant-input {
      background-color: transparent;
      font-size: 12px;
      color: @text-color;

      &::placeholder {
        color: @text-color;
        opacity: 1;
      }
    }
  }

  &.dark {
    :global {
      .ant-input-affix-wrapper,
      .ant-input {
        background-color: transparent;
      }

      .ant-input {
        color: @white;
        &::placeholder {
          color: @white;
        }
      }
    }
  }
}

.title {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}

.filter {
  :global {
    .ant-dropdown-trigger {
      border: 0;
    }

    .ant-dropdown-open {
      box-shadow: none;
    }
  }
}

.tableBox {
  overflow: auto;
  position: relative;
  height: 400px;

  &.minHeight {
    height: auto;
  }
}

.headerDrop {
  :global {
    .ant-select {
      &:not(.ant-select-customize-input) {
        .ant-select-selector {
          border: none;
          padding: 0 20px 0 0;
          box-shadow: none !important;
        }
      }

      .ant-select-arrow {
        margin-top: -12px;
        color: @black;

        &:before {
          content: '\e957';
          font-size: 14px;
        }
      }
    }

    .ant-select-single {
      &.ant-select-show-arrow {
        .ant-select-selection-item {
          padding-right: 24px;
        }
      }

      &.ant-select-open {
        .ant-select-selection-item {
          color: @black-2;
        }
      }
    }
  }
}

.headerDropDark {
  :global {
    .ant-select {
      .ant-select-arrow {
        color: @white;
      }
    }

    .ant-select-single {
      &.ant-select-open {
        .ant-select-selection-item {
          color: @white;
          border: 0;
        }
      }
    }
  }
}

.dateItem {
  display: flex;
  gap: 10px;
  cursor: pointer;
}

.analytics {
  white-space: nowrap;
  max-width: 210px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tableCell {
  text-align: left;
  padding: 10px 20px;

  &:first-child {
    padding: 10px 20px 10px 40px;
  }
}

.tableHead {
  padding: 10px 20px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  text-transform: uppercase;
  text-align: left;
  position: sticky;
  top: 0;
  z-index: 10;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: @gray-1;
    z-index: -2;
  }

  &:first-child {
    padding: 10px 20px 10px 40px;
  }
}

.status {
  text-align: center;
  padding-right: 44px;
}

.sent {
  color: @black-2;
}

.pending {
  color: @yellow-3;
}

.active {
  color: @green;
}

.rejected {
  color: @red;
}

.table {
  width: 100%;
  font-size: 14px;
  line-height: 20px;

  tbody {
    tr {
      &:hover {
        cursor: pointer;
        background: rgba(@yellow-3, 0.1);
      }
    }
  }
}

.inverse {
  background: #1e2126;

  .tableHead {
    &::before {
      background: #23262b;
    }
  }

  .tableRow {
    border-bottom: 1px solid rgba(147, 149, 151, 0.3);

    &:last-child {
      border-bottom: 0;
    }
  }
}

.bold {
  font-weight: 700;
}

.active {
  color: @green;
}

.suspended {
  color: @red;
}

.centeredCell {
  text-align: center;
  justify-content: center;
}

.info {
  .image {
    margin: 70px auto 10px;
    width: 140px;
    height: 140px;
  }

  :global(.emptyBox) {
    padding: 0 0 24px 0;
  }

  :global(.emptyText) {
    margin: 0;
  }
}

.date {
  white-space: nowrap;
}

@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;