@import 'variables';

@font-face {
  font-family: '@{icomoon-font-family}';
  src: url('@{icomoon-font-path}/@{icomoon-font-family}.ttf?974ist') format('truetype'),
  url('@{icomoon-font-path}/@{icomoon-font-family}.woff?974ist') format('woff'),
  url('@{icomoon-font-path}/@{icomoon-font-family}.svg?974ist#@{icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '@{icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-burger {
  &:before {
    content: @icon-burger; 
  }
}

.icon-transaction {
  &:before {
    content: @icon-transaction; 
  }
}

.icon-more-square {
  &:before {
    content: @icon-more-square; 
  }
}

.icon-history {
  &:before {
    content: @icon-history; 
  }
}

.icon-users {
  &:before {
    content: @icon-users; 
  }
}

.icon-arrow-circle-right {
  &:before {
    content: @icon-arrow-circle-right; 
  }
}

.icon-arrow-circle-left {
  &:before {
    content: @icon-arrow-circle-left; 
  }
}

.icon-stage {
  &:before {
    content: @icon-stage; 
  }
}

.icon-tracker {
  &:before {
    content: @icon-tracker; 
  }
}

.icon-graph {
  &:before {
    content: @icon-graph; 
  }
}

.icon-work {
  &:before {
    content: @icon-work; 
  }
}

.icon-load {
  &:before {
    content: @icon-load; 
  }
}

.icon-bell {
  &:before {
    content: @icon-bell; 
  }
}

.icon-headphones {
  &:before {
    content: @icon-headphones;
  }
}

.icon-newspaper {
  &:before {
    content: @icon-newspaper;
  }
}

.icon-server-network {
  &:before {
    content: @icon-server-network;
  }
}

.icon-eye {
  &:before {
    content: @icon-eye;
  }
}

.icon-not-available-eye {
  &:before {
    content: @icon-not-available-eye;
  }
}

.icon-meeting-board {
  &:before {
    content: @icon-meeting-board;
  }
}

.icon-download-file {
  &:before {
    content: @icon-download-file;
  }
}

.icon-pencil {
  &:before {
    content: @icon-pencil;
  }
}

.icon-twitter {
  &:before {
    content: @icon-twitter;
  }
}

.icon-youtube {
  &:before {
    content: @icon-youtube;
  }
}

.icon-facebook {
  &:before {
    content: @icon-facebook;
  }
}

.icon-instagram {
  &:before {
    content: @icon-instagram;
  }
}

.icon-linkedin {
  &:before {
    content: @icon-linkedin;
  }
}

.icon-wrench {
  &:before {
    content: @icon-wrench;
  }
}

.icon-x-add {
  &:before {
    content: @icon-x-add;
  }
}

.icon-sitemap {
  &:before {
    content: @icon-sitemap;
  }
}

.icon-file-contract-dollar {
  &:before {
    content: @icon-file-contract-dollar;
  }
}

.icon-cloud-block {
  &:before {
    content: @icon-cloud-block;
  }
}

.icon-chart-down {
  &:before {
    content: @icon-chart-down;
  }
}

.icon-caret-down-solid {
  &:before {
    content: @icon-caret-down-solid;
  }
}

.icon-integrations {
  &:before {
    content: @icon-integrations;
  }
}

.icon-server {
  &:before {
    content: @icon-server;
  }
}

.icon-account-settings {
  &:before {
    content: @icon-account-settings;
  }
}

.icon-exit {
  &:before {
    content: @icon-exit;
  }
}

.icon-deleted-folders {
  &:before {
    content: @icon-deleted-folders;
  }
}

.icon-archived {
  &:before {
    content: @icon-archived;
  }
}

.icon-bill {
  &:before {
    content: @icon-bill;
  }
}

.icon-user {
  &:before {
    content: @icon-user;
  }
}

.icon-resend {
  &:before {
    content: @icon-resend;
  }
}

.icon-question-circle {
  &:before {
    content: @icon-question-circle;
  }
}

.icon-camera {
  &:before {
    content: @icon-camera;
  }
}

.icon-export {
  &:before {
    content: @icon-export;
  }
}

.icon-import {
  &:before {
    content: @icon-import;
  }
}

.icon-triangle {
  &:before {
    content: @icon-triangle;
  }
}

.icon-close-rounded {
  &:before {
    content: @icon-close-rounded;
  }
}

.icon-clip {
  &:before {
    content: @icon-clip;
  }
}

.icon-whatsapp {
  &:before {
    content: @icon-whatsapp;
  }
}

.icon-sms {
  &:before {
    content: @icon-sms;
  }
}

.icon-email {
  &:before {
    content: @icon-email;
  }
}

.icon-angle-right-light {
  &:before {
    content: @icon-angle-right-light;
  }
}

.icon-trash-can {
  &:before {
    content: @icon-trash-can;
  }
}

.icon-unarchived {
  &:before {
    content: @icon-unarchived;
  }
}

.icon-arrows-with-plus {
  &:before {
    content: @icon-arrows-with-plus;
  }
}

.icon-square-plus {
  &:before {
    content: @icon-square-plus;
  }
}

.icon-arrows {
  &:before {
    content: @icon-arrows;
  }
}

.icon-sing-up {
  &:before {
    content: @icon-sing-up;
  }
}

.icon-blog {
  &:before {
    content: @icon-blog;
  }
}

.icon-login {
  &:before {
    content: @icon-login;
  }
}

.icon-american-express {
  &:before {
    content: @icon-american-express;
  }
}

.icon-maestro {
  &:before {
    content: @icon-maestro;
  }
}

.icon-paypal {
  &:before {
    content: @icon-paypal;
  }
}

.icon-visa {
  &:before {
    content: @icon-visa;
  }
}

.icon-arrow-down {
  &:before {
    content: @icon-arrow-down;
  }
}

.icon-arrow-up {
  &:before {
    content: @icon-arrow-up;
  }
}

.icon-calling {
  &:before {
    content: @icon-calling;
  }
}

.icon-send {
  &:before {
    content: @icon-send;
  }
}

.icon-delete {
  &:before {
    content: @icon-delete;
  }
}

.icon-check-single {
  &:before {
    content: @icon-check-single;
  }
}

.icon-lock {
  &:before {
    content: @icon-lock;
  }
}

.icon-password {
  &:before {
    content: @icon-password;
  }
}

.icon-Profile {
  &:before {
    content: @icon-Profile;
  }
}

.icon-wallet {
  &:before {
    content: @icon-wallet;
  }
}

.icon-wallet-outline {
  &:before {
    content: @icon-wallet-outline;
  }
}

.icon-move {
  &:before {
    content: @icon-move;
  }
}

.icon-dots {
  &:before {
    content: @icon-dots;
  }
}

.icon-check-filled {
  &:before {
    content: @icon-check-filled;
  }
}

.icon-paper-minus {
  &:before {
    content: @icon-paper-minus;
  }
}

.icon-paper-plus {
  &:before {
    content: @icon-paper-plus;
  }
}

.icon-hide {
  &:before {
    content: @icon-hide;
  }
}

.icon-notification {
  &:before {
    content: @icon-notification;
  }
}

.icon-call {
  &:before {
    content: @icon-call;
  }
}

.icon-angle-top {
  &:before {
    content: @icon-angle-top;
  }
}

.icon-check {
  &:before {
    content: @icon-check;
  }
}

.icon-danger {
  &:before {
    content: @icon-danger;
  }
}

.icon-notify {
  &:before {
    content: @icon-notify;
  }
}

.icon-undo {
  &:before {
    content: @icon-undo;
  }
}

.icon-info-circle {
  &:before {
    content: @icon-info-circle;
  }
}

.icon-template {
  &:before {
    content: @icon-template;
  }
}

.icon-info {
  &:before {
    content: @icon-info;
  }
}

.icon-arrow-left {
  &:before {
    content: @icon-arrow-left;
  }
}

.icon-message {
  &:before {
    content: @icon-message;
  }
}

.icon-pin {
  &:before {
    content: @icon-pin;
  }
}

.icon-smartphone {
  &:before {
    content: @icon-smartphone;
  }
}

.icon-schedule {
  &:before {
    content: @icon-schedule;
  }
}

.icon-lightbulb {
  &:before {
    content: @icon-lightbulb;
  }
}

.icon-question {
  &:before {
    content: @icon-question;
  }
}

.icon-calendar {
  &:before {
    content: @icon-calendar;
  }
}

.icon-home {
  &:before {
    content: @icon-home;
  }
}

.icon-checkbox {
  &:before {
    content: @icon-checkbox;
  }
}

.icon-archive {
  &:before {
    content: @icon-archive;
  }
}

.icon-angle-down {
  &:before {
    content: @icon-angle-down;
  }
}

.icon-arrow-right {
  &:before {
    content: @icon-arrow-right;
  }
}

.icon-profile {
  &:before {
    content: @icon-profile;
  }
}

.icon-star {
  &:before {
    content: @icon-star;
  }
}

.icon-close-square {
  &:before {
    content: @icon-close-square;
  }
}

.icon-share-network {
  &:before {
    content: @icon-share-network;
  }
}

.icon-share {
  &:before {
    content: @icon-share;
  }
}

.icon-angle-bottom {
  &:before {
    content: @icon-angle-bottom;
  }
}

.icon-angle-left {
  &:before {
    content: @icon-angle-left;
  }
}

.icon-angle-right {
  &:before {
    content: @icon-angle-right;
  }
}

.icon-close {
  &:before {
    content: @icon-close;
  }
}

.icon-plus {
  &:before {
    content: @icon-plus;
  }
}

.icon-copy {
  &:before {
    content: @icon-copy;
  }
}

.icon-edit {
  &:before {
    content: @icon-edit;
  }
}

.icon-programming {
  &:before {
    content: @icon-programming;
  }
}

.icon-search {
  &:before {
    content: @icon-search;
  }
}

.icon-url {
  &:before {
    content: @icon-url;
  }
}
