@import 'src/styles/base/_variables.less';

body {
  .ant-divider {
    &.ant-divider-horizontal {
      margin: 20px 0;

      &.light-divider {
        border-color: @white;
      }

      &.lighten-divider {
        border-color: @gray-3;
      }

      &.dark-divider {
        border-color: @gray-7;
      }

      &.mt-0 {
        margin-top: 0;
      }
    }
  }
}
