@import 'src/styles/base/_variables.less';

.narrow {
  background-color: @gray-5;
  color: @white;
  text-align: center;
  padding-right: 20px;
  font-size: 14px;
  font-weight: 600;
  line-height: 25px;
}

.narrowLink {
  color: @white !important;
  padding-left: 6px;
  text-decoration: underline !important;

  &:hover {
    text-decoration: none !important;
  }
}

.header {
  &:global(.ant-layout-header) {
    padding: 0;
    background-color: @white;
    position: relative;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
    line-height: normal;
    display: flex;
    justify-content: space-between;
    z-index: 5;
    height: 60px;

    @media (min-width: @breakpoint-lg) {
      height: 80px;
    }
  }

  &.headerDarken {
    &:global(.ant-layout-header) {
      background-color: @white-2;
    }
  }

  &.headerDarkMode {
    background-color: @black-2;
    border-bottom: 1px solid rgba(@gray-4, 0.3);
  }
}

.headerLogoBox {
  background-color: @black-2;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 102px;
  cursor: pointer;

  @media (min-width: @breakpoint-lg) {
    width: 136px;
  }

  &.headerLogoBoxDark {
    background-color: @black-2;
  }
}

.headerLogo {
  width: 80px;

  @media (min-width: @breakpoint-lg) {
    width: 108px;
  }
}

.customLogo {
  height: 60px;
  width: 60px;

  @media (min-width: @breakpoint-lg) {
    height: 80px;
    width: 80px;
  }
}

.leftBox {
  margin-right: auto;
  padding-left: 20px;
  align-self: center;
}

.rightBox {
  align-self: center;
  padding-right: 20px;

  @media (min-width: @breakpoint-lg) {
    padding-right: 40px;
  }
}

.headerProfile {
  display: flex;
  align-items: center;

  &Notify {
    padding-right: 15px;
    margin-right: 20px;
    border-right: 1px solid @gray-3;
  }
}

.headerProfileImg {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: 1px solid @white;
}

.profileDrop {
  cursor: pointer;
  font-size: 18px;
  text-transform: uppercase;

  &Menu {
    :global {
      .ant-dropdown-menu {
        min-width: 230px;
      }
    }
  }
}

.headerMenu {
  display: flex;
  margin: 0 -8px;

  @media (min-width: @breakpoint-md) {
    margin: 0 -20px;
  }

  li {
    padding: 0 8px;

    @media (min-width: @breakpoint-md) {
      padding: 0 20px;
    }

    > * {
      text-transform: uppercase;
      color: @black-2;
      font-weight: 500;
      cursor: pointer;
      font-size: 10px;

      &:hover,
      &.activeLink {
        font-weight: 700;
      }

      @media (min-width: @breakpoint-md) {
        font-size: 14px;
      }

      span::before {
        display: block;
        content: attr(data-text);
        font-weight: 700;
        height: 0;
        overflow: hidden;
        visibility: hidden;
      }
    }

    .no-auth-header-item:hover {
      color: @black-2;
    }
  }
}

.navList {
  display: flex;
  text-transform: uppercase;
  margin: 0 auto 0 10px;
  margin-right: auto;

  @media (min-width: @breakpoint-md) {
    margin-left: 20px;
  }

  li {
    padding: 0 12px;

    @media (min-width: @breakpoint-md) {
      padding: 0 20px;
    }

    > * {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      font-weight: 500;
      cursor: pointer;

      @media (min-width: @breakpoint-md) {
        font-size: 14px;
      }

      &:hover {
        font-weight: 700;
      }

      span::before {
        display: block;
        content: attr(data-text);
        font-weight: 700;
        height: 0;
        overflow: hidden;
        visibility: hidden;
      }
    }
  }


  i {
    @media (min-width: @breakpoint-md) {
      display: none;
    }
  }

  span {
    display: none;

    @media (min-width: @breakpoint-md) {
      display: block;
    }
  }

  .activeLink {
    font-weight: 700;
  }
}

.darkMode {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .switch {
    :global {
      .ant-switch {
        width: 36px;
      }

      .ant-switch-handle {
        top: 2px;
        left: 2px;
      }
    }
  }
}


.menuBox {
  display: flex;
  align-items: center;
  gap: 10px;
}

.menuImg {
  flex-shrink: 0;
  width: 18px;
  height: 18px;

  > img {
    display: block;
    max-width: 100%;
  }
}


.burger {
  display: flex;
  align-items: center;
  justify-content: center;

  :global {
    .ant-btn {

      &:not(.ant-btn-link) {
        color: #242424;
        font-size: 20px;
        border: 0;
        box-shadow: none;
        background-color: transparent;

        &:hover {
          box-shadow: none;
          opacity: 1;
        }

        &:not(.ant-btn-circle) {
          min-width: 24px;
        }
      }

      &:not(:disabled) {
        &:hover {
          outline: 0;
        }
      }
    }
  }
}

.weglot {
  width: 100%;
  height: 44px;
  flex-shrink: 0;
  background-color: @white;
}

@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;