@import 'src/styles/base/_variables.less';

.wrapper {
  width: 100%;
  height: 400px;
  border: 1px solid rgba(@gray-4, 0.3);
  border-radius: 8px;
  overflow: auto;
  position: relative;
}

.table {
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  text-align: center;

  th {
    padding: 10px 20px;
    width: 20%;
    font-weight: 400;
    text-transform: uppercase;
    background-color: @white;
    position: sticky;
    top: 0;
    z-index: 10;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: rgba(@gray-4, 0.04);
      z-index: -2;
    }
  }

  td {
    width: 20%;
    padding: 10px 20px;
  }

  tbody {
    tr {
      &:hover {
        cursor: pointer;
        background: rgba(@yellow-3, 0.1);
      }
    }
  }
}

@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;