@import 'src/styles/base/_variables.less';

.searchGrid {
  width: 100%;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: auto minmax(0, 1fr);
}

.searchWrapp {
  background-color: @gray-5;
  text-align: center;
  padding: 32px 10px 0;

  @media (min-width: @breakpoint-lg) {
    background-image: url('../../../src/assets/img/questions.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 96% center;
  }

  :global {
    .h1 {
      color: @white;
      margin-bottom: 37px;
      display: none;

      @media (min-width: @breakpoint-lg) {
        display: block;
      }
    }
  }
}

.searchBox {
  display: flex;
  padding: 8px 20px;
  position: relative;
  z-index: 1;
  max-width: 620px;
  margin: 0 auto -30px;
  background-color: @white;
  border-radius: 200px;
  border: 2px solid @gray-3;
  z-index: 4;

  :global {
    .ant-input-affix-wrapper {
      border: 0;
      background-color: transparent;
      box-shadow: none;
      flex-grow: 1;
    }

    .ant-input {
      background-color: transparent;
      color: @text-color-darken;
    }

    .ant-btn {
      flex-shrink: 0;
    }

    .ant-input-prefix {
      color: @text-color;
      font-size: 24px;
      margin-right: 10px;
    }
  }
}

.searchClear {
  padding: 0 20px 0 10px;
  flex-shrink: 0;
  border-right: 1px solid @gray-3;
  font-size: 24px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.mobileTitle {
  position: relative;
  font-size: 34px;
  text-transform: uppercase;
  color: @white;
  text-align: center;
  letter-spacing: 0.075px;
  text-shadow: 0px 0.6px 0.6px @black;
  font-family: @font-second;
  margin: 0 -10px 45px;

  @media (min-width: @breakpoint-lg) {
    display: none;
  }

  span {
    color: @primary-color;
  }
}

.mobileTitleMirror {
  transform: scaleY(-1);
  backface-visibility: visible;
  z-index: 1;
  position: absolute;
  bottom: -27px;
  text-shadow: none;
  background: -webkit-linear-gradient(
    rgba(90, 126, 242, 0) 30%,
    rgba(@white, 0.3)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(0, 0, 0, 0);
  width: 100%;
  pointer-events: none;
}

.noFoundWrapp {
  flex-grow: 1;
  background-color: @white;
  padding: 10px 20px;
}

.noFound {
  max-width: 620px;
  margin: 0 auto;
  text-transform: uppercase;
  text-align: center;
  color: @text-color-darken;
  padding-top: 90px;

  @media (min-width: @breakpoint-lg) {
    padding-top: 10%;
  }
}

.title {
  font-size: 24px;
  line-height: 20px;
  position: relative;
  padding-bottom: 20px;
  font-weight: 700;
  max-width: 310px;
  margin: 0 auto;

  @media (min-width: @breakpoint-md) {
    max-width: none;
    padding-bottom: 50px;
    font-size: 36px;
    line-height: 40px;
  }
}

.capture {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-size: 14px;

  @media (min-width: @breakpoint-md) {
    margin-bottom: 40px;
    font-size: 24px;
    line-height: 24px;
    font-weight: 700;
  }

  strong {
    flex-shrink: 0;
    padding: 0 20px;
  }

  span {
    height: 3px;
    background-color: @black;
    border-radius: 20px;
    flex-grow: 1;
    position: relative;

    &:first-child {
      @media (min-width: @breakpoint-md) {
        margin-right: 15px;
      }

      &:before {
        right: -15px;
      }
    }

    &:last-child {
      @media (min-width: @breakpoint-md) {
        margin-left: 15px;
      }

      &:before {
        left: -15px;
      }
    }

    &:before {
      @media (min-width: @breakpoint-md) {
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: @gray-5;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

.subtitle {
  color: @text-color;
  position: relative;
  padding-top: 10px;
  letter-spacing: 0.73px;
  font-size: 13px;

  @media (min-width: @breakpoint-md) {
    font-size: 24px;
    letter-spacing: 1.39px;
    padding-top: 20px;
  }

  &:after {
    content: '';
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    height: 3px;
    background-color: @black;
    border-radius: 20px;
  }
}

.detailsHead {
  display: flex;
  color: @text-color-darken;
  font-size: 24px;
  font-weight: 700;
  padding-top: 30px;
}

.detailsHeadName {
  width: calc(100% - 50px);
  padding-right: 15px;
  align-self: center;
  word-break: break-word;
}

@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;