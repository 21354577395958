@import 'src/styles/base/_variables.less';

.position {
  position: fixed !important;
  height: 100%;
  top: 60px;
  bottom: 0;
  left: -1000px;
  z-index: 9999;
}

.position.isOpen {
  left: 0;
  top: 60px;
  z-index: 9999;
}


.sidebar {
  &Img {
    padding: 4px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    > img {
      width: 90px;
    }

    @media (min-width: @breakpoint-lg) {
      height: 80px;

      > img {
        width: 108px;
      }
    }

  }
}

@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;