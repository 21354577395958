@import 'src/styles/base/_variables.less';

.tableWrap {
  height: calc(100vh - 80px);
  max-width: 940px;
  margin: 0 auto;
}

.table {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  border-collapse: collapse;

  &Head {
    background-color: @white;
    width: 100%;
    position: sticky;
    top: 0;

    .tableCell {
      display: flex;
      width: 185px;
      height: 176px;
      border: 1px solid rgba(@gray-4, 0.3);
      border-bottom: 0;

      &.side {
        width: 190px;
      }

      &.label {
        position: relative;
        border: 2px solid @black-2;
        border-bottom: 0;

        &:before {
          content: 'best value';
          position: absolute;
          top: -22px;
          left: -2px;
          width: 185px;
          height: 20px;
          background: @black-2;
          color: @white;
          font-weight: 700;
          font-size: 12px;
          line-height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px 8px 0px 0px;
          text-transform: uppercase;
        }
      }
    }

    .tableRow {
      &.fake {
        height: 20px;
      }
    }
  }

  &Body {
    > div {
      &:nth-child(even) {
        background: rgba(@yellow-3, 0.1);
      }

      &:last-child {
        .tableCell {
          border-bottom: 1px solid rgba(@gray-4, 0.3);

          &.label {
            border-bottom: 2px solid @black-2;
          }
        }
      }
    }

    .tableRow {
      &.fake {
        height: 40px;
        background: #e7e7e7;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        text-transform: uppercase;
      }
    }

    .tableCell {
      width: 185px;
      height: 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      font-size: 14px;
      line-height: 20px;
      padding: 2px 10px;
      border-left: 1px solid rgba(@gray-4, 0.3);
      border-right: 1px solid rgba(@gray-4, 0.3);

      > i {
        font-size: 16px;
      }

      &.side {
        width: 190px;
        text-align: left;
        line-height: 12px;
        font-weight: 700;

        > a {
          color: @black-2;
          text-decoration: underline;
        }
      }

      &.label {
        border-left: 2px solid @black-2;
        border-right: 2px solid @black-2;
      }
    }
  }

  &Row {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

.tableDark {
  .tableHead {
    background-color: @black-2;

    .tableCell {
      &.label {
        border-color: rgba(@white, 0.5);

        &:before {
          color: @black-2;
          background-color: rgba(@white, 0.5);
        }
      }
    }
  }

  .head {
    background-color: @black-2;
  }

  .tableBody {
    .tableRow {
      &.fake {
        background: #23262b;
      }
    }

    .tableCell {
      &.label {
        border-color: rgba(@white, 0.5);
      }

      &.side {
        > a {
          color: @white;
        }
      }
    }
  }
}

.head {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px 10px 20px;
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  text-transform: uppercase;
  background-color: @white;

  &Price {
    > span {
      font-size: 14px;
      font-weight: 400;
      text-transform: capitalize;
    }
  }

  &Btn {
    :global {
      .ant-btn:not(.ant-btn-link).ant-btn-lg {
        &:hover {
          border-color: @yellow-3;
          background-color: @yellow-3;
          opacity: 1;
          font-weight: 700;
          color: @black-2;
          transition: none;
        }

        &:disabled {
          &:hover {
            background-color: @gray-5;
            border-color: @gray-5;
            opacity: 0.9;
            color: @white;
          }
        }
      }
    }
  }

  &.side {
    text-align: left;
    font-size: 14px;
    padding: 30px 20px 20px;
  }

  &.current {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(@yellow-3, 0.1);
      border: 2px solid @yellow-3;
    }
  }
}

.noSubscriptionBlock {
  height: 50px;
}

@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;