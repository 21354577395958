@import '../../../../../styles/base/_variables.less';

.modal {
  &:global(.modal-root.ant-modal) {
    @media (min-width: @breakpoint-lg) {
      max-width: 820px;
      margin: 0 auto;
    }
  }

  p {
    line-height: 20px;
    font-weight: 400;
  }
}

.card {
  :global {
    .ant-radio-button-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      min-height: 80px;
      padding: 20px;

      &:first-child {
        border: 1px solid rgba(@gray-4, 0.3);
        border-radius: 4px;

        &:hover {
          background: rgba(@gray-4, 0.04);
          border: 1px solid @gray-4;
        }
      }

      &.ant-radio-button-wrapper-checked {
        &:not(.ant-radio-button-wrapper-disabled) {
          &:first-child {
            border: 1px solid @gray-4;
            box-shadow: none;
          }
        }
      }
    }
  }

  &Box {
    display: flex;
    align-items: center;
    gap: 20px;
    font-size: 14px;
    line-height: 20px;
  }

  &Icon {
    width: 40px;
    height: 40px;
    border: 1px solid rgba(@gray-4, 0.3);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: @gray-4;
  }
}

.titleBilling {
  color: @gray-4;
  margin-bottom: 20px;
}

.accent {
  color: @yellow;
  font-weight: 700;
  padding-left: 4px;
}
@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;