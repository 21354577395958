@import 'src/styles/base/_variables.less';

.baseStep {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000000;

  &.project {
    @media (min-width: @breakpoint-xxxl) {
      left: auto;
      right: 6%;
    }
  }

  &.templates {
    @media (min-width: @breakpoint-xxxl) {
      left: auto;
      transform: none;
      top: 16%;
      left: 12%;
    }
  }

  &.analytics {
    @media (min-width: @breakpoint-xxxl) {
      left: auto;
      transform: none;
      top: 16%;
      left: 12%;
    }
  }

  &.settings {
    @media (min-width: @breakpoint-xxxl) {
      left: auto;
      transform: none;
      top: 25%;
      right: 3%;
    }
  }

  &.filter {
    top: 30%;

    @media (min-width: @breakpoint-md) {
      top: 50%;
    }

    @media (min-width: @breakpoint-xxxl) {
      left: auto;
      transform: none;
      top: 45%;
      right: 20%;
    }
  }
}

.baseStepModal {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 340px;
  height: 176px;
  padding: 20px;
  background-color: @white;
  border-radius: 8px;

  @media (min-width: @breakpoint-md) {
    width: 538px;
    padding: 30px;
  }

  h3 {
    color: @black;
  }

  &.darkMode {
    background-color: @black-2;
    color: @gray-4;
    box-shadow: 0px 3px 8px rgba(147, 149, 151, 0.3);

    h3 {
      color: @white;
    }
  }
}

.baseStepTitle {
  font-weight: 700;
  margin-bottom: 10px;
}

.baseStepDesc {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.baseStepBtnBox {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: auto -5px 0;

  > div {
    padding: 0 5px;
  }
}

.baseStepArrow {
  display: none;

  @media (min-width: @breakpoint-xxxl) {
    display: block;
    position: absolute;
    z-index: 10000000;
    height: 120px;
    pointer-events: none;

    &.project {
      top: -70%;
      right: 10%;
      transform: none;
      width: 80px;
    }

    &.analytics {
      top: -70%;
      right: 36%;
      width: 84px;
    }

    &.templates {
      top: -70%;
      right: 56%;
      width: 90px;
    }

    &.settings {
      top: -82%;
      right: -4%;
      width: 60px;
    }

    &.filter {
      top: -70%;
      right: 16%;
      width: 86px;
    }
  }
}

@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;