@import 'src/styles/base/_variables.less';

.chart {
  padding: 10px;
  border-radius: 8px;
  border: 1px solid rgba(@gray-4, 0.3);

  @media (min-width: @breakpoint-lg) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    min-height: 240px;
  }

  &Box {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 30px 1fr;
    grid-template-areas: "title title"
      "trackers info";
    gap: 18px;

    @media (min-width: @breakpoint-lg) {
      display: flex;
      flex-direction: column;
    }
  }

  h1 {
    font-size: 24px;
    line-height: 24px;
    font-weight: 700;

    @media (min-width: @breakpoint-lg) {
      max-width: 230px;
    }
  }

  h2 {
    font-size: 38px;
    line-height: 40px;
  }

  p {
    color: @gray-4;
    line-height: 20px;
    margin-bottom: 0;
  }
}


.item {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  &Square {
    width: 16px;
    height: 16px;
    border-radius: 4px;
  }
}

.pieChart {
  height: 110px;

  :global {
    .recharts-responsive-container {
      margin: 18px auto 0;
    }
  }

  @media (min-width: @breakpoint-lg) {
    height: 136px;

    :global {
      .recharts-responsive-container {
        margin: auto;
      }
    }
  }
}

.info {
  grid-area: info;
}

.title {
  grid-area: title;
}

.trackers {
  grid-area: trackers;
}
@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;