@import 'src/styles/base/_variables.less';

body {
  .ant-dropdown {
    .ant-dropdown-menu {
      box-shadow: 0px 3px 8px rgba(@black, 0.1);
      border-radius: 20px;
      padding: 0 20px;
      min-width: 230px;

      .ant-dropdown-menu-item {
        padding: 10px 0;
        border-bottom: 1px solid @gray-3;
        border-radius: 0;

        &:last-child {
          border-bottom: 0;
        }

        &:hover {
          background-color: transparent;
          font-weight: 700;
        }

        .icon {
          padding-right: 10px;
          font-size: 18px;
          vertical-align: text-bottom;
        }
      }
    }
  }
}
