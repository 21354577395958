@import 'src/styles/base/_variables.less';

.card {
  padding: 10px 20px;
  line-height: 20px;

  > span {
    font-weight: 700;
  }

  :global {
    .ant-collapse {
      background-color: transparent;
      padding: 0;
      border: none;
      box-shadow: none;

      > .ant-collapse-item {
        border: none;

        > .ant-collapse-header {
          padding: 0;

          flex-direction: row-reverse;
          justify-content: space-between;
        }

        &.ant-collapse-item-active {
          > .ant-collapse-header {
            padding: 0 0 10px;
          }
        }
      }
    }

    .ant-collapse-content {
      border: none;
      margin: 0 -20px -10px;
      background-color: transparent;

      > .ant-collapse-content-box {
        padding: 10px 80px;
      }
    }
  }
}

.box {
  display: flex;
  align-items: center;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 50%;
    transform: translate(-50%, 0%);
    background: @gray-4;
    height: 100%;
    width: 1px;
  }

  .left,
  .right {
    width: 50%;

    > div {
      display: flex;
      align-items: center;
      gap: 2px;

      > i {
        font-size: 16px;
        font-weight: 700;
        padding-right: 18px;
      }
    }
  }

  .right {
    padding-left: 94px;
  }
}

.bold {
  font-weight: 700;
}

.active {
  cursor: pointer;
  color: @yellow-3;
}

.disabled {
  color: @gray-4;
}

@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;