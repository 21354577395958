@import 'src/styles/base/_variables.less';

.unsubscribe {
  padding: 20px;
  margin: 0 auto;
  width: 100%;
  max-width: 940px;
  background-color: @white;
  border: 1px solid rgba(147, 149, 151, 0.3);
  border-radius: 8px;

  @media (min-width: @breakpoint-md) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 226px;
  }

  @media (min-width: @breakpoint-lg) {
    padding: 50px 40px 50px 68px;
  }

  &Img {
    width: 80px;
    flex-shrink: 0;

    @media (min-width: @breakpoint-md) {
      width: 116px;
    }

    > img {
      display: block;
      width: 100%;
    }
  }

  &Box {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    @media (min-width: @breakpoint-md) {
      margin-bottom: 0;
    }
  }

  &Text {
    margin-left: 20px;

    @media (min-width: @breakpoint-lg) {
      max-width: 368px;
      margin-left: 68px;
    }
  }

  &Title {
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: @gray-5;
    margin-bottom: 10px;

    @media (min-width: @breakpoint-lg) {
      font-size: 24px;
    }
  }

  &Btn {
    .ant-btn {
      width: 100%;

      @media (min-width: @breakpoint-md) {
        width: auto;
      }
    }
  }

  &.successfully {
    text-align: center;
    flex-direction: column;
    padding: 20px;

    @media (min-width: @breakpoint-md) {
      padding: 40px;
      min-height: 134px;
    }

    .unsubscribeBox {
      justify-content: center;
      gap: 16px;
      margin-bottom: 20px;

      > i {
        font-size: 24px;
        color: @gray-5;
      }
    }

    .unsubscribeTitle {
      margin-bottom: 0;
    }
  }
}

@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;