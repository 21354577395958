@import 'src/styles/base/_variables.less';

.selectContainer {
  position: relative;
  min-width: 350px;
  padding-right: 40px;

  :global {
    .ant-select {
      width: 100%;

      &:not(.ant-select-customize-input) {
        &.ant-select-lg {
          .ant-select-selector {
            background-color: @gray-15;
            border-color: @gray-15 !important;
            border-radius: 20px;
            box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
            font-weight: 700;
            height: 50px;
            padding: 0 20px;
            z-index: 9999;

            .ant-select-selection-item {
              line-height: 48px;
            }
          }

          .ant-select-arrow {
            z-index: 9999;
            right: 15px;

            &:before {
              content: '\e940';
            }
          }
        }
      }

      &.ant-select-focused {
        &:not(.ant-select-disabled) {
          &:not(.ant-select-customize-input) {
            .ant-select-selector {
              border-color: @gray-15;
              box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
            }
          }
        }
      }

      &.ant-select-open {
        .ant-select-selection-item {
          color: @text-color-darken;
        }
      }
    }

    .ant-select-dropdown {
      border-radius: 20px;
      border: 0;
      box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
      top: 0px !important;
      padding: 50px 20px 0 20px;

      .ant-select-item {
        min-height: 40px;
        line-height: 28px;
        padding-left: 0;

        &.ant-select-item-option-active {
          background-color: transparent;

          &.ant-select-item-option-selected {
            background-color: transparent;
          }
        }
      }
    }
  }

  &.dark {
    :global {
      .ant-select {
        &:not(.ant-select-customize-input) {
          &.ant-select-lg {
            .ant-select-selector {
              background-color: #1e2126;
              border-color: transparent !important;
              box-shadow: none;
            }

            .ant-select-arrow {
              z-index: 9999;
              right: 15px;

              &:before {
                content: '\e940';
              }
            }
          }
        }

        &.ant-select-focused {
          &:not(.ant-select-disabled) {
            &:not(.ant-select-customize-input) {
              .ant-select-selector {
                border-color: transparent;
                box-shadow: none;
              }
            }
          }
        }

        &.ant-select-open {
          .ant-select-selection-item {
            color: @white;
          }
        }
      }
    }
  }
}

.selectMobileContainer {
  min-width: auto;
  width: 230px;
  padding-right: 0;
}

@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;