@import 'src/styles/base/_variables.less';

.invoice {
  width: 100%;
  border: 1px solid rgba(@gray-4, 0.3);
  border-radius: 8px;
  padding: 20px 20px 0;

  @media (min-width: @breakpoint-xl) {
    padding: 20px 40px 0;
  }
}

.box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  margin-bottom: 10px;
}

.label {
  display: inline-block;
  min-width: 80px;
  padding: 2px 10px;
  border-radius: 20px;
  border: 1px solid rgba(@gray-4, 0.3);
  color: @gray-4;
  font-size: 10px;
  line-height: 20px;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;

  &.failed {
    color: @red;
    background: linear-gradient(
      86.18deg,
      rgba(255, 0, 47, 0) 10.12%,
      rgba(255, 0, 47, 0.12) 83.78%
    );
    border-color: rgba(@red, 0.3);
  }

  &.success {
    color: @green;
    background: linear-gradient(
      87.68deg,
      rgba(0, 170, 99, 0) 3.89%,
      rgba(0, 170, 99, 0.17) 87.1%
    );
    border-color: rgba(@green, 0.3);
  }
}

.tableBox {
  overflow: auto;
  position: relative;
  height: 400px;
  margin: 0 -20px;

  @media (min-width: @breakpoint-xl) {
    margin: 0 -40px;
  }
}

.table {
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  &Head {
    text-align: center;
    padding: 12px 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    white-space: nowrap;
    text-transform: uppercase;
    background-color: @white;
    position: sticky;
    top: 0;
    z-index: 10;

    @media (min-width: @breakpoint-xl) {
      padding: 10px 20px 10px 40px;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: rgba(@gray-4, 0.04);
      z-index: -2;
    }
  }

  &Cell {
    text-align: center;
    padding: 20px;

    @media (min-width: @breakpoint-xl) {
      padding: 12px 20px 12px 40px;
    }

    > div {
      white-space: nowrap;
    }
  }

  &LeftAligned {
    text-align: start;
  }

  a {
    color: @blue-2;
  }
}

.tableDark {
  background: #1e2126;

  .tableHead {
    color: @gray-4;
    background: #23262b;
  }

  .tableRow {
    border-bottom: 1px solid rgba(147, 149, 151, 0.3);
  }
}

@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;