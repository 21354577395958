@import 'src/styles/base/_variables.less';

.alertMessage {
  display: flex;
  align-items: center;
}

.alertBox {
  flex-grow: 1;
  padding: 0 20px;
}

.alertIcon {
  font-size: 24px;
  flex-shrink: 0;
  color: @white;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}

.alertTitle {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 4px;
  font-weight: 500;
}

.alertText {
  font-size: 14px;
  word-break: break-word;
  line-height: 14px;
  color: @text-color;
}

.alertClose {
  color: @gray-5;
  font-size: 14px;
  cursor: pointer;
}

@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;