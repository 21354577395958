@import 'src/styles/base/_variables.less';

body {
  .ant-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &:not(.ant-btn-link) {
      background-color: @gray-3;
      border-color: @gray-3;
      color: inherit;
      font-size: 10px;
      box-shadow: 0px 3px 8px rgba(@black, 0.1);

      &:not(.ant-btn-circle) {
        min-width: 68px;
      }

      &.ant-btn-link {
        box-shadow: none;
      }

      &.ant-btn-text {
        font-weight: 600;
        box-shadow: none;
        background-color: transparent;
        border: 0;

        &:hover {
          box-shadow: none;
          color: inherit;
        }

        > i {
          font-size: 20px;
        }
      }

      &:hover {
        box-shadow: 0px 3px 8px rgba(@black, 0.3);
        opacity: 0.9;
        color: inherit;
        border-color: @gray-3;
      }

      &.ant-btn-lg {
        font-size: 14px;
        box-shadow: 0px 3px 8px rgba(@black, 0.1);

        &:not(.ant-btn-circle) {
          height: 50px;

          &:not(.ant-btn-block) {
            min-width: 160px;

            &.icon-btn {
              width: 50px;
              min-width: 0;
            }
          }
        }

        &:hover {
          opacity: 0.9;
          box-shadow: 0px 3px 8px rgba(@black, 0.3);
        }
      }

      &.ant-btn-primary {
        background-color: @primary-color;
        border-color: @primary-color;
        color: initial;

        &:hover {
          background-color: @primary-color;
          border-color: @primary-color;
          color: @gray-5;
        }
      }

      &.dark-btn {
        background-color: @gray-5;
        border-color: @gray-5;
        color: @white;
      }

      &.disabled-btn {
        .dark-btn;
        opacity: 0.7;
      }

      &.disabled-btn:hover {
        .disabled-btn;
      }

      &.disabled-btn-light {
        border-color: @gray-4;
        background-color: @gray-4;
        color: @white;
        opacity: 0.7;
      }

      &.disabled-btn-light:hover {
        .disabled-btn-light;
      }

      &.yellow-btn {
        background-color: @primary-color;
        border-color: @primary-color;
      }

      &.dark-btn-outline {
        border-color: @gray-4;
        background-color: transparent;
        box-shadow: none;
        font-weight: 600;
      }

      &.ant-btn-ghost {
        background-color: transparent;
        border: 1px solid rgba(@gray-4, 0.3);
        border-radius: 4px;
        box-shadow: none;
        height: 40px;
        min-width: 40px;
        padding: 6px;

        &:hover {
          border-color: @gray-4;
        }

        i {
          font-size: 20px;
        }
      }

      &.with-icon {
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-shadow: none;
        background-color: transparent;
        border: none;
        text-transform: uppercase;
        font-weight: 500;

        .icon {
          flex-shrink: 0;
          flex-grow: 0;
          color: inherit;

          &:first-child {
            margin-right: 10px;
          }

          &:last-child {
            margin-left: 10px;
          }
        }

        &:hover {
          box-shadow: none;
          color: @yellow-2;
        }
      }

      &.timer-btn {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 0;
        font-size: 14px;
        color: @yellow-3;
        box-shadow: none;
        background-color: transparent;
        border: none;

        &:disabled {
          color: rgba(@black, 0.25);
        }

        .ant-statistic-content {
          font-size: 14px;
          color: rgba(@black, 0.25);
        }

        .ant-statistic-content-prefix,
        .ant-statistic-content-suffix {
          margin: 0;
        }
      }

      &.start-btn {
        color: @white;
        background-color: #4EBB3C;
        border-color: #4EBB3C;
      }

      &.end-btn {
        color: @white;
        background-color: #FF6868;
        border-color: #FF6868;
      }
    }
  }
}
