@import '../../styles/base/_variables.less';

.pickerWrapper {
  display: flex;
  gap: 20px;
}

.pickerSider {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  :global {
    .ant-menu-vertical {
      border: 0;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;

      .ant-menu-item {
        width: 100%;
        padding: 0;
        border-radius: 0;
        margin-inline: 0;
        margin-block: 0;
        padding-inline: 0;
        border-bottom: 1px solid @gray-3;

        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }
    }

    .ant-menu-light {
      .ant-menu-item {
        &:hover {
          font-weight: 700;
          // color: @black-2;
        }
      }

      &.ant-menu-root {
        &.ant-menu-vertical {
          border-inline-end: 0;
        }
      }
    }

    .ant-menu {
      &:not(.ant-menu-horizontal) {
        .ant-menu-item-selected {
          background-color: transparent;
          color: inherit;
          font-weight: 700;
        }
      }
    }
  }
}

.pickerBox {
  display: flex;
  align-items: center;
  margin: 0 -5px;

  > div {
    width: 100%;
    padding: 0 5px;
  }
}

.pickerMain {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.picker {
  :global {
    .ant-picker-panel-container {
      display: none;
    }
  }
}

.pickerWrap {
  :global {
    .ant-picker-suffix {
      order: -1;
      margin: 0 10px 0 0;
    }
  }
}

.calendar {
  text-align: center;

  font-weight: 700;
  font-size: 14px;
  line-height: 20px;

  :global {
    .ant-picker-calendar {
      .ant-picker-panel {
        border: 0;
      }
    }

    .ant-picker-content {
      th {
        font-weight: 700;
      }
    }

    .ant-picker-cell {
      color: @gray-4;

      &.ant-picker-cell-in-view {
        color: inherit;
      }

      &:before {
        height: 20px;
      }
    }
  }
}

.today {
  background-color: @yellow-3;
  border-radius: 4px;
  height: 20px;
}

@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;