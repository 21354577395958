@import 'src/styles/base/_variables.less';

.businessSection {
  position: relative;
  background-color: @black-2;
  padding: 80px 0 120px;

  @media (min-width: @breakpoint-xl) {
    padding: 310px 0 336px;
  }
}

.pagination {
  top: 374px;
}

.card {
  position: relative;

  @media (min-width: @breakpoint-xl) {
    padding: 138px 52px 112px;
    max-width: 698px;
    height: 578px;
    border-radius: 8px;
    border: 1px solid @gray-4;
  }

  &Grid {
    position: absolute;
    background-image: url('../../../../../src/assets/img/landing/grid-mobile.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    top: 0;
    left: 0;
    right: 0;
    bottom: -75px;
    z-index: 0;

    @media (min-width: @breakpoint-sm) {
      transform: rotate(45deg);
    }

    @media (min-width: @breakpoint-sm) {
      right: auto;
      bottom: -70px;
      width: 100%;
      background-size: 100%;
      transform: rotate(0deg);
      background-image: url('../../../../../src/assets/img/landing/grid.svg');
    }

    @media (min-width: @breakpoint-xl) {
      right: auto;
      bottom: auto;
      width: 100%;
      height: 100%;
      background-size: 100%;
      transform: rotate(-32deg);
    }
  }

  &Title {
    position: relative;
    margin-bottom: 30px;
    padding-right: 20px;

    @media (min-width: @breakpoint-xl) {
      max-width: none;
      margin-bottom: 40px;
    }

    h2 {
      > div {
        color: @white;
      }
    }
  }

  &Content {
    @media (max-width: @breakpoint-xl - 1) {
      max-width: 700px;
      margin: 0 auto;
      border-radius: 4px;
      border: 1px solid @gray-4;
      padding: 40px 20px;
      position: relative;
    }
  }

  &Text {
    color: @gray-1;
    position: relative;
    max-width: 560px;
  }

  &Icon {
    background: radial-gradient(
      100% 100% at 0% 0%,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
    backdrop-filter: blur(21px);
    border: 2px solid @gray-13;
    width: 66px;
    height: 66px;
    font-size: 28px;
    bottom: -44px;
    left: 14px;

    @media (min-width: @breakpoint-xl) {
      bottom: -62px;
      left: -62px;
      width: 120px;
      height: 120px;
      font-size: 48px;
    }
  }

  &IconSecond {
    display: flex;
    background: radial-gradient(
      100% 100% at 0% 0%,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
    backdrop-filter: blur(21px);
    border: 2px solid @gray-13;
    width: 44px;
    height: 44px;
    font-size: 24px;
    top: -22px;
    right: 18px;

    @media (min-width: @breakpoint-xl) {
      top: -62px;
      right: -62px;
      width: 124px;
      height: 124px;
      font-size: 68px;
    }
  }
}

@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;