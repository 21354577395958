@import 'src/styles/base/_variables.less';

.modalTitle {
  :global {
    .ant-modal-header {
      .ant-modal-title {
        padding-right: 16px;
        text-transform: uppercase;
      }
    }
  }
}

.modal {
  padding: 20px 20px 2px 20px;
}

.box {
  display: flex;
  align-items: center;
  gap: 10px;

  > div {
    &:first-child {
      flex-grow: 1;
    }
  }

  &Btn {
    padding-top: 10px;

    :global {
      .ant-btn {
        height: 40px;
      }
    }
  }
}

.input {
  :global {
    .ant-input {
      height: 40px;
      border-color: #9395974D;
    }

    .ant-input::placeholder {
      color: #939597;
    }
  }
}

.wrap {
  color: @black-2;
  margin-bottom: 28px;

  > div {
    &:first-child {
      margin-bottom: 10px;
    }
  }
}

.search {
  :global {
    .ant-input {
      &::placeholder {
        color: @gray-4 !important;
      }
    }

    .ant-input-prefix {
      color: @gray-4 !important;
    }

    .ant-input-affix-wrapper {
      .ant-input {
        &.ant-input-lg {
          padding: 4px 10px 4px 10px;
        }
      }
    }
  }
}

.table {
  width: 100%;
  height: 220px;
  overflow-x: hidden;
  overflow-y: auto;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.button {
  :global {
    .ant-btn {
      &:not(.ant-btn-link) {
        &.ant-btn-ghost {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 24px;
          min-width: 24px;
          padding: 2px;

          i {
            font-size: 12px;
          }
        }
      }
    }
  }
}

@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;