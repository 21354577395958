@import 'src/styles/base/_variables.less';

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  margin-bottom: 22px;

  >* {
    &:first-child {
      text-transform: uppercase;
      font-weight: 700;

      i {
        font-weight: 700;
      }
    }
  }

  i {
    font-size: 16px;
  }


  .icon-angle-right-light {
    padding: 0 10px 0 5px;
    font-size: 8px;

    @media (min-width: @breakpoint-lg) {
      padding: 0 12px;
      font-size: 14px;
    }
  }


  a {
    color: @text-color-darken;
    line-height: 16px;

    &:hover {
      color: @text-color-darken;
    }
  }

  &.dark {
    a {
      color: @white;

      &:hover {
        color: @white;
      }
    }
  }
}
@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;