@import 'src/styles/base/_variables.less';

.modal {
  &:global(.modal-root.ant-modal) {
    @media (min-width: @breakpoint-lg) {
      max-width: 820px;
      margin: 0 auto;
    }
  }
}

.list {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;

  &Btn {
    color: @blue-2;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }

  &Card {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: @gray-4;

    &Item {
      margin-bottom: 8px;
    }

    span {
      font-weight: 700;
    }
  }

  &Title {
    margin-bottom: 10px;
  }

  &Block {
    margin-bottom: 20px;
  }
}

.card {
  padding: 20px;
  border: 1px dashed rgba(@gray-4, 0.3);
  border-radius: 4px;

  &Btn {
    display: inline-flex;
    align-items: center;
    color: @gray-4;

    i {
      border: 1px solid rgba(@gray-4, 0.3);
      border-radius: 4px;
      padding: 8px;
      font-size: 20px;
      color: @gray-4;
      cursor: pointer;

      &:hover {
        border-color: @gray-5;
        color: @gray-5;
      }
    }

    span {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      padding-left: 20px;
    }
  }
}

@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;