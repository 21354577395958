@import '../../../../styles/base/_variables.less';

.stretch {
  width: 100%;
  display: flex;
  flex: 1 1;
  border: 1px solid rgba(@gray-4, 0.3);
  border-radius: 8px;
}

.card {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;

  @media (min-width: @breakpoint-xl) {
    padding: 40px 20px;
  }

  &Avatar {
    margin: 0 auto 20px;
  }

  &Box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 -5px 16px;

    @media (min-width: @breakpoint-lg) {
      margin: 0 -5px 32px;
    }

    >div {
      padding: 0 5px;
    }
  }

  &Name {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    word-break: break-word;
  }

  &Title {
    color: @black;
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    text-transform: uppercase;
  }
}

.btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 90px;
  height: 30px;
  border: 1px solid rgba(@gray-4, 0.3);
  border-radius: 4px;
  font-size: 10px;
  line-height: 10px;

  &:hover {
    border-color: @gray-4;
  }
}

.label {
  display: flex;
  gap: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: @gray-5;
  margin-bottom: 10px;

  >i {
    color: @gray-4;
    font-size: 15px;
    cursor: pointer;
  }

  &.inverse {
    color: @white;

    >i {
      color: @white;
    }
  }
}

.input {
  :global {

    .ant-input,
    .ant-input-affix-wrapper {
      padding: 8px 10px;
      border-color: @gray-3;
      background-color: transparent;

      &:hover,
      &:focus {
        background-color: transparent;
        border-color: @gray-4;
        box-shadow: none;
      }
    }

    .ant-form-item-has-error {

      .ant-input,
      .ant-input-affix-wrapper {
        border-color: @red;
      }
    }

    .ant-input-disabled,
    .ant-input-affix-wrapper-disabled {
      color: rgba(@gray-4, 0.8);

      &:hover {
        border-color: @gray-3;
      }

      .ant-input-prefix {
        color: rgba(@gray-4, 0.8);
      }
    }

    .ant-select {
      &:not(.ant-select-disabled) {
        &:not(.ant-select-customize-input) {
          &:not(.ant-pagination-size-changer) {
            &:hover {
              .ant-select-selector {
                border-color: @gray-4;
                box-shadow: none;
              }
            }
          }
        }
      }

      .ant-select-selection-placeholder {
        font-family: "Seaford", sans-serif;
        font-size: 14px;
        color: rgba(@gray-4, 0.8);
        font-weight: 400;
      }
    }

    .ant-select-focused {
      &.ant-select {
        &:not(.ant-select-disabled) {
          &:not(.ant-select-customize-input) {
            &:not(.ant-pagination-size-changer) {
              .ant-select-selector {
                border-color: @gray-4;
                box-shadow: none;
              }
            }
          }
        }
      }
    }

    .ant-input-prefix {
      color: @black-2;
    }

    .ant-input-suffix {
      width: 90px;
      justify-content: flex-end;
      color: @gray-4;
      padding-left: 10px;
    }

    .ant-select-disabled {
      &.ant-select {
        &:not(.ant-select-customize-input) {
          .ant-select-selector {
            color: rgba(@gray-4, 0.8);
            font-size: 14px;
            padding: 0 10px;
            background-color: transparent;
          }
        }
      }
    }

    .ant-select-single {
      &.ant-select-lg {
        &:not(.ant-select-customize-input) {
          .ant-select-selector {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.summary {
  padding-top: 40px;

  &Title {
    font-weight: 700;
    margin-bottom: 20px;
  }

  &Subscriptions {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    @media (min-width: @breakpoint-lg) {
      flex-direction: row;
      justify-content: space-between;
      gap: 60px;
    }
  }

  &Btn {
    display: inline-block;
    margin-left: 8px;
    font-weight: 500;
    font-size: 14px;
    color: @blue-2;
    cursor: pointer;
  }

  :global {
    .ant-btn {
      width: 100%;

      @media (min-width: @breakpoint-lg) {
        width: auto;
      }
    }
  }
}

.phone {
  width: 100%;
  display: flex;
  align-items: center;

  >div:last-child {
    width: 100%;
  }

  :global {
    .ant-select {
      &.ant-select-in-form-item {
        width: 70px;
        flex-shrink: 0;
      }

      .ant-select-arrow {
        color: @black;
      }
    }

    .ant-select-single {
      &:not(.ant-select-customize-input) {
        .ant-select-selector {
          padding: 0 10px;
          display: flex;
          align-items: center;
          height: 40px;
          border-radius: 4px 0 0 4px;
          color: @black;
          background-color: @gray-1;
        }
      }
    }

    .ant-select-disabled {
      &.ant-select {
        &:not(.ant-select-customize-input) {
          .ant-select-selector {
            height: 40px;
            background-color: @gray-1;
            padding: 0 10px;
          }
        }
      }
    }

    .ant-input {
      width: 100%;
      border-radius: 0 4px 4px 0;
      border-left-color: transparent;
    }

    .ant-input-disabled {
      &:hover {
        border-left-color: transparent;
      }
    }

    .ant-input-affix-wrapper {
      border-radius: 0 4px 4px 0;
      border-left: 1px solid @white;
    }
  }
}

.wrapper {
  :global {
    .ant-checkbox-wrapper {
      .ant-checkbox {
        margin-top: 4px;
        align-self: flex-start;
      }
    }
  }
}

.box {
  display: flex;
  flex-direction: column;
  gap: 10px;

  &Trial {
    padding: 10px;
    border: 1px solid rgba(@yellow-2, 0.4);
    border-radius: 20px;
  }
}

.cancelBtn {
  width: 100%;

  @media (min-width: @breakpoint-lg) {
    width: auto;
  }
}
@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;