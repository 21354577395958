@import 'src/styles/base/_variables.less';

.heroSection {
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  padding: 84px 0 60px;
  background-image: url('../../../../../src/assets/img/landing/main-bg-mobile.svg');
  background-position: center 168px;

  @media (min-width: @breakpoint-xl) {
    background-image: url('../../../../../src/assets/img/landing/bg-main-new.svg');
    padding: 128px 0 166px;
    background-position: center 100px;
  }

  @media (min-width: @breakpoint-xxl) {
    background-position: center 30px;
  }

  .heroCard {
    display: none;

    @media (min-width: @breakpoint-xl) {
      display: flex;
    }
  }
}

.heroIcon {
  opacity: 0;

  @media (min-width: @breakpoint-xl) {
    opacity: 1;
    transform: rotate(30deg);
    width: 72px;
    height: 72px;
    top: 154px;
    right: 122px;
    font-size: 34px;
  }
}

.heroTitle {
  color: @black-2;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  font-size: 36px;
  line-height: 40px;
  max-width: 335px;
  margin: 0 auto 80px;

  @media (min-width: @breakpoint-xl) {
    text-align: left;
    font-size: 96px;
    line-height: 100px;
    max-width: 1050px;
    margin: 0 0 84px;
  }

  &Accent {
    display: block;
    color: @white-2;
    -webkit-text-stroke: 1px @black;
  }
}

.heroSubTitle {
  line-height: 20px;
  text-align: center;
  h2 {
    font-weight: 700;
    color: @gray-5;
    font-size: 14px;
  }

  @media (min-width: @breakpoint-md) {
    line-height: 40px;
    margin-bottom: 20px;
    h2 {
      font-size: 36px;
    }
  }

  @media (min-width: @breakpoint-xl) {
    text-align: left;
  }

  span {
    border-top: 2px solid @black-2;
    display: inline-block;
    padding-top: 10px;

    @media (min-width: @breakpoint-md) {
      border-width: 4px;
      padding-top: 20px;
    }
  }
}

.heroCaption {
  color: @gray-5;
  line-height: 20px;
  margin: 0 auto 40px;
  max-width: 336px;
  text-align: center;
  h3 {
    font-size: 14px;
  }
  @media (min-width: @breakpoint-md) {
    max-width: 400px;
    line-height: 30px;
    h3 {
      font-size: 24px;
    }
  }

  @media (min-width: @breakpoint-xl) {
    text-align: left;
    margin: 0 0 40px;
    max-width: 550px;
  }
}

.heroBtn {
  max-width: 332px;
  margin: 0 auto;

  @media (min-width: @breakpoint-xl) {
    margin: 0;
  }

  :global {
    .ant-btn {
      display: flex;
      align-items: center;
      gap: 20px;
      border-radius: 100px;
      background-color: @yellow-3 !important;

      > span {
        font-size: 16px;
      }

      > i {
        font-size: 24px;
      }

      @media (min-width: @breakpoint-lg) {
        height: 60px !important;

        > span {
          font-size: 20px;
        }

        > i {
          font-size: 30px;
        }
      }
    }
  }
}

.heroCard {
  display: none;

  @media (min-width: @breakpoint-xl) {
    display: flex;
    position: absolute;
    max-width: 628px;
    height: 100px;
    pointer-events: none;
    bottom: 246px;
    right: -100px;
    z-index: 1;
  }

  @media (min-width: @breakpoint-xxl) {
    bottom: 286px;
    right: -80px;
  }

  &:global(.ticket) {
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
    border: none;
    padding-right: 80px;
  }

  &Second {
    @media (min-width: @breakpoint-xl) {
      bottom: 148px;
      right: 22px;
      z-index: 0;
    }

    @media (min-width: @breakpoint-xxl) {
      bottom: 188px;
      right: 62px;
    }
  }

  &Third {
    @media (min-width: @breakpoint-xl) {
      bottom: 32px;
      right: -200px;
    }

    @media (min-width: @breakpoint-xxl) {
      bottom: 62px;
      right: -266px;
    }

    &Box {
      :global {
        .ticket__name {
          font-size: 24px;
          margin-bottom: 8px;
        }
      }
    }
  }
}

@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;