@import 'src/styles/base/_variables.less';

//highlighted element
.onboardingHeader {
  z-index: auto !important;
}

.onboardingProject {
  z-index: 1000000;
  pointer-events: none;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% + 20px);
    height: 80px;
    background-color: @white;
    border-radius: 8px;

    @media (min-width: @breakpoint-md) {
      width: 200px;
      height: 100px;
    }
  }

  &.onboardingProjectDark {
    &:before {
      background-color: @black-2;
      box-shadow: 0px 3px 8px rgba(147, 149, 151, 0.3);
    }
  }
}

.onboardingTemplate {
  z-index: 1000000;
  pointer-events: none;
  background-color: @white;

  &.onboardingTemplateDark {
    background-color: @black-2;
    box-shadow: 0px 3px 8px rgba(147, 149, 151, 0.3);
  }
}

.onboardingProfile {
  z-index: 1000000;
  pointer-events: none;
  position: relative;
  background-color: @white;

  > div {
    background-color: @white;
    z-index: 1000000;
    pointer-events: none;
    position: relative;
  }

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translate(-50%, -50%);
    width: 90px;
    height: 60px;
    background-color: @white;

    @media (min-width: @breakpoint-lg) {
      height: 80px;
    }
  }

  &.onboardingProfileDark {
    background-color: @black-2;

    > div {
      background-color: @black-2;
    }

    &:before {
      width: 96px;
      background-color: @black-2;
      box-shadow: 0px 3px 8px rgba(147, 149, 151, 0.3);
    }
  }
}

.completenessOnboarding {
  z-index: 1000000;
  pointer-events: none;
  position: relative;

  > div {
    background-color: @white;
    z-index: 1000000;
    pointer-events: none;
    position: relative;
  }

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 76px;
    height: 68px;
    background-color: @white;
    border-radius: 8px;
    z-index: 99998;
  }

  &.completenessOnboardingDark {
    > div {
      background-color: @black-2;
    }

    &:before {
      background-color: @black-2;
      box-shadow: 0px 3px 8px rgba(147, 149, 151, 0.3);
    }
  }
}

@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;