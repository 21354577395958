@import 'src/styles/base/_variables.less';

body {
  .ant-checkbox-wrapper {
    &:after {
      display: none;
    }

    &:not(.ant-checkbox-wrapper-disabled) {
      &:hover {
        .ant-checkbox-checked {
          &:not(.ant-checkbox-disabled) {
            &:after {
              border-color: @gray-5;
            }

            .ant-checkbox-inner {
              background-color: transparent;
              border-color: @gray-5;
            }
          }
        }

        .ant-checkbox-inner {
          border-color: @gray-5;
        }
      }
    }
  }

  .ant-checkbox {
    .ant-checkbox-inner {
      width: 24px;
      height: 24px;
      border: 2px solid @gray-5;
      border-radius: 6px;
      background-color: transparent;
    }

    &:not(.ant-checkbox-disabled) {
      &:hover {
        .ant-checkbox-inner {
          border-color: @gray-5;
        }
      }
    }
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: transparent;

      &::after {
        border-color: @gray-5;
        inset-inline-start: 25.5%;
      }
    }

    &:after {
      display: none;
    }

    &:not(.ant-checkbox-disabled) {
      &:hover {
        .ant-checkbox-inner {
          background-color: transparent;
          border-color: @gray-5;
        }
      }
    }
  }

  .ant-checkbox-disabled {
    .ant-checkbox-inner {
      border-color: @gray-3;

      &::after {
        border-color: @gray-3;
      }
    }
  }
}
