@import 'src/styles/base/_variables.less';

.banner {
  display: block;
  padding: 10px;
  margin: 0 4px 20px;
  border-radius: 8px;
  box-shadow: 0px 3px 8px rgba(@black, 0.1);

  @media (min-width: @breakpoint-md) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    gap: 40px;
  }

  &.settings {
    text-align: center;
    justify-content: center;

    @media (min-width: @breakpoint-md) {
      min-height: 100px;
    }
  }

  @media (max-width: @breakpoint-md - 1) {
    > div {
      &:first-child {
        &:not(:only-child) {
          padding-bottom: 10px;
        }
      }
    }
  }

  &__left {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }

  &__right {
    display: block;
    padding-top: 10px;

    @media (min-width: @breakpoint-sm) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      padding-top: 0;
    }

    @media (min-width: @breakpoint-lg) {
      gap: 20px;
    }
  }

  &__box {
    display: block;

    @media (min-width: @breakpoint-sm) {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    @media (min-width: @breakpoint-lg) {
      gap: 20px;
    }
  }

  &__actions {
    display: block;

    > div {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    @media (min-width: @breakpoint-md) {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin: -5px;

      > div {
        padding: 5px;
        margin-bottom: 0;
      }
    }

    .ant-btn {
      width: 100%;

      @media (min-width: @breakpoint-md) {
        width: auto;
      }

      &:is(.banner-btn) {
        margin-left: auto;
        width: auto;
      }
    }
  }

  &__name {
    font-size: 16px;
    line-height: 18px;
    word-break: break-all;

    @media (min-width: @breakpoint-md) {
      font-size: 24px;
      line-height: 20px;
    }

    &.mb-8 {
      margin-bottom: 8px;
    }
  }

  &__count {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    min-width: 40px;
    padding: 10px;
    border: 1px solid rgba(@gray-4, 0.3);
    border-radius: 4px;
    flex-shrink: 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    margin-right: 10px;

    @media (min-width: @breakpoint-md) {
      height: 60px;
      min-width: 60px;
      font-size: 24px;
      line-height: 20px;
      margin-right: 20px;
    }
  }

  &__item {
    font-size: 14px;
    line-height: 18px;
    white-space: nowrap;

    > span {
      color: @gray-5;
      padding-left: 4px;
    }
  }

  &__progress {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    box-shadow: 0px 0px 0px 2px @gray-7;
    margin-right: 20px;

    .ant-progress-circle-trail {
      stroke: @gray-3;
    }

    .ant-progress-circle {
      &.ant-progress-status-success {
        .ant-progress-text {
          color: @gray-5;
        }
      }
      .ant-progress-text {
        font-size: 12px;
        font-weight: 500;
        color: @gray-5;
      }
    }
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0 -5px 10px;

    @media (min-width: @breakpoint-sm) {
      justify-content: center;
      margin: 0 -5px;
    }

    @media (min-width: @breakpoint-lg) {
      margin: 0 -10px;
    }

    > div {
      padding: 0 5px;

      @media (min-width: @breakpoint-lg) {
        padding: 0 10px;
      }
    }
  }

  &__profile {
    display: flex;
    gap: 20px;
  }

  &__image {
    width: 58px;
    height: 58px;
    border: 1px solid rgba(@gray-4, 0.3);
    border-radius: 3px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    flex-shrink: 0;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .name {
      font-weight: 700;
      font-size: 24px;
      line-height: 20px;
    }

    .item {
      line-height: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 224px;

      @media (min-width: @breakpoint-xs) {
        width: auto;
      }

      > span {
        color: @gray-4;
        padding-right: 4px;
      }

      a {
        color: @black-2;
      }
    }
  }

  &__description {
    font-size: 14px;
    line-height: 18px;
  }

  &.mb-10 {
    margin-bottom: 10px;
  }

  &.inverse {
    border: 1px solid rgba(@gray-4, 0.3);

    .banner__count {
      background-color: #1e2126;
    }

    .banner__item {
      > span {
        color: @gray-4;
      }

      a {
        color: @gray-4;
      }
    }

    .banner__progress {
      box-shadow: 0px 0px 0px 2px rgba(230, 230, 230, 0.3);

      .ant-progress-circle-trail {
        stroke: rgba(240, 240, 240, 0.1);
      }

      .ant-progress-circle {
        .ant-progress-text {
          color: @white;
        }
      }
    }

    .banner__image {
      background: #1e2126;
    }

    .banner__content {
      .item {
        a {
          color: @white;
        }
      }
    }
  }
}

.category-circle {
  position: relative;
  padding-left: 15px;

  &:before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    left: 0;
    top: 50%;
    transform: translate(0%, -50%);;
  }
}