@font-face {
  font-family: 'Montserrat';
  src: url('../../../assets/fonts/montserrat/Montserrat-Light.eot');
  src: url('../../../assets/fonts/montserrat/Montserrat-Light.eot?#iefix')
      format('embedded-opentype'),
    url('../../../assets/fonts/montserrat/Montserrat-Light.woff') format('woff'),
    url('../../../assets/fonts/montserrat/Montserrat-Light.ttf')
      format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../../../assets/fonts/montserrat/Montserrat-Regular.eot');
  src: url('../../../assets/fonts/montserrat/Montserrat-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('../../../assets/fonts/montserrat/Montserrat-Regular.woff')
      format('woff'),
    url('../../../assets/fonts/montserrat/Montserrat-Regular.ttf')
      format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../../../assets/fonts/montserrat/Montserrat-Medium.eot');
  src: url('../../../assets/fonts/montserrat/Montserrat-Medium.eot?#iefix')
      format('embedded-opentype'),
    url('../../../assets/fonts/montserrat/Montserrat-Medium.woff')
      format('woff'),
    url('../../../assets/fonts/montserrat/Montserrat-Medium.ttf')
      format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../../../assets/fonts/montserrat/Montserrat-Bold.eot');
  src: url('../../../assets/fonts/montserrat/Montserrat-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('../../../assets/fonts/montserrat/Montserrat-Bold.woff') format('woff'),
    url('../../../assets/fonts/montserrat/Montserrat-Bold.ttf')
      format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Allerta Stencil';
  src: url('../../../assets/fonts/AllertaStencil/AllertaStencil.eot');
  src: url('../../../assets/fonts/AllertaStencil/AllertaStencil.eot?#iefix')
      format('embedded-opentype'),
    url('../../../assets/fonts/AllertaStencil/AllertaStencil.woff')
      format('woff'),
    url('../../../assets/fonts/AllertaStencil/AllertaStencil.ttf')
      format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Seaford';
  src: url('../../../assets/fonts/seaford/SeafordRg.ttf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Seaford';
  src: url('../../../assets/fonts/seaford/SeafordBold.ttf');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
