@import 'src/styles/base/_variables.less';

* {
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: @gray-4;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: @gray-4;
  }
}