@import 'src/styles/base/_variables.less';

.filterOpener {
  border: 1px solid rgba(@gray-4, 0.3);
  display: inline-block;
  min-width: 60px;
  background-color: transparent;
  border-radius: 4px;
  cursor: pointer;
  padding: 4px 10px;
  line-height: 30px;
  font-weight: 500;
  font-size: 12px;

  span {
    color: @text-color;
    font-weight: 400;
  }

  &:global(.ant-dropdown-open) {
    box-shadow: 0px 4px 4px rgba(@black, 0.1);
    border: 1px solid @gray-4;
  }

  &:hover {
    border: 1px solid @gray-4;
  }
}

.selectedValue {
  font-weight: 700;
}

.lightSearch {
  background-color: #f6f6f6;
  color: #a1a1a1;
}

.darkSearch {
  color: inherit;
}

@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;