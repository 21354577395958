@import 'src/styles/base/_variables.less';

.authPage {
  overflow-y: auto;
  background-color: @white;
  flex: auto;
}

.authWrap {
  position: relative;
  padding: 50px 15px 30px;
  overflow-x: hidden;

  @media (min-width: @breakpoint-xl) {
    padding: 50px 15px 30px;
  }

  @media (min-width: @breakpoint-xxl) {
    padding: 120px 15px 30px;
  }
}

.authRow {
  @media (min-width: @breakpoint-md) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 1360px;
    margin: 0 auto;
    height: 100%;
  }
}

.authForm {
  @media (min-width: @breakpoint-md) {
    min-width: 640px;
    min-height: 784px;
    position: relative;
    z-index: 1;
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.4);
    border: 1px solid @black-2;
    border-radius: 8px;
  }

  &Title {
    font-size: 36px;
    line-height: 40px;
    margin-bottom: 40px;
    color: @gray-5;
    text-align: center;
    font-weight: 700;
  }

  &Wrap {
    @media (min-width: @breakpoint-md) {
      width: 100%;
    }
  }

  &Box {
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
    position: relative;
  }

  &Logo {
    width: 225px;
    margin: 0 auto 30px;
  }

  // &Progress {
  //   display: flex;
  //   align-items: center;
  //   justify-content: space-evenly;
  //   margin-bottom: 60px;
  // }
}
.authFooter {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 10px;
  color: @black-2;
  max-width: 640px;
  margin: 40px auto 0;
}

.authBtnBox {
  display: flex;
  justify-content: center;
  margin: 0 auto 60px;
  max-width: 380px;
  width: 100%;
}

.authBtn {
  padding: 0 5px;

  :global {
    .ant-btn {
      height: 50px;
      width: auto;
      min-width: 140px !important;
      font-weight: 500;
      font-size: 14px !important;
      line-height: 20px;
    }
  }
}

.authContent {
  display: none;

  @media (min-width: @breakpoint-xl) {
    width: calc(100% - 595px);
    display: flex;
    flex-direction: column;
  }
}

.authTitleBox {
  @media (min-width: @breakpoint-xl) {
    height: 33.33%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}

.authTitle {
  @media (min-width: @breakpoint-xl) {
    font-weight: 700;
    font-size: 48px;
    line-height: 40px;
    color: @white;
    margin-bottom: 15px;
    position: relative;
  }

  span {
    color: @primary-color;
  }
}

.authSubtitle {
  @media (min-width: @breakpoint-xl) {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    letter-spacing: 12.8px;
    color: @text-color;
  }
}

.authProgress {
  @media (min-width: @breakpoint-xl) {
    position: absolute;
    top: calc(100% + 60px);
    left: 50%;
    transform: translateX(-50%);
    width: 140px;
  }

  &:after {
    @media (min-width: @breakpoint-xl) {
      content: '';
      height: 54px;
      width: 5px;
      background-image: url('../../../src/assets/img/line.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      bottom: calc(100% - 3px);
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &.authProgressTop {
    @media (min-width: @breakpoint-xl) {
      bottom: calc(100% + 60px);
      top: auto;
    }

    &:after {
      @media (min-width: @breakpoint-xl) {
        top: calc(100% - 3px);
        bottom: auto;
      }
    }
  }

  &.active {
    .authProgressBox {
      @media (min-width: @breakpoint-xl) {
        border-color: @primary-color;
        color: @primary-color;
      }
    }
  }

  &Wrap {
    @media (min-width: @breakpoint-xl) {
      width: 118px;
      height: 118px;
      border-radius: 50%;
      border: 1px dashed rgba(@gray-1, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
    }
  }

  &Box {
    @media (min-width: @breakpoint-xl) {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      border: 10px solid rgba(@gray-1, 0.1);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      color: @gray-1;
    }
  }

  &Text {
    @media (min-width: @breakpoint-xl) {
      color: @gray-4;
      font-weight: 500;
      font-size: 14px;
      text-align: center;
      margin: 15px 0;
      text-transform: capitalize;
    }
  }
}

@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;