@import 'src/styles/base/_variables.less';

.validationRule {
  font-size: 12px;
  margin-bottom: 40px;

  :global {
    .circle {
      height: 8px;
      width: 8px;
      background-color: rgba(@text-color, 0.5);
      border-radius: 50%;
      display: inline-block;
      margin-right: 8px;
    }

    .validation-rule {
      color: @gray-4;

      &.success {
        color: @black-2;

        .circle {
          background-color: @black-2;
        }
      }
    }
  }
}

.authLinkBox {
  color: rgba(@text-color, 0.5);
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  max-width: 260px;
  margin: 0 auto;

  a {
    color: @blue-2;
    white-space: nowrap;

    &:hover {
      color: @blue-2;
      font-weight: 700;
    }
  }
}

.authTitleBox {
  @media (min-width: @breakpoint-xl) {
    padding-left: 100px;
    max-width: 766px;
  }
}

.authTitle {
  max-width: 640px;
}

.authText {
  font-size: 34px;
  margin-bottom: 40px;
}

.authFooterText {
  max-width: 246px;
  margin: 0 auto;
  font-size: 12px;
  line-height: 20px;
  color: @gray-4;
  text-align: center;

  a {
    text-decoration: underline;
    color: @gray-4;

    &:hover {
      font-weight: 600;
    }
  }
}

.thankCaption {
  color: @black-2;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 40px;
  text-align: center;

  > div {
    text-decoration: underline;
  }
}

.thankContact {
  margin: auto 0;
  font-size: 12px;
  line-height: 20px;
  text-align: center;

  a {
    color: inherit;
    text-decoration: underline;

    &:hover {
      color: inherit;
      text-decoration: underline;
      font-weight: 700;
    }
  }
}

.thankText {
  font-size: 12px;
  line-height: 20px;
  max-width: 250px;
  margin: 0 auto 80px;
  text-align: center;
  color: @gray-4;
}

.authProgress {
  margin-bottom: 80px;
}

.authBtnBox {
  margin-bottom: 20px;
}

@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;