@import 'src/styles/base/_variables.less';

.grid-row {
  width: 100%;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: auto minmax(0, 1fr);
  position: relative;
  flex-shrink: 1;
  flex-grow: 1;
  flex-basis: 0;

  @media (min-width: @breakpoint-lg) {
    grid-template-rows: minmax(0, 1fr);
    grid-template-columns: 500px minmax(0, 1fr);
  }

  @media (min-width: @breakpoint-xxl) {
    grid-template-columns: 600px minmax(0, 1fr);
  }
}

.grid-scroll {
  overflow-x: hidden;
  overflow-y: auto;
}
