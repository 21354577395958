@import 'src/styles/base/_variables.less';

.comments {
  padding: 40px 0;

  .title {
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 16px;
  }
}

.input {
  :global {
    .ant-input-affix-wrapper {
      padding: 13px 0 13px 12px;
      position: relative;

      .ant-input {
        width: calc(100% - 134px);
      }

      .ant-input-suffix {
        right: 0;

        .ant-btn {
          box-shadow: none;
          height: 48px;
          min-width: 120px;
          background-color: @yellow;
          font-size: 14px;
          font-weight: 700;
          color: @black-2;
          opacity: 1;
        }
      }
    }
  }
}

.wrapper {
  border: 1px solid @gray-3;
  border-radius: 8px;
  padding: 30px 20px 0;

  > div {
    border-bottom: 1px solid @gray-3;
    margin-bottom: 20px;

    &:last-child {
      border-bottom: 0;
      margin-bottom: 0;
    }
  }
}

.item {
  span {
    color: @gray-4;
    padding-right: 4px;
  }

  p {
    word-break: break-word;
    margin-bottom: 20px;
  }
}

@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;