@import 'src/styles/base/_variables.less';

.number {
  &:global(.ant-input-number) {
    height: 40px;
    min-width: 158px;
    border: 1px solid rgba(147, 149, 151, 0.3);
    border-radius: 4px;

    &:hover {
      border: 1px solid rgba(147, 149, 151, 0.3);
    }
  }

  :global {
    .ant-input-number-input {
      height: 38px;
    }

    .ant-input-number-handler-wrap {
      opacity: 1;
      width: 38px;
      background: #ffdf5e;
      color: @black-2;
      border-radius: 0 3px 3px 0;

      &:hover {
        .ant-input-number-handler {
          height: 50%;
        }
      }
    }

    .ant-input-number-handler-up,
    .ant-input-number-handler-down {
      &:hover {
        height: 50% !important;
        color: @black-2;
        background: #fcd228;
      }
    }

    .ant-input-number-handler-up-inner,
    .ant-input-number-handler-down-inner {
      right: 12px;
      color: @black-2;
      font-weight: 700;
      font-size: 12px;
    }
  }
}

@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;