@import '../../../../../styles/base/_variables.less';

@borderRows: 1px solid @gray-3;

.role,
.info {
  color: @gray-4;
}

.title {
  display: none;

  @media (min-width: @breakpoint-xl) {
    display: block;
    margin-bottom: 20px;
    font-weight: 700;
    line-height: 40px;
  }
}

.tabName {
  margin-bottom: 10px;
}

.info {
  padding: 0;

  a {
    color: @blue-2;
  }
}

.inviteBtnBox {
  margin-bottom: 20px;

  @media (min-width: @breakpoint-xl) {
    text-align: right;
  }
}

.inviteBtn {
  :global {
    width: 100px;
  }
}

.tableHead {
  margin-bottom: 20px;
  background: rgba(@gray-4, 0.04);

  .tableCol {
    padding: 12px 20px;

    &.actions {
      text-align: center;
    }
  }
}

.tableRow {
  @media (min-width: @breakpoint-xl) {
    display: grid;
    grid-template-columns: 27% 28% 18% 12% 15%;
  }

  &.xsNone {
    display: none;

    @media (min-width: @breakpoint-xl) {
      display: grid;
      grid-template-columns: 27% 28% 18% 12% 15%;
    }
  }

  &.lgNone {
    @media (min-width: @breakpoint-xl) {
      display: none;
    }
  }

  &.userGroups {
    grid-template-columns: 25% 60% 15%;
  }
}

.tableCol {
  padding: 5px 50px 5px 0;

  @media (min-width: @breakpoint-xl) {
    padding: 8px 20px;
  }
}

.tableBody {
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 10px;
  flex-grow: 1;

  .tableRow {
    border-top: @borderRows;
    position: relative;
    padding: 10px 0;

    @media (min-width: @breakpoint-xl) {
      padding: 0;
    }

    &:last-child {
      border-bottom: @borderRows;
    }

    .tableCol {
      display: flex;
      align-items: center;
      gap: 10px;

      &.actions {
        text-align: center;
        position: absolute;
        top: 20px;
        right: -35px;

        @media (min-width: @breakpoint-xl) {
          position: static;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

.active {
  color: @green;
}

.pending {
  color: @yellow-2;
}

.resentBtn {
  &:global(.ant-btn-icon-only.ant-btn-sm) {
    > * {
      font-size: 18px;
      color: @gray-4;
    }
  }
}

.pagination {
  text-align: right;
}

.btn {
  &:global(.ant-btn) {
    padding: 0 3px;
    color: @gray-4;

    &:hover {
      color: @gray-5;
    }
  }
}

@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;