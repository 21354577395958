@import 'src/styles/base/_variables.less';

.leftBox {
  padding: 20px;

  @media (min-width: @breakpoint-lg) {
    padding: 50px 40px 20px;
  }

  @media (min-width: @breakpoint-xl) {
    padding: 156px 40px 40px;
  }

  :global {
    .h1 {
      margin-bottom: 0;
    }
  }
}

.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: @gray-3;
  font-size: 18px;
  color: @text-color;
  border: 0;
  position: fixed;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease;
  bottom: 20px;
  right: 20px;

  @media (min-width: @breakpoint-xxl) {
    bottom: 100px;
    right: 80px;
  }

  &:hover {
    color: @text-color-darken;
  }

  &.buttonVisible {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s ease;
  }
}

.content {
  color: @text-color-darken;
  font-size: 14px;
  line-height: 17px;
  max-width: 1140px;

  a {
    text-decoration: underline;
    color: rgb(0, 0, 238);

    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
      color: rgb(0, 0, 238);
    }

    &:visited {
      color: rgb(85, 26, 139);
    }
  }
}

.text {
  text-align: center;
  text-transform: capitalize;
}

.tabsHolderWrap {
  display: flex;
  flex-direction: column;

  :global {
    .ant-tabs {
      display: flex;

      > .ant-tabs-nav {
        margin: 0;
      }
    }
    .ant-tabs-top {
      > .ant-tabs-nav {
        &::before {
          display: none;
        }
      }
    }
  }
}

.tabsHolder {
  min-height: 0;

  :global {
    .ant-tabs-content-holder {
      overflow-y: auto;
      overflow-x: hidden;
    }

    .ant-tabs-tab {
      font-weight: 700;
      text-transform: uppercase;
      margin: 0 20px 0 0;
      color: @gray-4;

      &:hover,
      &:focus {
        color: @gray-5;
      }

      &.ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          font-weight: 700;
          color: @gray-5;
        }
      }
    }

    .ant-tabs-ink-bar {
      background-color: @gray-5;
    }

    .ant-tabs-nav-wrap {
      padding: 20px;

      @media (min-width: @breakpoint-lg) {
        padding: 40px 20px;
      }

      @media (min-width: @breakpoint-xl) {
        padding: 82px 40px 28px;
      }
    }

    .ant-tabs-tab-btn {
      &:focus,
      &:active {
        color: @gray-5;
      }
    }
  }
}

@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;