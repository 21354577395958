@import 'src/styles/base/_variables.less';

.details-wrap {
  max-width: 440px;
  width: calc(100% - 20px);
  margin: 0 auto;
  color: @text-color-darken;
  z-index: 4;
  position: absolute;
  top: 74px;
  right: 0;
  transition: transform 0.3s ease;

  @media (min-width: @breakpoint-lg) {
    position: static;
    padding-top: 96px;
    width: 100%;
  }

  &.details-hidden {
    transform: translateX(100%);
    transition: transform 0.3s ease;

    @media (min-width: @breakpoint-lg) {
      transform: translateX(0);
    }
  }

  &.search-page {
    padding-top: 0;
    top: 102px;
  }
}

.details-name {
  font-size: 36px;
  margin-bottom: 40px;
  font-weight: 700;
}

.details-card {
  border: 2px solid @gray-3;
  font-weight: 500;
  background-color: @white;
  border-radius: 20px 0 0 20px;
  border-right-width: 0;

  @media (min-width: @breakpoint-lg) {
    border-right-width: 2px;
    border-radius: 20px;
  }

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: @gray-5;
    border-bottom: 1px solid @gray-3;
    border-radius: 20px 0 0 0;
    padding: 20px 25px;

    @media (min-width: @breakpoint-lg) {
      padding: 30px 40px;
      border-radius: 20px 20px 0 0;
    }
  }

  &__head-icon {
    flex-shrink: 0;
    width: 98px;
    height: 98px;
    border-radius: 50%;
    border: 4px solid rgba(@gray-3, 0.05);
    box-shadow: 0px 0px 0px 4px @gray-5 inset;
    background-color: rgba(@gray-6, 0.2);
    color: @white;
    font-size: 48px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: @breakpoint-md) {
      width: 128px;
      height: 128px;
    }
  }

  &__info {
    width: 170px;
    flex-shrink: 0;
    padding-left: 8px;
    position: relative;
    color: @text-color;

    &:after {
      content: '';
      position: absolute;
      top: 4px;
      bottom: 4px;
      left: 0;
      width: 1px;
      background-color: @primary-color;
    }
  }

  &__caption {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 5px;
    color: @primary-color;
  }

  &__progress {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 98px;
    height: 98px;
    border-radius: 50%;
    box-shadow: 0px 0px 0px 4px rgba(@gray-3, 0.05);
    padding: 4px;

    @media (min-width: @breakpoint-lg) {
      width: 128px;
      height: 128px;
    }

    .ant-progress-circle-trail {
      stroke: rgba(@gray-6, 0.2);
    }

    .ant-progress-circle {
      width: 100%;
      height: 100%;

      &.ant-progress-status-success {
        .ant-progress-text {
          color: @white;
        }
      }

      .ant-progress-text {
        font-weight: 700;
        color: @white;
        font-size: 14px;

        @media (min-width: @breakpoint-lg) {
          font-size: 24px;
        }
      }
    }

    .ant-progress-inner {
      width: 100% !important;
      height: 100% !important;
    }
  }

  &__body {
    overflow: hidden;
  }

  &__title {
    flex-grow: 1;
    padding: 0 10px;
    color: @black;
    font-weight: 500;
  }

  &__row {
    display: flex;
    align-items: center;

    &:hover {
      @media (min-width: @breakpoint-xl) {
        .details-card__copy {
          opacity: 1;
          visibility: visible;
          transition: opacity 0.3s ease;
        }
      }
    }
  }

  &__icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: @white;
    font-size: 14px;
    background-color: @gray-5;
    flex-shrink: 0;
  }

  &__content {
    word-break: break-word;
    color: @text-color-darken;
  }

  &__copy {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    cursor: pointer;
    flex-shrink: 0;
    color: @text-color;

    &:hover {
      color: @text-color-darken;
    }

    @media (min-width: @breakpoint-xl) {
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.3s ease;
    }
  }

  &__collapse {
    .ant-collapse {
      border: 0;
      background-color: transparent;

      .ant-collapse-content {
        border-color: @gray-3;
        background-color: transparent;
      }

      .ant-collapse-item {
        border-color: @gray-3;

        &:last-child {
          border-bottom: 0;
        }

        &.ant-collapse-item-active {
          .ant-collapse-header {
            .ant-collapse-arrow {
              color: @text-color-darken;
            }
          }
        }

        .ant-collapse-header {
          padding: 6px 50px 6px 20px;

          .ant-collapse-arrow {
            padding: 0;
            font-size: 18px;
            top: 50%;
            transform: translateY(-50%);
            color: @text-color;

            &:hover {
              color: @text-color-darken;
            }
          }
        }
      }
    }
  }
}
