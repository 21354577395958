@import 'src/styles/base/_variables.less';

.empty {
  max-width: 620px;
  margin: 0 auto;
  text-align: center;
  padding-top: 90px;

  @media (min-width: @breakpoint-lg) {
    padding-top: 10%;
  }

  &Title {
    font-size: 24px;
    line-height: 20px;
    position: relative;
    font-weight: 700;
    max-width: 310px;
    margin: 0 auto;

    @media (min-width: @breakpoint-md) {
      max-width: none;
      font-size: 36px;
      line-height: 40px;
    }
  }

  &Capture {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    font-size: 14px;

    @media (min-width: @breakpoint-md) {
      margin-bottom: 40px;
      font-size: 24px;
      line-height: 24px;
      font-weight: 700;
    }

    strong {
      flex-shrink: 0;
      padding: 0 20px;
    }

    span {
      height: 3px;
      background-color: @black;
      border-radius: 20px;
      flex-grow: 1;
      position: relative;

      &:first-child {
        @media (min-width: @breakpoint-md) {
          margin-right: 15px;
        }

        &:before {
          right: -15px;
        }
      }

      &:last-child {
        @media (min-width: @breakpoint-md) {
          margin-left: 15px;
        }

        &:before {
          left: -15px;
        }
      }

      &:before {
        @media (min-width: @breakpoint-md) {
          content: '';
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: @gray-5;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }

  &Subtitle {
    color: @text-color;
    position: relative;
    padding-top: 10px;
    letter-spacing: 0.73px;
    font-size: 13px;

    @media (min-width: @breakpoint-md) {
      font-size: 24px;
      letter-spacing: 1.39px;
      padding-top: 20px;
    }
  }
}

@primary-color: #f5df4d;@error-color: #b91600;@font-family: inherit;@font-size-base: 14px;@line-height-base: 1.5;@border-radius-base: 4px;@body-background: transparent;@btn-primary-color: #242424;@btn-font-weight: 500;@label-color: #242424;@form-item-margin-bottom: 20px;@form-vertical-label-padding: 0px 0px 10px;@form-item-label-font-size: 14px;@input-color: #242424cc;@input-bg: #dbdbdb40;@input-border-color: #F0F0F0;@input-placeholder-color: #939597;@input-hover-border-color: #242424;@picker-bg: #dbdbdb40;@picker-border-color: #F0F0F0;@select-border-color: #E6E6E6;@select-item-selected-color: #242424;@select-item-selected-font-weight: 400;@select-item-active-bg: #E6E6E626;@layout-header-height: 80px;@layout-header-padding: 0;@radio-size: 20px;@radio-border-width: 2px;@radio-dot-color: #242424;